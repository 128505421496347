import React, { Component } from 'react'
import './../Aluno.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment';
import Table from '../../../components/Table'
import ModalErro from '../../../components/modals/Erro'
import ModalInativarContrato from '../../../components/modals/InativarContrato'
import ModalAtivarContrato from '../../../components/modals/AtivarContrato'
import ModalCancelarContrato from '../../../components/modals/CancelarContrato'
import ModalProrrogarContrato from '../../../components/modals/ProrrogarContrato'
import ModalVincularCodigo from '../../../components/modals/VincularCodigo'
import ModalEstornarProrrogacao from '../../../components/modals/EstornarProrrogacao'
import ModalTransferirCodigo from '../../../components/modals/TransferenciaCodigoAluno'
import ModalVincularAditivo from '../../../components/modals/VincularAditivo'
import ModalAditivoContrato from '../../../components/modals/AditivoContrato'
import ModalObservacaoProrrogacao from './../../../components/modals/ObservacaoProrrogacao'
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';
import real from '../../../services/real';
import Snackbar from '@material-ui/core/Snackbar';
import { saveAs } from 'file-saver';
import Alert from './../../../components/templates/Alert'
import decrypt from './../../../services/crypto'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moeda from './../../../services/moeda'

const initalState = {
  aluno: {
    _id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contrato: {
      contm_data_inicio_vigencia: "",
      contm_duracao: "",
      contm_observacao: "",
      contm_curso_id: "",
      contm_ativo: true,
      contm_unin_id: ""
    }
  },
  contrato: {
    contm_id: "",
    contm_data_inicio_vigencia: "",
    contm_duracao: "",
    contm_observacao: "",
    curso: {
      curso_nome: ''
    },
    contm_ativo: "",
    unidade: {
      unin_numero: '',
      unin_descricao: '',
    },
    forma_pagto: {
      formpag_descricao: ''
    },
    contm_unin_id: "",
    contm_tabval_id: "",
    contm_data_vct_matricula: "",
    contm_data_vct_primeira_mensalidade: "",
    formapagto_id: "",
    stcontmat_situacao: "",
    contm_codigo: '',
    contm_metdiv_id: '',
    tabela: {
      _id: '',
      tabval_nome: '',
      tabval_num_parc: '',
      tabval_val_cheio_mensal: '',
      tabval_val_matri: '',
      tabval_val_mensal: '',
      tabval_val_mensal_total: ''
    },
    material: {
      matcont_valor: '',
      matcont_num_parcelas: '',
      matcont_dia_vencimento: '',
      matcont_formpag_id: '',
      matcont_tabprec_id: '',
      matcont_prod_id: '',
    }
  },
  responsavel: {
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "",
      principal: true,
      index: 1
    }],
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesAtivarInativar: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesCancelar: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesProrrogar:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesAtribuirCodigo:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesEstornoProrrogar:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesTransferirCodigo:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesVincularAditivo: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  loading: true,
  contratos: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'contm_codigo', numeric: false, disablePadding: false, label: 'Codigo' },
    { id: 'curso_nome', numeric: false, disablePadding: false, label: 'Curso' },
    { id: 'contm_data_inicio_vigencia', numeric: false, disablePadding: false, label: 'Data Inicio', orderBy: 'inicio_vigencia_order' },
    { id: 'contm_data_fim_vigencia', numeric: false, disablePadding: false, label: 'Data Fim', orderBy: 'fim_vigencia_order' },
    { id: 'contm_data_cancelamento', numeric: false, disablePadding: false, label: 'Data Cancelamento', orderBy: 'data_cancelamento_order' },
    { id: 'stcontmat_situacao', numeric: false, disablePadding: false, label: 'Situação' },
  ],
  acoesTabela: ['viewContrato', 'imprimirDoc', 'reenviarAssinatura'],
  cabecalhoTabelaProrrogacoes: [
    { id: 'hisp_data', numeric: false, disablePadding: false, label: 'Data da Prorrogação', orderBy: 'hisp_dataOrder' },
    { id: 'hisp_dias', numeric: false, disablePadding: false, label: 'Qtd Dias' },
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Colaborador'},
    { id: 'comercial', numeric: false, disablePadding: false, label: 'Comercial'},
    { id: 'hisp_lanc_financ', numeric: false, disablePadding: false, label: 'Lanç. Financeiro'},
    { id: 'hisp_codigo', numeric: false, disablePadding: false, label: 'Cod. Assinatura'},
    { id: 'hisp_situacao', numeric: false, disablePadding: false, label: 'Situação'},
    { id: 'hisp_ativo', numeric: false, disablePadding: false, label: 'Ativo'},
  ],
  acoesTabelaProrrogacoes: [],
  inativarContrato: {
    motivoInativar: ''
  },
  cancelarContrato: {
    motivoCancelamento: ''
  },
  modalProrrogarContrato: false,
  prorrogarContrato: {
    qtdDias: '',
    contm_data_fim_vigencia_orig: '',
    contm_data_fim_vigencia_nova: '',
    lanc_fin: false,
    lanc_fin_taxa: false,
    contm_tabval_id: '',
    contm_data_vct_primeira_mensalidade: '',
    formapagto_id: '',
    contfin_id:'',
    observacao: '',
    tabela: {
      _id: '',
      tabval_nome: '',
      tabval_num_parc: '',
      tabval_val_cheio_mensal: '',
      tabval_val_matri: '',
      tabval_val_mensal: '',
      tabval_val_mensal_total: real(0)
    }
  },
  modalInativarContrato: false,
  modalAtivarContrato: false,
  modalCancelarContrato: false,
  modalErro: false,
  modalEstornarProrrogacao:false,
  modalVincularAditivo: false,
  openModalAditivoContrato: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  financeiros_pendentes: {
    list: []
  },
  valores_quitacao: {
    list: []
  },
  qtdParcelasEditado: 0,
  total_sugerido: 0,
  conta: {
    contpr_descricao: "",
    contpr_tipo: "A RECEBER",
    contpr_valortotal: "",
    contpr_numparcela: 1,
    contpr_planocontas_id: "",
    plancontas_codigo: "",
    plancontas_descricao: "",
    qtd_parcelas: 1,
    parcelas: [
      {
        parcont_baixado: "",
        parcont_datavencimento: "",
        parcont_valorparcela: "",
        parcont_formapagto_id: "",
        parcont_documento: "",
        parcont_num: "",
        contfin_id: "",
        parcela_editada: false,
        index: 1
      }
    ]
  },
  prorrogacoes:{
    list: []
  },
  formasPagamento: {
    list: []
  },
  planosConta: {
    list: []
  },
  codigos: {
    list: []
  },
  habilitaProrrogar: false,
  cabecalhoTabelaCodigo: [
    { id: 'codmatdid_codigo', numeric: false, disablePadding: true, label: 'Código' },
    { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Material' },
    { id: 'codmatdid_criado_em', numeric: false, disablePadding: false, label: 'Gerado em' },
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Atribuido por' },
    { id: 'hiscod_data', numeric: false, disablePadding: false, label: 'Atribuido em' },
    { id: 'hiscod_situacao', numeric: false, disablePadding: false, label: 'Situação' }
  ],
  acoesTabelaCodigo: [],
  cabecalhoTabelaAditivo: [
    { id: 'aditcontm_descricao', numeric: false, disablePadding: true, label: 'Aditivo' },
    { id: 'aditcontm_criado_em', numeric: false, disablePadding: true, label: 'Data do Aditivo' },
    { id: 'aditcontm_situacao', numeric: false, disablePadding: false, label: 'Situação' },
    { id: 'aditcontm_codigo', numeric: false, disablePadding: false, label: 'Código de Assinatura' }
  ],
  acoesTabelaAditivo: ['imprimirDoc','reenviarAssinatura'],
  modalVincularCodigo: false,
  contasFinanceira: {
    list: []
  },
  materiais:{
    list: []
  },
  material:{
    prod_id: '',
    prod_descricao: '',
    bok_start: ''
  },
  codigo: {
    codmatdid_id: '',
    codmatdid_codigo: ''
  },
  possuiFinanceiroCodigo: false,
  plano_contas:{
    plancontas_id: '',
    plancontas_codigo: '',
    plancontas_descricao: ''
  },
  prorrogacaoEstornar:{
    hisp_data: '',
    hisp_dataOrder: '',
    hisp_data_fim: '',
    hisp_data_fimOrder: '',
    hisp_dias: '',
    pesf_nome: '',
    hisp_lanc_financ: '',
    hisp_ativo: ''
  },
  estornoProrrogacao:{
    estp_hisp_id: '',
    estp_motivo: ''
  },
  snackbar:{
    mensagem: '',
    open: false,
    severity: ''
  },
  disabledButtonProrrogar: false,
  disabledButtonCancelar: false,
  modalTransferirCodigo: false,
  buscaPor: 'cpf',
  search: '',
  loadingPessoa: false,
  alunoTransferenciaCodigo: null,
  codigoSelecionado: null,
  loadingSalvarTransf: false,
  loadingSalvar: false,
  config_aditivos:{
    list: []
  },
  aditivoSelecionado:{
    ccad_id: '',
    ccad_descricao: '',
    ccad_gera_fin: false,
    ccad_duracao: '',
    cursos_aditivo: []
  },
  curso_aditivo:{
    cursad_id: '',
    curso_atual:{
      curso_nome: ''
    },
    curso_novo:{
      curso_nome: ''
    },
    material: {
      matcont_valor: '',
      matcont_tipo: '',
      matcont_num_parcelas: '',
      matcont_dia_vencimento: '',
      matcont_formpag_id: '',
      matcont_tabprec_id: '',
      matcont_prod_id: '',
      contfin_id: ''
    }
  },
  planoContas: {
    parametros:{
      paramfin_parcelamento : 'Mensal'
    }
  },
  tabelaPrecoMaterial: null,
  funcionarios: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  dadosAditivoCotrato: {
    contm_id: '',
    html: '',
    data_inicio_vigencia: '',
    data_fim_vigencia: '',
    curso_id: '',
    curso_nome: '',
    duracao: '' ,
    codigo: '',
    descricao_aditivo: '',
    inicio_curso: '',
    mes_inicio_curso: '',
    duracao_aula: '',
    dia_aula_1: '',
    dia_aula_1_periodo: '',
    dia_aula_1_horario: '',
    dia_aula_2: '',
    dia_aula_2_periodo: '',
    dia_aula_2_horario: ''
  },
  cursos: {
    list: []
  },
  cursoSelecionado: {
    curso_id: '',
    curso_nome: '',
    duracoes: []
  },
  modulosSelecionadas: [],
  modulos: [
    {num: 1, value: 'Um'},
    {num: 2, value: 'Dois'},
    {num: 3, value: 'Três'},
    {num: 4, value: 'Quatro'},
    {num: 5, value: 'Cinco'},
    {num: 6, value: 'Seis'},
    {num: 7, value: 'Sete'},
    {num: 8, value: 'Oito'}
  ],
  aditivos: {
    list: []
  },
  editorState: EditorState.createEmpty(),
  tabelaValores: {
    list: []
  },
  tabelaValoresFiltrado: {
    list: []
  },
  planos: {
    list: []
  },
  openModalObservacaoProrrogacao: false,
  observacao_prorrogacao: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#FF4500').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#FF4500',
    },
  },
}))(Button);

function formatarCodigo(str) {
  const parte1 = str.slice(0, 4)
  const parte2 = str.slice(4, 8)
  const parte3 = str.slice(8, 12)

  return `${parte1}-${parte2}-${parte3}`
}

const ColorGreenButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#008000').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#008000',
    },
  },
}))(Button);

const ColorYellowButton = withStyles((theme) => ({
  root: {
    color: '#000',
    backgroundColor: Color('#FFFF00').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#FFFF00',
    },
  },
}))(Button);

const ColorIndigoButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#4B0082').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#4B0082',
    },
  },
}))(Button);

function converteFloatMoeda(valor) {
  var inteiro = null, decimal = null, c = null, j = null;
  var aux = [];
  valor = "" + valor;
  c = valor.indexOf(".", 0);
  //encontrou o ponto na string
  if (c > 0) {
    //separa as partes em inteiro e decimal
    inteiro = valor.substring(0, c);
    decimal = valor.substring(c + 1, valor.length);
  } else {
    inteiro = valor;
  }

  //pega a parte inteiro de 3 em 3 partes
  for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
    aux[c] = inteiro.substring(j - 3, j);
  }

  //percorre a string acrescentando os pontos
  inteiro = "";
  for (c = aux.length - 1; c >= 0; c--) {
    inteiro += aux[c] + '.';
  }
  //retirando o ultimo ponto e finalizando a parte inteiro

  inteiro = inteiro.substring(0, inteiro.length - 1);

  decimal = parseInt(decimal);
  if (isNaN(decimal)) {
    decimal = "00";
  } else {
    decimal = "" + decimal;
    if (decimal.length === 1) {
      decimal = decimal + "0";
    }
  }

  valor = "R$ " + inteiro + "," + decimal;

  return valor;
}

function maskCpf(value) {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    this.setState({
      aditivoSelecionado:{
        ccad_id: '',
        ccad_descricao: '',
        ccad_gera_fin: false,
        ccad_duracao: '',
        cursos_aditivo: []
      },
      curso_aditivo:{
        cursad_id: '',
        curso_atual:{
          curso_nome: ''
        },
        curso_novo:{
          curso_nome: ''
        },
        material: {
          matcont_valor: '',
          matcont_tipo: '',
          matcont_num_parcelas: '',
          matcont_dia_vencimento: '',
          matcont_formpag_id: '',
          matcont_tabprec_id: '',
          matcont_prod_id: '',
        }
      }
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'alunos')[0]
    if (!permissoes) permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'nova-matricula')[0]
    
    let permissoesAtivarInativar = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'ativa_inativar_contrato')[0]
    let permissoesCancelar = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cancelar_contrato')[0]
    let permissoesProrrogar = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'prorrogacao-contrato')[0]
    let permissoesEstornoProrrogar = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'estorno-prorrogacao-contrato')[0]

    let permissoesAtribuirCodigo = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'codigo-alfa-aluno')[0]
    let permissoesTransferirCodigo = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'codigo-alfa-transferencia-alunos')[0]
    let permissoesVincularAditivo = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'vincular-aditivo-curso')[0]
    
    if(permissoesAtivarInativar){
      this.setState({
        permissoesAtivarInativar
      })
    }

    if(permissoesCancelar){
      this.setState({
        permissoesCancelar
      })
    }

    if(permissoesProrrogar){
      this.setState({
        permissoesProrrogar
      })
    }

    if(permissoesEstornoProrrogar){
      this.setState({
        permissoesEstornoProrrogar
      })
    }

    if(permissoesAtribuirCodigo){
      this.setState({
        permissoesAtribuirCodigo
      })
    }
  
    if(permissoesTransferirCodigo){
      this.setState({
        permissoesTransferirCodigo
      })

      if(permissoesTransferirCodigo.perm_inserir){
        this.setState({
          acoesTabelaCodigo: ['transferirCodigo']
        })
      }
    }

    if(permissoesVincularAditivo){
      this.setState({permissoesVincularAditivo})
    }

    this.setState({
      permissoes
    })

    const aluno = this.props.aluno

    try { 
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/codigoMaterial/listByAluno/${aluno._id}`, this.getToken())

      this.setState({
        codigos: {
          list: data.map(codigo => {
            let hiscod_situacao = ''
            if (codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1].hiscod_situacao === 'AGUARDANDO_LIBERACAO') {
              hiscod_situacao = 'Aguardando Liberação'
            } else if (codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1].hiscod_situacao === 'LIBERADO_USO') {
              hiscod_situacao = 'Liberado P/ Uso'
            } else if (codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1].hiscod_situacao === 'ULTILIZADO') {
              hiscod_situacao = 'Ultilizado'
            } else if (codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1].hiscod_situacao === 'AGUARDANDO_APROVACAO_TRANSFERENCIA') {
              hiscod_situacao = 'Aguardando Aprovação Transf.'
            } else if (codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1].hiscod_situacao === 'CANCELADO') {
              hiscod_situacao = 'Cancelado'
            } else if (codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1].hiscod_situacao === 'TRANSFERIDO') {
              hiscod_situacao = 'Transferido'
            }

            return {
              _id: codigo.codigo_material.codmatdid_id,
              codmatdid_codigo: formatarCodigo(codigo.codigo_material.codmatdid_codigo),
              codmatdid_criado_em: moment(codigo.codigo_material.codmatdid_criado_em).format('DD/MM/YYYY HH:mm'),
              hiscod_data: moment(codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1 ].hiscod_data).format('DD/MM/YYYY HH:mm'),
              prod_descricao: codigo.codigo_material.produto.prod_descricao,
              pesf_nome: codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1 ].usuario.pessoa.fisica ? codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1 ].usuario.pessoa.fisica.pesf_nome.split(' ')[0] : 'Migração',
              hiscod_situacao
            }
          })
        }
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      let planoContas = data.filter(param => param.plancontas_ativo === true).map(param => {
        return {
          plancontas_id: param.plancontas_id,
          plancontas_codigo: param.plancontas_codigo,
          plancontas_descricao: param.plancontas_descricao,
          plancontas_ativo: param.plancontas_ativo,
          plancontas_slug: param.plancontas_slug,
          parametros: param.parametros
        }
      })

      this.setState({
        planosConta: {
          list: planoContas
        }
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/contratos/${aluno._id}`, this.getToken())
      const indexContratoAtivo = data.findIndex(param => param.contm_ativo === true)
      
      if(indexContratoAtivo !== -1){
        const { data: dataProdutos } = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/listByCurso/${data[indexContratoAtivo].contm_curso_id}/${aluno.aluno_unin_id}/${aluno._id}`, this.getToken())
      
        this.setState({
          materiais:{
            list: dataProdutos.map(material => {
              return {
                prod_id: material.prod_id,
                prod_descricao: material.prod_descricao,
                bok_start: material.bok_start
              }
            })
          }
        })
      }

      this.setState({
        contratos: {
          list: data.map(contrato => {
            let stcontmat_situacao = ''
            if (contrato.status.stcontmat_situacao === 'APROVADO') {
              stcontmat_situacao = 'Aprovado'
            } else if (contrato.status.stcontmat_situacao === 'REPROVADO') {
              stcontmat_situacao = 'Reprovado'
            } else if (contrato.status.stcontmat_situacao === 'INATIVO') {
              stcontmat_situacao = 'Inativo'
            } else if (contrato.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO') {
              stcontmat_situacao = 'Aguardando Aprovação'
            } else if (contrato.status.stcontmat_situacao === 'TRANSFERIDO') {
              stcontmat_situacao = 'Transferido'
            } else if (contrato.status.stcontmat_situacao === 'CANCELADO') {
              stcontmat_situacao = 'Cancelado'
            } else if (contrato.status.stcontmat_situacao === 'ENCERRADO') {
              stcontmat_situacao = 'Encerrado'
            } else if (contrato.status.stcontmat_situacao === 'AGUARDANDO_TRANFERENCIA') {
              stcontmat_situacao = 'Aguardando Transferência'
            } else if (contrato.status.stcontmat_situacao === 'TRANSFERENCIA_RECUSADA') {
              stcontmat_situacao = 'Transferência Recusada'
            }

            let dataCancelamento = null
            if (contrato.contm_data_cancelamento) {
              const dataHoje = new Date()
              dataCancelamento = moment(contrato.contm_data_cancelamento, moment.defaultFormat).toDate()
              dataCancelamento.setMinutes(dataCancelamento.getMinutes() - dataHoje.getTimezoneOffset())
            }

            return {
              _id: contrato.contm_id,
              contm_codigo: contrato.contm_codigo,
              contm_observacao: contrato.contm_observacao,
              contm_duracao: contrato.contm_duracao,
              contm_unin_id: contrato.contm_unin_id,
              contm_data_inicio_vigencia: moment(contrato.contm_data_inicio_vigencia).format('DD/MM/YYYY'),
              contm_data_fim_vigencia: moment(contrato.contm_data_fim_vigencia).format('DD/MM/YYYY'),
              contm_data_cancelamento: dataCancelamento ? moment(dataCancelamento).format('DD/MM/YYYY') : '',
              curso_nome: contrato.curso.curso_nome,
              stcontmat_situacao,
              stcontmat_observacao: contrato.status.stcontmat_observacao,
              inicio_vigencia_order: moment(contrato.contm_data_inicio_vigencia).format('YYYYMMDD'),
              fim_vigencia_order: moment(contrato.contm_data_fim_vigencia).format('YYYYMMDD'),
              data_cancelamento_order: dataCancelamento ? moment(dataCancelamento).format('YYYYMMDD') : ''
            }
          })
        }
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      aluno: aluno,
      loading: false
    })
  }

  async viewContrato(value) {

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/contratoMatricula/${value._id}`, this.getToken())
      const { data: prorrogacoes } = await axios.get(`${process.env.REACT_APP_API_URL}/prorrogacao/list/${value._id}`, this.getToken())

      let {permissoesEstornoProrrogar} = this.state

      let acoesTabelaProrrogacoes = ['imprimir', 'visualizarObservacao', 'reenviarAssinatura']

      if(permissoesEstornoProrrogar.perm_inserir){
        acoesTabelaProrrogacoes.push('estornarProrrogacao')
      }
      
      this.setState({
        acoesTabelaProrrogacoes,
      })
      
      this.setState({
        prorrogacoes:{
          list: prorrogacoes.map(prorrogacao => {
            let hisp_situacao = ''
        
            if(prorrogacao.hisp_situacao === 'PRORROGADO')
              hisp_situacao = 'Prorrogado'

            if(prorrogacao.hisp_situacao === 'AGUARDANDO_ASSINATURA')
              hisp_situacao = 'Aguardando Assinatura'
          
            if(prorrogacao.hisp_situacao === 'CANCELADO')
              hisp_situacao = 'Cancelado'

            return {
              _id: prorrogacao.hisp_id,
              hisp_data: moment(prorrogacao.hisp_criado_em).format('DD/MM/YYYY'),
              hisp_dataOrder: moment(prorrogacao.hisp_criado_em).format('YYYYMMDD'),
              hisp_dias: prorrogacao.hisp_dias,
              pesf_nome: prorrogacao.usuario.pessoa.fisica.pesf_nome,
              comercial: prorrogacao.comercial ? prorrogacao.comercial.fisica.pesf_nome : '',
              hisp_lanc_financ: prorrogacao.hisp_lanc_financ,
              hisp_codigo: prorrogacao.codigo,
              hisp_ativo: prorrogacao.hisp_ativo,
              hisp_observacao: prorrogacao.hisp_observacao,
              hisp_situacao,
              documento: prorrogacao.documento
            }
          })
        },
        aditivos: {
          list: data.aditivos.map(aditivo => {
            return {
              _id: aditivo.aditcontm_id,
              aditcontm_descricao: aditivo.aditcontm_descricao,
              aditcontm_criado_em: moment(aditivo.aditcontm_criado_em).format('DD/MM/YYYY'),
              aditcontm_situacao: aditivo.aditcontm_situacao === 'AGUARDANDO_ASSINATURA' ? 'Aguardando Assinatura': 'Aditivado',
              aditcontm_codigo: aditivo.codigo,
              documento: aditivo.documento
            }
          })
        }
      })

      let stcontmat_situacao = ''
      let stcontmat_observacao = '' 
     
      data.todos_status.sort((a, b) => (a.stcontmat_id > b.stcontmat_id) ? -1 : ((b.stcontmat_id > a.stcontmat_id) ? 1 : 0)).forEach(status => {

        if (status.stcontmat_situacao === 'APROVADO') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Aprovado/Ativado por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
        } else if (status.stcontmat_situacao === 'REPROVADO') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Reprovado por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
        } else if (status.stcontmat_situacao === 'INATIVO') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Inativado por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
        } else if (status.stcontmat_situacao === 'TRANSFERIDO') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Transferido por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
        } else if (status.stcontmat_situacao === 'CANCELADO') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Cancelado por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
        } else if (status.stcontmat_situacao === 'ENCERRADO') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Encerrado por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
        } else if (status.stcontmat_situacao === 'AGUARDANDO_TRANFERENCIA') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Solicitado transferência por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
        } else if (status.stcontmat_situacao === 'TRANSFERENCIA_RECUSADA') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Transferência recusada por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
        } else if (status.stcontmat_situacao === 'REMATRICULA') {
          stcontmat_observacao = stcontmat_observacao + (status.stcontmat_observacao ? status.stcontmat_observacao : '') + ` - Rematriculado por ${status.usuario.pessoa.fisica.pesf_nome.split(' ')[0]} em ${moment(status.stcontmat_data).format('DD/MM/YYYY HH:mm')}

`
}
      })

      if (data.status.stcontmat_situacao === 'APROVADO') {
        stcontmat_situacao = 'Aprovado'
      } else if (data.status.stcontmat_situacao === 'REPROVADO') {
        stcontmat_situacao = 'Reprovado'
      } else if (data.status.stcontmat_situacao === 'INATIVO') {
        stcontmat_situacao = 'Inativo'
      } else if (data.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO') {
        stcontmat_situacao = 'Aguardando Aprovação'
      } else if (data.status.stcontmat_situacao === 'AGUARDANDO_ASSINATURA') {
        stcontmat_situacao = 'Aguardando Assinatura'
      } else if (data.status.stcontmat_situacao === 'TRANSFERIDO') {
        stcontmat_situacao = 'Transferido'
      } else if (data.status.stcontmat_situacao === 'CANCELADO') {
        stcontmat_situacao = 'Cancelado'
      } else if (data.status.stcontmat_situacao === 'ENCERRADO') {
        stcontmat_situacao = 'Encerrado'
      } else if (data.status.stcontmat_situacao === 'AGUARDANDO_TRANFERENCIA') {
        stcontmat_situacao = 'Aguardando Transferência'
      } else if (data.status.stcontmat_situacao === 'TRANSFERENCIA_RECUSADA') {
        stcontmat_situacao = 'Transferência Recusada'
      }

      let habilitaProrrogar = false
      const fimVigencia = moment(data.contm_data_fim_vigencia)
      const hoje = moment()
      if (fimVigencia.diff(hoje, 'days') <= 60) {
        habilitaProrrogar = true
      }

      let contm_observacao = data.contm_observacao

      if(data.his_prorr){
        for (const i in data.his_prorr) {
          contm_observacao = contm_observacao + `
---------------------------------------------------------------------------------------------------------------------------------------------------------
${data.his_prorr[i].hisp_historico} 
Prorrogação realizada em ${moment(data.his_prorr[i].hisp_criado_em).format('DD/MM/YYYY HH:mm')} por ${data.his_prorr[i].usuario.pessoa.fisica.pesf_nome.split(' ')[0]}`
        }
      }

      let modulos = ''

      if(data.modulos){
        data.modulos = data.modulos.sort((a, b) => (a.modcontm_numero > b.modcontm_numero) ? 1 : (b.modcontm_numero > a.modcontm_numero) ? -1 : 0)

        for (let i = 0; i < data.modulos.length; i++) {
          if((i + 1) === data.modulos.length){
            modulos += `${data.modulos[i].modcontm_numero} (${data.modulos[i].modcontm_numero_extenso})`;
          }else{
            modulos += `${data.modulos[i].modcontm_numero} (${data.modulos[i].modcontm_numero_extenso}), `;
          }
        }
      }

      const contrato = {
        contm_id: data.contm_id,
        contm_data_inicio_vigencia: moment(data.contm_data_inicio_vigencia).format('DD/MM/YYYY'),
        contm_data_inicio_vigencia_sem_form: data.contm_data_inicio_vigencia,
        contm_data_fim_vigencia: moment(data.contm_data_fim_vigencia).format('DD/MM/YYYY'),
        contm_data_fim_vigencia_sem_form: data.contm_data_fim_vigencia,
        contm_data_cancelamento: data.contm_data_cancelamento ? moment(data.contm_data_fim_vigencia).format('DD/MM/YYYY') : '',
        contm_duracao: data.contm_duracao,
        contm_codigo_assinatura: data.contm_codigo_assinatura,
        contm_observacao,
        curso: {
          curso_id: data.curso.curso_id,
          curso_nome: data.curso.curso_nome,
        },
        contm_ativo: data.contm_ativo,
        unidade: {
          unin_numero: data.unidade.unin_numero,
          unin_descricao: data.unidade.unin_descricao,
        },
        contm_unin_id: data.contm_unin_id,
        contm_tabval_id: "",
        contm_data_vct_matricula: data.contm_data_vct_matricula ? moment(data.contm_data_vct_matricula).format('DD/MM/YYYY') : '',
        contm_data_vct_primeira_mensalidade: data.contm_data_vct_primeira_mensalidade ? moment(data.contm_data_vct_primeira_mensalidade).format('DD/MM/YYYY') : '',
        formapagto_id: data.contm_forma_pagto_id ? data.contm_forma_pagto_id : '',
        contm_data_hora_cadastro: moment(data.contm_data_hora_cadastro).format('DD/MM/YYYY HH:mm'),
        stcontmat_situacao,
        stcontmat_observacao,
        contm_codigo: data.contm_codigo,
        contm_metdiv_id: data.contm_metdiv_id ? data.contm_metdiv_id : '',
        contm_inicio_curso: data.contm_inicio_curso,
        contm_mes_inicio_curso: data.contm_mes_inicio_curso,
        contm_dia_aula_1: data.contm_dia_aula_1,
        contm_dia_aula_2: data.contm_dia_aula_2,
        contm_dia_aula_1_periodo: data.contm_dia_aula_1_periodo,
        contm_dia_aula_2_periodo: data.contm_dia_aula_2_periodo,
        contm_dia_aula_1_horario: data.contm_dia_aula_1_horario,
        contm_dia_aula_2_horario: data.contm_dia_aula_2_horario,
        contm_duracao_aula: data.contm_duracao_aula,
        modulos,
        modulos_array: data.modulos,
        tabela: {
          _id: data.tab_val_cont.tabvalcont_id,
          tabval_nome: data.tab_val_cont.tabvalcont_nome,
          tabval_num_parc: data.tab_val_cont.tabvalcont_num_parc,
          tabval_val_cheio_mensal: converteFloatMoeda(data.tab_val_cont.tabvalcont_val_cheio_mensal),
          tabval_val_matri: converteFloatMoeda(data.tab_val_cont.tabvalcont_val_matri),
          tabval_val_mensal: converteFloatMoeda(data.tab_val_cont.tabvalcont_val_mensal),
          tabval_val_mensal_total: converteFloatMoeda(data.tab_val_cont.tabvalcont_val_mensal * data.tab_val_cont.tabvalcont_num_parc)
        }
      }

      if (data.material_contrato) {
        contrato.material = {
          matcont_valor: converteFloatMoeda(data.material_contrato.matcont_valor),
          matcont_tipo: data.material_contrato.matcont_tipo,
          matcont_num_parcelas: data.material_contrato.matcont_num_parcelas,
          matcont_dia_vencimento: data.material_contrato.matcont_dia_vencimento,
          matcont_dia_vencimento_old:  data.material_contrato.matcont_dia_vencimento_old,
          matcont_formpag_id: data.material_contrato.matcont_formpag_id,
          matcont_tabprec_id: data.material_contrato.matcont_tabprec_id,
          matcont_prod_id: data.material_contrato.matcont_prod_id,
          prod_descricao: data.material_contrato.material.prod_descricao,
          forma_pagamento: data.material_contrato.forma_pagamento,
        }
      }

      if (data.forma_pagto) {
        contrato.forma_pagto = {
          formpag_descricao: data.forma_pagto.formpag_descricao
        }
      }

      this.setState({
        contrato,
        habilitaProrrogar
      })
    } catch (error) {
      console.log(error)
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      // return
    }
  }

  async consultaContaFinanceira(unidade_id){
    try {
      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${unidade_id}`, this.getToken())

      this.setState({
        contasFinanceira: {
          list: contasFinanceira
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  handleCloseModal() {
    this.setState({
      modalConfirmacao: !this.state.modalConfirmacao
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  inativarContrato() {
    this.setState({
      modalInativarContrato: true,
      inativarContrato: {
        motivoInativar: ''
      }
    })
  }

  ativarContrato() {
    this.setState({
      modalAtivarContrato: true,
      inativarContrato: {
        motivoInativar: ''
      }
    })
  }

  async cancelarContrato(){
    const aluno = this.props.aluno
    let {contrato, conta, planosConta } = this.state

    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/parcelaConta/byAluno/pendente/${aluno._id}/${contrato.contm_unin_id}`, this.getToken())

      const valores_quitacao = []
      const valores_quitacao_10_porc = []

      data.forEach(financeiro => {
        const mesFin = moment(financeiro.parcont_datavencimento).format('MM')
        const anoFin = moment(financeiro.parcont_datavencimento).format('YYYY')

        const mesAtual = moment(new Date()).format('MM')
        const anoAtual = moment(new Date()).format('YYYY')

        if(financeiro['conta.planocontas.plancontas_slug'] !== "mensalidade" ||
        (financeiro['conta.planocontas.plancontas_slug'] === "mensalidade" && financeiro.parcont_situacao === "VENCIDO") ||
        (mesFin === mesAtual && anoFin === anoAtual)){
          let parcont_valorparcela = 0

          if(financeiro['conta.planocontas.plancontas_slug'] === "mensalidade"){
            let date1 = new Date(moment(new Date()).format('YYYY-MM-DD')); 
            let date2 = new Date(moment(financeiro.parcont_datavencimento).format('YYYY-MM-DD')); 

            if (date2.valueOf() < date1.valueOf()) {
              parcont_valorparcela = parseFloat(contrato.tabela.tabval_val_cheio_mensal.replace('R$', '').replace('.', '').replace(',', '.')) + financeiro.parcont_multajuros
            }else{
              parcont_valorparcela = financeiro.parcont_valorparcela + financeiro.parcont_multajuros
            }
           
          }else{
            parcont_valorparcela = financeiro.parcont_valorparcela + financeiro.parcont_multajuros
          }
          
          valores_quitacao.push({
            _id: financeiro.parcont_id,
            parcont_datavencimento: moment(financeiro.parcont_datavencimento).format('DD/MM/YYYY'),
            plancontas_descricao: financeiro['conta.contpr_descricao'] + ' Parc. ' + financeiro.parcont_num + '/' + financeiro['conta.contpr_numparcela'],
            parcont_valorparcela
          })
        }


        if(financeiro['conta.planocontas.plancontas_slug'] === "mensalidade" && financeiro.parcont_situacao === "A VENCER" && (`${mesFin}${anoFin}` !== `${mesAtual}${anoAtual}`)){
          valores_quitacao_10_porc.push({
            _id: financeiro.parcont_id,
            parcont_datavencimento: financeiro.parcont_datavencimento,
            plancontas_descricao: financeiro['conta.contpr_descricao'] + ' Parc. ' + financeiro.parcont_num + '/' + financeiro['conta.contpr_numparcela'],
            parcont_valorparcela: (parseFloat(contrato.tabela.tabval_val_cheio_mensal.replace('R$', '').replace('.', '').replace(',', '.')) + financeiro.parcont_multajuros) / 10
          })
        }
      });

      if(valores_quitacao_10_porc.length > 0){
        valores_quitacao.push({
          _id: 9999,
          parcont_datavencimento: '',
          plancontas_descricao: `10% sobre a soma das mensalidades a vencer entre ${moment(valores_quitacao_10_porc[0].parcont_datavencimento).format('MM/YYYY')} até ${moment(valores_quitacao_10_porc[valores_quitacao_10_porc.length - 1].parcont_datavencimento).format('MM/YYYY')}`,
          parcont_valorparcela:somarValores(valores_quitacao_10_porc.map(parcela => { return parcela.parcont_valorparcela })),
          parcont_multajuros: 0
        })
      }

      conta.contpr_descricao = `Quitação de Contrato do Aluno ${aluno.aluno_matricula} - ${aluno.pessoaFisica.nome}`
      conta.contpr_valortotal = real(somarValores(valores_quitacao.map(parcela => { return parcela.parcont_valorparcela })))
      conta.parcelas[0].parcont_valorparcela = real(somarValores(valores_quitacao.map(parcela => { return parcela.parcont_valorparcela })))
      conta.parcelas[0].parcont_datavencimento = moment(new Date()).format('YYYY-MM-DD')

      const planoContasQuitacao = planosConta.list.filter(param => param.plancontas_slug === "quitacao")[0]

      conta.contpr_planocontas_id = planoContasQuitacao.plancontas_id
      conta.plancontas_codigo= planoContasQuitacao.plancontas_codigo
      conta.plancontas_descricao= planoContasQuitacao.plancontas_descricao

      this.setState({
        financeiros_pendentes:{
          list :data.map(financeiro => {
            return {
              _id: financeiro.parcont_id,
              parcont_datavencimento: moment(financeiro.parcont_datavencimento).format('DD/MM/YYYY'),
              parcont_situacao: financeiro.parcont_situacao,
              parcont_valorparcela: real(financeiro.parcont_valorparcela),
              parcont_multajuros: real(financeiro.parcont_multajuros),
              plancontas_descricao: financeiro['conta.contpr_descricao'] + ' Parc. ' + financeiro.parcont_num + '/' + financeiro['conta.contpr_numparcela'],
              formpag_descricao: financeiro['valores_pago.forma_pagto.formpag_descricao'] 
            }
          })
        },
        valores_quitacao: {
          list: valores_quitacao.map(financeiro =>{
            return {
              _id: financeiro.parcont_id,
              parcont_datavencimento: financeiro.parcont_datavencimento,
              plancontas_descricao: financeiro.plancontas_descricao,
              parcont_valorparcela: real(financeiro.parcont_valorparcela)
            }
          })
        },
        total_sugerido: real(somarValores(valores_quitacao.map(parcela => { return parcela.parcont_valorparcela }))),
        conta
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    await this.consultaContaFinanceira(contrato.contm_unin_id)

    this.setState({
      modalCancelarContrato: true,
      cancelarContrato: {
        motivoCancelamento: '',
        lanc_fin: false
      }
    })
  }

  updateFieldConta(event) {
    const conta = { ...this.state.conta }

    if (event.target.name === 'contpr_valortotal') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replace(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].parcont_valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = this.ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    conta[event.target.name] = event.target.value


    this.setState({ conta })
  }

  recalcularParcela(event, index) {
    const conta = { ...this.state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.parcont_valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", "."))

      let qtdParcelasEditado = this.state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        this.setState({
          qtdParcelasEditado
        })
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      

      
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)


      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].parcont_valorparcela = real(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = this.ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      this.setState({ conta })
    }
  }

  mudarParcelas(event) {
    const conta = { ...this.state.conta }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date()

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date();
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i));

      array.push({
        parcont_baixado: "",
        parcont_datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
        parcont_valorparcela: real(valorDividido),
        parcont_formapagto_id: "",
        parcont_documento: "",
        parcont_num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = this.ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.contpr_numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array
    this.setState({ conta })
  }

  ajustarArray(qtdParcela, array, valorDividido, sobra) {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].parcont_valorparcela = real((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  updateFieldParcela(event, index) {
    const conta = { ...this.state.conta }
    conta.parcelas[index - 1][event.target.name] = event.target.value
    this.setState({ conta })
  }

  verificaDataParcela(event, index){
    const {conta, planoContas} = this.state

    const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
    const data_vencimento = new Date(event.target.value)

    if (data_vencimento.getTime() < data_hoje.getTime()) {
      conta.parcelas[index - 1].parcont_datavencimento = conta.parcelas[index - 1].parcont_datavencimento_orig
    }else{
      conta.parcelas[index - 1].parcont_datavencimento_orig = event.target.value
    }
    
    for (let i = index; i < conta.parcelas.length; i++) {
      let count = (i - index ) + 1

      if(planoContas.parametros.paramfin_parcelamento === 'Mensal'){
        let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

        conta.parcelas[i].parcont_datavencimento = novaData
        conta.parcelas[i].parcont_datavencimento_orig = novaData
      }else{
        let qtdDias = 30 *  count
        let novaData = moment(event.target.value).add(qtdDias, 'days').format('YYYY-MM-DD')
        let diaSemana = moment(novaData).format('dddd')
        
        if(diaSemana === 'sábado'){
          novaData = moment(novaData).add(2, 'days').format('YYYY-MM-DD')
        }else if(diaSemana === 'domingo'){
          novaData = moment(novaData).add(1, 'days').format('YYYY-MM-DD')
        }

        conta.parcelas[i].parcont_datavencimento = novaData
        conta.parcelas[i].parcont_datavencimento_orig = novaData
      }
    }

    this.setState({ conta })
  }

  handleCloseModalInativarContrato() {
    this.setState({
      modalInativarContrato: false,
      inativarContrato: {
        motivoInativar: ''
      }
    })
  }

  handleCloseModalAtivarContrato() {
    this.setState({
      modalAtivarContrato: false,
      inativarContrato: {
        motivoInativar: ''
      }
    })
  }

  handleClosemodalCancelarContrato(){
    this.setState({
      modalCancelarContrato: false,
      cancelarContrato: {
        motivoCancelamento: ''
      }
    })
  }

  async confirmarInativar(value) {
    const { contrato, inativarContrato } = this.state
    if (value) {

      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/aluno/inativarContrato/${contrato.contm_id}`, inativarContrato, this.getToken())

        window.location.reload()

      } catch (error) {
        this.setState({
          modalErro: true,
          msgErro: error.response.data.error,
        })
      }

    } else {
      this.setState({
        modalInativarContrato: false,
        inativarContrato: {
          motivoInativar: ''
        }
      })
    }
  }

  async confirmarAtivar(value) {
    const { contrato, inativarContrato } = this.state
    if (value) {

      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/aluno/ativarContrato/${contrato.contm_id}`, inativarContrato, this.getToken())

        window.location.reload()

      } catch (error) {
        this.setState({
          modalErro: true,
          msgErro: error.response.data.error,
        })
      }

    } else {
      this.setState({
        modalAtivarContrato: false,
        inativarContrato: {
          motivoInativar: ''
        }
      })
    }
  }

  updateFieldInativar(event) {
    const { inativarContrato } = this.state

    inativarContrato[event.target.name] = event.target.value

    this.setState({ inativarContrato })
  }

  updateFieldCancelar(event){
    const { cancelarContrato } = this.state

    cancelarContrato[event.target.name] = event.target.value

    this.setState({ cancelarContrato })
  }
  
  async confirmarCancelamento(value){
    if(value){
      this.setState({loadingSalvar: true})
      let {conta, cancelarContrato, contrato, financeiros_pendentes} = this.state

      if(cancelarContrato.lanc_fin){
        if(conta.contpr_valortotal === "" || parseFloat(conta.contpr_valortotal.replace(".", "").replace(",", ".")) === 0 ){
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `Por favor informar o valor da conta.`
          })
          this.setState({loadingSalvar: false})
          return
        }
  
        if(parseFloat(conta.contpr_valortotal.replace(".", "").replace(",", ".")) < 0 ){
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `O valor da conta não pode ser negativo.`
          })
          this.setState({loadingSalvar: false})
          return
        }

        for (let i = 0; i < conta.parcelas.length; i++) {
          if(conta.parcelas[i].parcont_formapagto_id === ""){
            this.handleOpenDialog({
              titulo: 'Erro no Cadastro',
              descricao: `Por favor informar a forma de pagamento da parcela ${conta.parcelas[i].index}.`
            })
            this.setState({loadingSalvar: false})
            return
          }
  
          if(conta.parcelas[i].parcont_datavencimento === ""){
            this.handleOpenDialog({
              titulo: 'Erro no Cadastro',
              descricao: `Por favor informar a data de vencimento da parcela ${conta.parcelas[i].index}.`
            })
            this.setState({loadingSalvar: false})
            return
          }

          const forma_selecionada = this.state.formasPagamento.list.filter(param => param.formpag_id === parseInt(conta.parcelas[i].parcont_formapagto_id))[0]
          const conta_selecionada = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(conta.parcelas[i].contfin_id))[0]

          if(conta_selecionada && conta_selecionada.conta_corrente.parceiro){
            if(
              moment(conta.parcelas[i].parcont_datavencimento).isBefore(new Date()) 
              && forma_selecionada.formpag_slug === 'boleto'){
                this.setState({ loadingSalvar: false })
                this.handleOpenDialog({
                  titulo: "Data Invalida!",
                  descricao: `A data de vencimento da parcela ${conta.parcelas[i].index} não pode ser inferior ao dia de amanhã!`
                })
                this.setState({loadingSalvar: false})
                return
            }

            let valor_parcela = parseFloat(conta.parcelas[i].parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", "."))
        
            if(valor_parcela < 3 && (forma_selecionada.formpag_slug === 'boleto' || forma_selecionada.formpag_slug === 'pix')){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Valor Inferior!",
                descricao: "O Valor do boleto/pix não pode ser inferior a 3 Reias!"
              })

              return
            }
          }
        }
      }

      if(cancelarContrato.motivoCancelamento === ""){
        this.handleOpenDialog({
          titulo: 'Erro no Cadastro',
          descricao: 'Por favor informar o motivo do cancelamento.'
        })
        this.setState({loadingSalvar: false})
        return
      }

      const dados = {
        motivoCancelamento: cancelarContrato.motivoCancelamento,
        conta,
        financeiros_pendentes: financeiros_pendentes.list.map(value => {
          return {
            parcont_id: value._id
          }
        })
      }

      if(!cancelarContrato.lanc_fin){
        delete dados.conta
      }else{
        dados.conta.contpr_tipo = 'RECEBER'
        dados.conta.contpr_valortotal = parseFloat(dados.conta.contpr_valortotal.replace(".", "").replace(",", "."))

        for (let i = 0; i < dados.conta.parcelas.length; i++) {
          dados.conta.parcelas[i].parcont_valorparcela = parseFloat(dados.conta.parcelas[i].parcont_valorparcela.replace(".", "").replace(",", "."))
          dados.conta.parcelas[i].parcont_baixado = false
          dados.conta.parcelas[i].parcont_num = dados.conta.parcelas[i].index
          dados.conta.parcelas[i].formas_pgto = [
            {
              valpag_formapagto_id: parseInt(dados.conta.parcelas[i].parcont_formapagto_id),
              contfin_id: dados.conta.parcelas[i].contfin_id ? parseInt(dados.conta.parcelas[i].contfin_id) : ''
            }
          ]

          if(dados.conta.parcelas[i].formas_pgto[0].contfin_id === ''){
            delete dados.conta.parcelas[i].formas_pgto[0].contfin_id
          }

          delete dados.conta.parcelas[i].parcont_formapagto_id
          delete dados.conta.parcelas[i].contfin_id
        }

      }
      
      if(financeiros_pendentes.list.length === 0){
        delete dados.financeiros_pendentes
      }
      
      try {
        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cancelando Contrato...'
          }
        })
        
        const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/aluno/cancelarContrato/${contrato.contm_id}`, dados, this.getToken())
     
        // for (const parcela of data.parcelas_criadas) {
        //   this.setState({
        //     alerta: {
        //       open: true,
        //       severity: 'info',
        //       message: 'Lançando Financerio...'
        //     }
        //   })
        //   if(parcela.forma_pgto === 'boleto'){
        //     await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: parcela.parcont_id}, this.getToken())
        //   }else if (parcela.forma_pgto === 'pix'){
        //     await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: parcela.parcont_id}, this.getToken())
        //   }
        // }
        
        try {
  
          const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/contrato/cancelamento/${contrato.contm_id}/imprimir`, {}, this.getToken())
  
          const dadosImprimir = { path: respImprimir.data }
          const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
          const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
          saveAs(pdfBlob, `Contrato-Aluno.pdf`); 
  
          window.location.reload()
        } catch ({response}) {
          this.handleOpenDialog({
            titulo: 'Aviso!',
            descricao: response.data.message
          })
          let i = 0

          const timer = setInterval(function() {
            if (i >= 2) {
              window.location.reload()
              clearInterval(timer)
            }

            i++
          }, 1000)

          return
        }

      } catch ({response}) {
        this.handleOpenDialog({
          titulo: 'Ops!',
          descricao: response.data.message
        })
        this.setState({loadingSalvar: false})
        return
      }


    }else{
      this.setState({
        modalCancelarContrato: false,
        cancelarContrato: {
          motivoCancelamento: ''
        }
      })
    }
  }

  verifyFinishedDate(event) {
    const dataHoje = new Date(moment().format('YYYY-MM-DD'))
    const dataInformada = new Date(event.target.value)

    if (dataInformada < dataHoje) {
      const { prorrogarContrato } = this.state
      prorrogarContrato[event.target.name] = ''
      this.setState({
        prorrogarContrato
      })

      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'A data informada é menor que a data atual. Favor informar uma data válida'
      })
      return
    }

  }


  async prorrogarContrato(){

    const {contrato, planosConta, aluno} = this.state

    const planoContasProrrogacao = planosConta.list.filter(param => param.plancontas_id === 62)[0]

    const prorrogarContrato = {
      qtdDias: 0,
      contm_data_fim_vigencia_orig: contrato.contm_data_fim_vigencia_sem_form,
      contm_data_fim_vigencia_nova: contrato.contm_data_fim_vigencia_sem_form,
      lanc_fin: false,
      lanc_fin_taxa: false,
      fun_id: '',
      nome_func : '',
      contm_tabval_id: '',
      contm_data_vct_primeira_mensalidade: '',
      observacao: '',
      tabela: {
        _id: '',
        tabval_nome: '',
        tabval_num_parc: '',
        tabval_val_cheio_mensal: '',
        tabval_val_matri: '',
        tabval_val_mensal: '',
        tabval_val_mensal_total: real(0)
      }
    }

    await this.consultaContaFinanceira(contrato.contm_unin_id)
    await this.buscaPlanos(contrato.contm_unin_id, contrato.curso.curso_id)

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data : funcoes } = await axios.post(`${process.env.REACT_APP_API_URL}/comissaoFuncao/funcoes`, dados, this.getToken())

      let funcionarios = []

      for (const i in funcoes) {
        for (const j in funcoes[i].contratos) {
          funcionarios.push({
            fun_id: funcoes[i].contratos[j].funcionario.fun_id,
            func_nivel: funcoes[i].func_nivel,
            pesf_nome: funcoes[i].contratos[j].funcionario.fisica.pesf_nome
          })
        }
      }

      funcionarios.sort((a, b) => (a.func_nivel > b.func_nivel) ? 1 : ((b.func_nivel > a.func_nivel) ? -1 : 0))

      this.setState({
        funcionarios:{
          list: funcionarios
        }
      })

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/comissao/getComercial/${contrato.contm_id}`, this.getToken())
      
      if(data){
        prorrogarContrato.fun_id = data.func.fun_id
        prorrogarContrato.nome_func = data.func.fisica.pesf_nome
      }else{
        prorrogarContrato.fun_id = funcionarios[0].fun_id
        prorrogarContrato.nome_func = funcionarios[0].pesf_nome
      }
    
    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      modalProrrogarContrato: true,
      prorrogarContrato,
      conta: {
        contpr_descricao: `Taxa Administrativa Prorrogação de Contrato ${aluno.aluno_matricula} - ${aluno.pessoaFisica.nome}`,
        contpr_tipo: "A RECEBER",
        contpr_valortotal: "",
        contpr_numparcela: 1,
        contpr_planocontas_id: planoContasProrrogacao.plancontas_id,
        plancontas_codigo: planoContasProrrogacao.plancontas_codigo,
        plancontas_descricao: planoContasProrrogacao.plancontas_descricao,
        qtd_parcelas: 1,
        parcelas: [
          {
            parcont_baixado: "",
            parcont_datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            parcont_valorparcela: "",
            parcont_formapagto_id: "",
            parcont_documento: "",
            parcont_num: "",
            contfin_id: "",
            parcela_editada: false,
            index: 1
          }
        ]
      }
    })
  }

  updateFieldProrrogar(event){
    const {prorrogarContrato} = this.state
    const {name, value} = event.target
    const planos = this.state.planos.list

    if(name === 'qtdDias'){
      prorrogarContrato.contm_data_fim_vigencia_nova = moment(prorrogarContrato.contm_data_fim_vigencia_orig).add(value, 'days').format('Y-MM-DD')
    }

    if(name === 'contm_tabval_id'){
      const index = planos.findIndex(tabela => tabela._id === parseInt(event.target.value))

      prorrogarContrato.tabela = {
        _id: planos[index]._id,
        tabval_nome: planos[index].tabval_nome,
        tabval_num_parc: planos[index].tabval_num_parc,
        tabval_val_cheio_mensal: planos[index].tabval_val_cheio_mensal,
        tabval_val_matri: planos[index].tabval_val_matri,
        tabval_val_mensal: planos[index].tabval_val_mensal,
        tabval_val_mensal_total: real(parseFloat(planos[index].tabval_val_mensal.replace("R$ ", "").replace(".", "").replace(",", ".")) * planos[index].tabval_num_parc)
      }

      prorrogarContrato.tabval_nome = planos[index].tabval_nome
    }

    prorrogarContrato[name] = value

    this.setState({ prorrogarContrato})
  }

  updateFieldLancFinanceiro(){
    const {prorrogarContrato} = this.state
    
    prorrogarContrato.lanc_fin = !prorrogarContrato.lanc_fin
    
    this.setState({
      prorrogarContrato
    })
  }

  updateFieldLancFinanceiroTaxa(){
    const {prorrogarContrato} = this.state
    
    prorrogarContrato.lanc_fin_taxa = !prorrogarContrato.lanc_fin_taxa
    
    this.setState({
      prorrogarContrato
    })
  }

  updateFieldLancFinanceiroCancelamento(){
    const {cancelarContrato} = this.state
    
    cancelarContrato.lanc_fin = !cancelarContrato.lanc_fin
    
    this.setState({
      cancelarContrato
    })
  }

  async confirmarProrrogacao(value){
    this.setState({
      disabledButtonProrrogar: true
    })
   
    if(value){

      const {prorrogarContrato, conta, contrato, aluno, planosConta} = this.state
     
      let conta_taxa = conta

      if(parseFloat(prorrogarContrato.qtdDias) < 1){
        this.setState({
          disabledButtonProrrogar: false
        })
        this.handleOpenDialog({
          titulo: 'Erro no Cadastro',
          descricao: 'Por favor informar a qtd de dias.'
        })
        return
      }

      if(prorrogarContrato.qtdDias === ''){
        this.setState({
          disabledButtonProrrogar: false
        })
        this.handleOpenDialog({
          titulo: 'Erro no Cadastro',
          descricao: 'Por favor informar a qtd de dias.'
        })
        return
      }

      if(prorrogarContrato.fun_id === ''){
        this.setState({
          disabledButtonProrrogar: false
        })
        this.handleOpenDialog({
          titulo: 'Erro no Cadastro',
          descricao: 'Por favor informar o comercial.'
        })
        return
      }

      if(prorrogarContrato.lanc_fin_taxa){
        if(conta.contpr_valortotal === "" || parseFloat(conta.contpr_valortotal.replace(".", "").replace(",", ".")) === 0 ){
          this.setState({
            disabledButtonProrrogar: false
          })
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `Por favor informar o valor da conta.`
          })
          return
        }

        if(parseFloat(conta.contpr_valortotal.replace(".", "").replace(",", ".")) < 0 ){
          this.setState({
            disabledButtonProrrogar: false
          })
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `O valor da conta não pode ser negativo.`
          })
          return
        }

        for (let i = 0; i < conta.parcelas.length; i++) {
          if(conta.parcelas[i].parcont_formapagto_id === ""){
            this.setState({
              disabledButtonProrrogar: false
            })
            this.handleOpenDialog({
              titulo: 'Erro no Cadastro',
              descricao: `Por favor informar a forma de pagamento da parcela ${conta.parcelas[i].index}.`
            })
            return
          }
  
          if(conta.parcelas[i].parcont_datavencimento === ""){
            this.setState({
              disabledButtonProrrogar: false
            })
            this.handleOpenDialog({
              titulo: 'Erro no Cadastro',
              descricao: `Por favor informar a data de vencimento da parcela ${conta.parcelas[i].index}.`
            })
            return
          }

          const forma_selecionada = this.state.formasPagamento.list.filter(param => param.formpag_id === parseInt(conta.parcelas[i].parcont_formapagto_id))[0]
          const conta_selecionada = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(conta.parcelas[i].contfin_id))[0]

          if(conta_selecionada && conta_selecionada.conta_corrente.parceiro){
            if(
              moment(conta.parcelas[i].parcont_datavencimento).isBefore(new Date()) 
              && forma_selecionada.formpag_slug === 'boleto'){
                this.setState({ loadingSalvar: false })
                this.handleOpenDialog({
                  titulo: "Data Invalida!",
                  descricao: `A data de vencimento da parcela ${conta.parcelas[i].index} não pode ser inferior ao dia de amanhã!`
                })
  
                return
            }

            let valor_parcela = parseFloat(conta.parcelas[i].parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", "."))
        
            if(valor_parcela < 3 && (forma_selecionada.formpag_slug === 'boleto' || forma_selecionada.formpag_slug === 'pix')){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Valor Inferior!",
                descricao: "O Valor do boleto/pix não pode ser inferior a 3 Reias!"
              })

              return
            }
          }
        }
      }

      if(prorrogarContrato.lanc_fin){
        if(prorrogarContrato.contm_tabval_id === ''){
          this.setState({
            disabledButtonProrrogar: false
          })
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `Por favor selecionar o plano de pagamento`
          })
          return
        }

        if(prorrogarContrato.contm_data_vct_primeira_mensalidade === ''){
          this.setState({
            disabledButtonProrrogar: false
          })
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `Por favor selecionar a data de vencimento da primeira mensalidade`
          })
          return
        }

        if(prorrogarContrato.formapagto_id === ''){
          this.setState({
            disabledButtonProrrogar: false
          })
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `Por favor informar a forma de pagemento das mensalidades`
          })
          return
        }
      }

      const dados = {
        contm_data_fim_vigencia_orig: prorrogarContrato.contm_data_fim_vigencia_orig,
        contm_data_fim_vigencia_nova: prorrogarContrato.contm_data_fim_vigencia_nova,
        qtdDias: prorrogarContrato.qtdDias,
        hisp_fun_id: prorrogarContrato.fun_id,
        observacao: prorrogarContrato.observacao,
        conta_taxa
      }

      if(!prorrogarContrato.lanc_fin_taxa){
        delete dados.conta_taxa
      }else{
        dados.conta_taxa.contpr_tipo = 'RECEBER'
        dados.conta_taxa.contpr_valortotal = parseFloat(dados.conta_taxa.contpr_valortotal.replace(".", "").replace(",", "."))
  
        for (let i = 0; i < dados.conta_taxa.parcelas.length; i++) {
          dados.conta_taxa.parcelas[i].parcont_valorparcela = parseFloat(dados.conta_taxa.parcelas[i].parcont_valorparcela.replace(".", "").replace(",", "."))
          dados.conta_taxa.parcelas[i].parcont_baixado = false
          dados.conta_taxa.parcelas[i].parcont_num = dados.conta_taxa.parcelas[i].index
          dados.conta_taxa.parcelas[i].formas_pgto = [
            {
              valpag_formapagto_id: parseInt(dados.conta_taxa.parcelas[i].parcont_formapagto_id),
              contfin_id: dados.conta_taxa.parcelas[i].contfin_id ? parseInt(dados.conta_taxa.parcelas[i].contfin_id) : ''
            }
          ]

          if(dados.conta_taxa.parcelas[i].formas_pgto[0].contfin_id === ''){
            delete dados.conta_taxa.parcelas[i].formas_pgto[0].contfin_id
          }
  
          delete dados.conta_taxa.parcelas[i].parcont_formapagto_id
          delete dados.conta_taxa.parcelas[i].contfin_id
        }
      }

      if(prorrogarContrato.lanc_fin){
        console.log(prorrogarContrato.tabela)
        const planoContasProrrogacao = planosConta.list.filter(param => param.plancontas_id === 110)[0]

        let parcelas = []

        const contm_dia_vct_mensalidade = moment(prorrogarContrato.contm_data_vct_primeira_mensalidade).format('DD')
        const contm_mes_vct_mensalidade = moment(prorrogarContrato.contm_data_vct_primeira_mensalidade).format('MM')
        const contm_ano_vct_mensalidade = moment(prorrogarContrato.contm_data_vct_primeira_mensalidade).format('YYYY')

        let dia = contm_dia_vct_mensalidade
        if (dia < 10) dia = dia

        let mes = contm_mes_vct_mensalidade
        if (mes < 10) mes = mes

        let dataParcelas = moment(`${contm_ano_vct_mensalidade}-${mes}-${dia}`)

        for (let i = 0; i < prorrogarContrato.tabela.tabval_num_parc; i++) {
          const data_vencimento = dataParcelas.add((i == 0 ? 0 : 1), 'M').format('YYYY-MM-DD')

          parcelas.push({
            parcont_num: i + 1,
            parcont_datavencimento: data_vencimento,
            parcont_valorparcela: parseFloat(prorrogarContrato.tabela.tabval_val_mensal.replace(".", "").replace(",", ".")),
            parcont_documento: `(R$ ${parseFloat(prorrogarContrato.tabela.tabval_val_cheio_mensal.replace(".", "").replace(",", "."))})`,
            formas_pgto: [
              {
                valpag_formapagto_id: prorrogarContrato.formapagto_id,
                contfin_id: prorrogarContrato.contfin_id
              }
            ]
          })
        }

        let conta_mensalidade = {
          contpr_descricao: `Mensalidade (Prorrogação) do(a) aluno(a) ${aluno.aluno_matricula} - ${aluno.pessoaFisica.nome}`,
          contpr_tipo: "RECEBER",
          contpr_valortotal: parseFloat(prorrogarContrato.tabela.tabval_val_mensal_total.replace(".", "").replace(",", ".")),
          contpr_numparcela: prorrogarContrato.tabela.tabval_num_parc,
          contpr_planocontas_id: planoContasProrrogacao.plancontas_id,
          contpr_unin_id: contrato.contm_unin_id,
          parcelas
        }

        dados.conta_mensalidade = conta_mensalidade
        dados.valor_cheio = parseFloat(prorrogarContrato.tabela.tabval_val_cheio_mensal.replace(".", "").replace(",", "."))
      }

      try {

        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cadastrando Prorrogação...'
          }
        })

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/prorrogacao/${contrato.contm_id}`, dados, this.getToken())

        for (const parcela of data.parcelas_criadas) {
          this.setState({
            alerta: {
              open: true,
              severity: 'info',
              message: 'Lançando Financerio...'
            }
          })
          if(parcela.forma_pgto === 'boleto'){
            await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: parcela.parcont_id}, this.getToken())
          }else if (parcela.forma_pgto === 'pix'){
            // await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: parcela.parcont_id}, this.getToken())
          }
        }

        window.location.reload()

        this.setState({
          disabledButtonProrrogar: false
        })
        
      } catch ({response}) {
        this.setState({
          disabledButtonProrrogar: false
        })
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

    }else{
      this.setState({
        modalProrrogarContrato: false,
        prorrogarContrato: {
          qtdDias: '',
          contm_data_fim_vigencia_orig: '',
          contm_data_fim_vigencia_nova: '',
          lanc_fin: false
        },
        disabledButtonProrrogar: false
      })
    }
  }

  vincularCodigo(){
    this.setState({
      modalVincularCodigo: true
    })
  }

  handleClosemodalVincularCodigo(){
    this.setState({
      modalVincularCodigo: false
    })
  }

  async confirmarVincularCodigo(value){
    if(value){
      const {conta, possuiFinanceiroCodigo, codigo} = this.state
      const aluno = this.props.aluno

      if(codigo.codmatdid_id === ""){
        this.handleOpenDialog({
          titulo: 'Erro no Cadastro',
          descricao: `Por favor selecionar um material didatico com codigo disponivel.`
        })
        return
      }
      
      if(possuiFinanceiroCodigo){
        if(conta.contpr_valortotal === "" || parseFloat(conta.contpr_valortotal.replace(".", "").replace(",", ".")) === 0 ){
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `Por favor informar o valor da conta.`
          })
          return
        }

        if(parseFloat(conta.contpr_valortotal.replace(".", "").replace(",", ".")) < 0 ){
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `O valor da conta não pode ser negativo.`
          })
          return
        }

        for (let i = 0; i < conta.parcelas.length; i++) {
          if(conta.parcelas[i].parcont_formapagto_id === ""){
            this.handleOpenDialog({
              titulo: 'Erro no Cadastro',
              descricao: `Por favor informar a forma de pagamento da parcela ${conta.parcelas[i].index}.`
            })
            return
          }
  
          if(conta.parcelas[i].parcont_datavencimento === ""){
            this.handleOpenDialog({
              titulo: 'Erro no Cadastro',
              descricao: `Por favor informar a data de vencimento da parcela ${conta.parcelas[i].index}.`
            })
            return
          }
        }
      }

      const dados = {
        aluno_id: aluno._id,
        unin_id: aluno.aluno_unin_id,
        codmatdid_id: codigo.codmatdid_id,
        conta
      }

      if(!possuiFinanceiroCodigo){
        delete dados.conta
      }else{
        dados.conta.contpr_tipo = 'RECEBER'
        dados.conta.contpr_valortotal = parseFloat(dados.conta.contpr_valortotal.replace(".", "").replace(",", "."))
  
        for (let i = 0; i < dados.conta.parcelas.length; i++) {
          dados.conta.parcelas[i].parcont_valorparcela = parseFloat(dados.conta.parcelas[i].parcont_valorparcela.replace(".", "").replace(",", "."))
          dados.conta.parcelas[i].parcont_baixado = false
          dados.conta.parcelas[i].parcont_num = dados.conta.parcelas[i].index
          dados.conta.parcelas[i].formas_pgto = [
            {
              valpag_formapagto_id: parseInt(dados.conta.parcelas[i].parcont_formapagto_id)
            }
          ]
  
          delete dados.conta.parcelas[i].parcont_formapagto_id
          delete dados.conta.parcelas[i].contfin_id
        }
      }

      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/codigoMaterial/vincularAluno`, dados, this.getToken())
        
        const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/aditivoMaterial/${codigo.codmatdid_id}/imprimir`, {}, this.getToken())

        const dadosImprimir = { path: respImprimir.data }
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Contrato-Aluno.pdf`); 

        window.location.reload()
      } catch ({response}) {
        this.handleOpenDialog({
          titulo: 'Aviso!',
          descricao: response.data.message
        })
        let i = 0

        const timer = setInterval(function() {
          if (i >= 2) {
            window.location.reload()
            clearInterval(timer)
          }

          i++
        }, 1000)

        return
      }

    }else{
      this.setState({
        modalVincularCodigo: false
      })
    }
  }
          
  estornarProrrogacao(row){
    this.setState({
      modalEstornarProrrogacao: true,
      prorrogacaoEstornar: row
    })
  }

  handleCloseModalEstornarProrrogacao(){
    this.setState({
      modalEstornarProrrogacao: false
    })
  }

  updateEstornoProrrogacao(event){
    const {estornoProrrogacao} = this.state

    estornoProrrogacao[event.target.name] = event.target.value

    this.setState({estornoProrrogacao})
  }

  async confirmarEstornoProrrogacao(value){
    if(value){
      const {estornoProrrogacao, prorrogacaoEstornar} = this.state

      if(estornoProrrogacao.estp_motivo === ""){
        this.handleOpenDialog({
          titulo: 'Erro no Cadastro',
          descricao: 'Por favor informar o motivo do estorno da prorrogação.'
        })
        return
      }
      estornoProrrogacao.estp_hisp_id = prorrogacaoEstornar._id

      await axios.post(`${process.env.REACT_APP_API_URL}/prorrogacao/estornar`, estornoProrrogacao, this.getToken())
    
      this.setState({
        modalEstornarProrrogacao: false,
        // snackbar:{
        //   mensagem: 'Prorrogação estornada com sucesso',
        //   open: true,
        //   severity: 'success'
        // }
      })
      
      window.location.reload()

    }else{
      this.setState({
        modalEstornarProrrogacao: false
      })
    }
  }

  async updateFieldMaterialCodigo(event, value){
    if(!value) return false

    let libera = true

    // if(value.bok_start){

    //   const {codigos, contratos} = this.state

    //   if(codigos.list.length > 0){
    //     libera = true
    //   }else{

    //     const indexContrato = contratos.list.findIndex(param => param.stcontmat_situacao === 'Aprovado')

    //     this.setState({
    //       material:{
    //         prod_id: '',
    //         prod_descricao: '',
    //         bok_start: ''
    //       }
    //     })

    //     this.handleOpenDialog({
    //       titulo: 'Ops...',
    //       descricao: `Este aluno não possui material didático para o ${contratos.list[indexContrato].curso_nome}`
    //     })
    //     return
    //   }

    // }else{
    //   libera = true
    // }

    if(libera){
      const aluno = this.props.aluno

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/codigoMaterial/listByProdByUni/${value.prod_id}/${aluno.aluno_unin_id}`, this.getToken())
      const { data: financeiro } = await axios.get(`${process.env.REACT_APP_API_URL}/codigoMaterial/verificaFinanceiro/${value.prod_id}/${aluno._id}`, this.getToken())
      const { data: plano_contas } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas/showByProd/${value.prod_id}`, this.getToken())
  
      if(!data[0]){
        this.handleOpenDialog({
          titulo: 'Opps!',
          descricao: `Esse material não possui códigos disponiveis.`
        })
        return
      }
  
      if(plano_contas.length === 0){
        this.handleOpenDialog({
          titulo: 'Opps!',
          descricao: `Esse material não possui plano de contas, por favor entrar em contato com a central.`
        })
        return
      }
  
      this.setState({
        material: value,
        codigo: data[0] ? data[0] : this.state.codigo,
        possuiFinanceiroCodigo: financeiro.length > 0 ? false : true,
        plano_contas: plano_contas[0],
        conta: {
          contpr_descricao: `Material ${value.prod_descricao} - ${aluno.aluno_matricula} - ${aluno.pessoaFisica.nome}`,
          contpr_tipo: "A RECEBER",
          contpr_valortotal: "",
          contpr_numparcela: 1,
          contpr_planocontas_id: plano_contas[0].plancontas_id,
          plancontas_codigo: plano_contas[0].plancontas_codigo,
          plancontas_descricao: plano_contas[0].plancontas_descricao,
          qtd_parcelas: 1,
          parcelas: [
            {
              parcont_baixado: "",
              parcont_datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              parcont_valorparcela: "",
              parcont_formapagto_id: "",
              parcont_documento: "",
              parcont_num: "",
              contfin_id: "",
              parcela_editada: false,
              index: 1
            }
          ]
        }
      })
    }
    
  }
        
  handleCloseSnackbar(){
    this.setState({
      snackbar:{
        mensagem: '',
        open: false,
        severity: ''
      }
    })
  }

  handleCloseModalProrrogarContrato(){
    this.setState({
      modalProrrogarContrato: false
    })
  }

  transferirCodigo(row){
    this.setState({
      modalTransferirCodigo: true,
      codigoSelecionado: row
    })
  }

  handleCloseModalTransferirCodigo(){
    this.setState({
      modalTransferirCodigo: false,
      buscaPor: 'cpf',
      search: '',
      loadingPessoa: false,
      alunoTransferenciaCodigo: null,
      codigoSelecionado: null
    })
  }

  async updateFieldSearch(event) {
    const { buscaPor } = this.state

    if (event.target.name === 'buscaPor') {
      this.setState({
        search: '',
        buscaPor: event.target.value
      })
    }

    if (event.target.name === 'search') {
      if (buscaPor === 'cpf') {
        this.setState({ search: maskCpf(event.target.value) })
      } else if (buscaPor === 'matricula') {
        this.setState({ search: event.target.value })
      }
    }
  }

  async buscaAluno() {
    const { search, buscaPor } = this.state
   
    this.setState({
      loadingPessoa: true
    })

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/search/${search}`, { buscaPor }, this.getToken())
      this.setState({
        loadingPessoa: false,
        alunoTransferenciaCodigo: data
      })
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Erro na Consulta!',
          descricao: response.data.message,
        },
        loadingPessoa: false
      })
    }
  }

  async confirmarTransferenciaCodigo(value){
    const {codigoSelecionado, alunoTransferenciaCodigo} = this.state

    if(value){
      this.setState({loadingSalvarTransf: true})
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/codigoMaterial/transferirAluno/${alunoTransferenciaCodigo.aluno_id}/${codigoSelecionado._id}`, {}, this.getToken())
        
        this.setState({
          modalTransferirCodigo: false,
          loadingSalvarTransf: false
        })
        
        window.location.reload()
      } catch ({response}) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro na Consulta!',
            descricao: response.data.message
          },
          loadingSalvarTransf: false
        })
      }
    }else{
      this.setState({
        modalTransferirCodigo: false,
        buscaPor: 'cpf',
        search: '',
        loadingPessoa: false,
        alunoTransferenciaCodigo: null,
        codigoSelecionado: null,
        loadingSalvarTransf: false
      })
    }

  }

  async vincularAditivo(){
    const {contrato, aluno, planosConta} = this.state

    await this.consultaContaFinanceira(contrato.contm_unin_id)

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/configAditivo/byCurso/${contrato.curso.curso_id}`, this.getToken())
      
      const planoContasMensalidade = planosConta.list.filter(param => param.plancontas_slug === "mensalidade")[0]

			this.setState({
				config_aditivos: {
					list: data.sort((a, b) => (a.ccad_descricao > b.ccad_descricao) ? 1 : (b.ccad_descricao > a.ccad_descricao) ? -1 : 0)
				},
        conta: {
          contpr_descricao: `Mensalidade do(a) aluno(a) ${aluno.aluno_matricula} - ${aluno.pessoaFisica.nome}`,
          contpr_tipo: "A RECEBER",
          contpr_valortotal: "",
          contpr_numparcela: 1,
          contpr_planocontas_id: planoContasMensalidade.plancontas_id,
          plancontas_codigo: planoContasMensalidade.plancontas_codigo,
          plancontas_descricao: planoContasMensalidade.plancontas_descricao,
          qtd_parcelas: 1,
          parcelas: [
            {
              parcont_baixado: "",
              parcont_datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              parcont_valorparcela: "",
              parcont_formapagto_id: "",
              parcont_documento: "",
              parcont_num: "",
              contfin_id: "",
              parcela_editada: false,
              index: 1
            }
          ]
        },
        modalVincularAditivo: true,
        planoContas: planoContasMensalidade
			})
      
    } catch ({response}) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Erro!',
          descricao: response.data.message
        },
        loadingSalvarTransf: false
      })
    }
  }

  async updateFieldMaterial(event){
    const {curso_aditivo} = this.state
    const materiais = this.state.materiais.list
    
    curso_aditivo.material[event.target.name] = event.target.value

    if (event.target.name === 'matcont_prod_id') {
      curso_aditivo.material.matcont_prod_descricao = materiais.filter(param => param._id === parseInt(event.target.value))[0].descricao
    
      await this.buscaTabelaPrecoMaterial()
    }
        
    this.setState({curso_aditivo})
  }

  async buscaTabelaPrecoMaterial() {
    const {curso_aditivo} = this.state
    const { material } = curso_aditivo

    if (material.matcont_prod_id === '') return true
    const aluno = this.props.aluno

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tabelaPreco/produto/${material.matcont_prod_id}/${aluno.contrato.contm_unin_id}`, this.getToken())

    this.setState({
      tabelaPrecoMaterial: Object.keys(data).length ? data : null
    })
  }

  handleClosemodalVincularAditivo(){
    this.setState({
      modalVincularAditivo: false
    })
  }

  updateFieldAditivo(event){
    const {aditivoSelecionado, config_aditivos} = this.state

    if(event.target.name === 'ccad_id'){
      const config = config_aditivos.list.filter(param => param.ccad_id === parseInt(event.target.value))[0]
      
      aditivoSelecionado.ccad_descricao = config.ccad_descricao
      aditivoSelecionado.cursos_aditivo = config.cursos_aditivo
      aditivoSelecionado.ccad_gera_fin = config.ccad_gera_fin
      aditivoSelecionado.ccad_duracao = config.ccad_duracao
    }

    aditivoSelecionado[event.target.name] = event.target.value

    this.setState({aditivoSelecionado})
  }

  async updateFieldCursoAditivo(event){
    const {curso_aditivo, aditivoSelecionado} = this.state
    
    const {cursos_aditivo} = aditivoSelecionado

    curso_aditivo[event.target.name] = event.target.value

    if(event.target.name === 'cursad_id'){
      const curso = cursos_aditivo.filter(param => param.cursad_id === parseInt(event.target.value))[0]
    
      curso_aditivo.material.matcont_prod_id = ''
      curso_aditivo.curso_atual.curso_nome = curso.curso_atual.curso_nome
      curso_aditivo.curso_novo.curso_nome = curso.curso_novo.curso_nome

      try {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/configAditivo/listBooks/${curso.curso_novo.curso_id}/${curso.curso_atual.curso_id}`, this.getToken())
        const produtos = []
  
        resp.data.forEach(produto => {
          produtos.push({
            _id: produto.prod_id,
            descricao: produto.prod_descricao,
          })
        })
    
        this.setState({
          materiais: {
            list: produtos
          }
        })
  
      } catch ({ response }) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }
    }
    
    this.setState({curso_aditivo})
  }

  async confirmarVincularAditivo(value){
    if(value){
      this.setState({loadingSalvar: true})

      const {conta, curso_aditivo, aditivoSelecionado, contrato} = this.state

      if(aditivoSelecionado.ccad_gera_fin){
        if(conta.contpr_valortotal === "" || parseFloat(conta.contpr_valortotal.replace(".", "").replace(",", ".")) === 0 ){
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `Por favor informar o valor da conta.`
          })
          this.setState({loadingSalvar: false})
          return
        }

        if(parseFloat(conta.contpr_valortotal.replace(".", "").replace(",", ".")) < 0 ){
          this.handleOpenDialog({
            titulo: 'Erro no Cadastro',
            descricao: `O valor da conta não pode ser negativo.`
          })
          this.setState({loadingSalvar: false})
          return
        }

        for (let i = 0; i < conta.parcelas.length; i++) {
          if(conta.parcelas[i].parcont_formapagto_id === ""){
            this.handleOpenDialog({
              titulo: 'Erro no Cadastro',
              descricao: `Por favor informar a forma de pagamento da parcela ${conta.parcelas[i].index}.`
            })
            this.setState({loadingSalvar: false})
            return
          }
  
          if(conta.parcelas[i].parcont_datavencimento === ""){
            this.handleOpenDialog({
              titulo: 'Erro no Cadastro',
              descricao: `Por favor informar a data de vencimento da parcela ${conta.parcelas[i].index}.`
            })
            this.setState({loadingSalvar: false})
            return
          }

          const forma_selecionada = this.state.formasPagamento.list.filter(param => param.formpag_id === parseInt(conta.parcelas[i].parcont_formapagto_id))[0]
          const conta_selecionada = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(conta.parcelas[i].contfin_id))[0]

          if(conta_selecionada && conta_selecionada.conta_corrente.parceiro){
            if(
              moment(conta.parcelas[i].parcont_datavencimento).isBefore(new Date()) 
              && forma_selecionada.formpag_slug === 'boleto'){
                this.setState({ loadingSalvar: false })
                this.handleOpenDialog({
                  titulo: "Data Invalida!",
                  descricao: `A data de vencimento da parcela ${conta.parcelas[i].index} não pode ser inferior ao dia de amanhã!`
                })
                this.setState({loadingSalvar: false})
                return
            }

            let valor_parcela = parseFloat(conta.parcelas[i].parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", "."))
        
            if(valor_parcela < 3 && (forma_selecionada.formpag_slug === 'boleto' || forma_selecionada.formpag_slug === 'pix')){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Valor Inferior!",
                descricao: "O Valor do boleto/pix da parcela não pode ser inferior a 3 Reias!"
              })

              return
            }
          }
        }
      }

      if (
        curso_aditivo.material.matcont_valor === "" ||
        curso_aditivo.material.matcont_num_parcelas === "" ||
        curso_aditivo.material.matcont_dia_vencimento === "" ||
        curso_aditivo.material.matcont_formpag_id === "" ||
        curso_aditivo.material.matcont_prod_id === ""
      ) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        this.setState({loadingSalvar: false})
        return
      }

      try {

        let matcont_valor = curso_aditivo.material.matcont_valor.replace("R$ ", "");
        matcont_valor = matcont_valor.replace(".", "");
        matcont_valor = matcont_valor.replace(",", ".");
        matcont_valor = parseFloat(matcont_valor)

        const forma_selecionada_material = this.state.formasPagamento.list.filter(param => param.formpag_id === parseInt(curso_aditivo.material.matcont_formpag_id))[0]
        const conta_selecionada_material = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(curso_aditivo.material.contfin_id))[0]

        if(conta_selecionada_material && conta_selecionada_material.conta_corrente.parceiro){
          if(matcont_valor < 3 && (forma_selecionada_material.formpag_slug === 'boleto' || forma_selecionada_material.formpag_slug === 'pix')){
            this.setState({ loadingSalvar: false })
            this.handleOpenDialog({
              titulo: "Valor Inferior!",
              descricao: "O Valor do boleto/pix do material não pode ser inferior a 3 Reias!"
            })

            return
          }
        }

        const dados = {
          contm_id: contrato.contm_id,
          cursad_id: curso_aditivo.cursad_id,
          ccad_duracao:aditivoSelecionado.ccad_duracao,
          conta,
          material: {
            matcont_valor,
            matcont_num_parcelas: parseInt(curso_aditivo.material.matcont_num_parcelas),
            matcont_dia_vencimento: parseInt(curso_aditivo.material.matcont_dia_vencimento),
            matcont_formpag_id: parseInt(curso_aditivo.material.matcont_formpag_id),
            matcont_prod_id: parseInt(curso_aditivo.material.matcont_prod_id),
            contfin_id: parseInt(curso_aditivo.material.contfin_id)
          }
        }

        if(dados.material.contfin_id === '' || !dados.material.contfin_id){
          delete dados.material.contfin_id
        }

        if(!aditivoSelecionado.ccad_gera_fin){
          delete dados.conta
        }else{
          dados.conta.contpr_tipo = 'RECEBER'
          dados.conta.contpr_valortotal = parseFloat(dados.conta.contpr_valortotal.replace(".", "").replace(",", "."))
    
          for (let i = 0; i < dados.conta.parcelas.length; i++) {
            dados.conta.parcelas[i].parcont_valorparcela = parseFloat(dados.conta.parcelas[i].parcont_valorparcela.replace(".", "").replace(",", "."))
            dados.conta.parcelas[i].parcont_baixado = false
            dados.conta.parcelas[i].parcont_num = dados.conta.parcelas[i].index
            dados.conta.parcelas[i].formas_pgto = [
              {
                valpag_formapagto_id: parseInt(dados.conta.parcelas[i].parcont_formapagto_id),
                contfin_id: dados.conta.parcelas[i].contfin_id ? parseInt(dados.conta.parcelas[i].contfin_id) : ''
              }
            ]

            if(dados.conta.parcelas[i].formas_pgto[0].contfin_id === '' || !dados.conta.parcelas[i].formas_pgto[0].contfin_id){
              delete dados.conta.parcelas[i].formas_pgto[0].contfin_id
            }
    
            delete dados.conta.parcelas[i].parcont_formapagto_id
            delete dados.conta.parcelas[i].contfin_id
          }
        }
        
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/configAditivo/vincularAluno`, dados, this.getToken())
        
        // for (const parcela of data.parcelas_criadas) {
        //   this.setState({
        //     alerta: {
        //       open: true,
        //       severity: 'info',
        //       message: 'Gerando Financeiro...'
        //     }
        //   })
        //   if(parcela.forma_pgto === 'boleto'){
        //     await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: parcela.parcont_id}, this.getToken())
        //   }else if (parcela.forma_pgto === 'pix'){
        //     await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: parcela.parcont_id}, this.getToken())
        //   }
        // }

        const dadosImprimir = { path: data.imprimir.path }
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Aditivo-Curso.pdf`); 

        window.location.reload()


      } catch ({response}) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        this.setState({loadingSalvar: false})
        return
      }

    }else{
      this.setState({
        modalVincularAditivo: false
      })
    }
  }

  async imprimirDoc(row){
    try {
      const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/reimprimirContrato/${row._id || row.contm_id}`, {}, this.getToken())
   
      const dados = { path: respImprimir.data }
      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `Contrato-Aluno.pdf`);
    } catch (error) {
      console.log(error)
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      return
    }
  }

  async imprimirProrrogacao(row){
    console.log(row)
    if(!row.documento){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Essa prorrogação não possui documento cadastrado'
        }
      })

      return
    }

    try {

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Baixando Contrato...'
        }
      })
     
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/prorrogacao/gerarPDFAssinado/${row._id}`, {codigo: row.hisp_codigo}, this.getToken())

      const dados = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Contrato Baixado.'
        }
      })

      saveAs(pdfBlob, `Prorrogacao-Assinada.pdf`);

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        loadingSalvar: false
      })
      return
    }

  }

  async aditivarContrato(contrato){
    try {
      // const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/buscaTempAditivo/${contrato.contm_id}`, this.getToken())
      
      const { data: cursos } = await axios.get(`${process.env.REACT_APP_API_URL}/curso`, this.getToken())
      
      let duracoes = cursos.filter(param => param.curso_id === contrato.curso.curso_id)[0].duracoes

      let index = duracoes.findIndex(param => param.durcur_tempo === contrato.contm_duracao)

      if(index === -1){
        duracoes.push({
          durcur_curso_id: contrato.curso.curso_id,
          durcur_id: -1,
          durcur_tempo: contrato.contm_duracao
        })
      }

      this.setState({
        openModalAditivoContrato: true,
        dadosAditivoCotrato: {
          contm_id: contrato.contm_id,
          html: '',
          data_inicio_vigencia: contrato.contm_data_inicio_vigencia_sem_form,
          data_fim_vigencia: contrato.contm_data_fim_vigencia_sem_form,
          curso_id: contrato.curso.curso_id,
          curso_nome: contrato.curso.curso_nome,
          duracao: contrato.contm_duracao,
          codigo: contrato.contm_codigo,
          descricao_aditivo: '',
          inicio_curso: contrato.contm_inicio_curso,
          mes_inicio_curso: contrato.contm_mes_inicio_curso,
          duracao_aula: contrato.contm_duracao_aula,
          dia_aula_1: contrato.contm_dia_aula_1,
          dia_aula_1_periodo: contrato.contm_dia_aula_1_periodo,
          dia_aula_1_horario: contrato.contm_dia_aula_1_horario,
          dia_aula_2: contrato.contm_dia_aula_2,
          dia_aula_2_periodo: contrato.contm_dia_aula_2_periodo,
          dia_aula_2_horario: contrato.contm_dia_aula_2_horario
        },
        cursoSelecionado: {
          curso_id: contrato.curso.curso_id,
          curso_nome: contrato.curso.curso_nome,
          duracoes
        },
        cursos: {
          list: cursos.filter(param => param.curso_ativo === true)
        },
        modulosSelecionadas: contrato.modulos_array.map(value => {
          return {
            num: value.modcontm_numero,
            value: value.modcontm_numero_extenso
          }
        }),
        editorState: EditorState.createEmpty()
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  handleClosemodalAditivoContrato(){
    this.setState({
      openModalAditivoContrato: false,
      dadosAditivoCotrato: {
        contm_id: '',
        html: '',
        data_inicio_vigencia: '',
        data_fim_vigencia: '',
        curso_id: '',
        curso_nome: '',
        duracao: '' ,
        codigo: '',
        descricao_aditivo: '',
        inicio_curso: '',
        mes_inicio_curso: '',
        duracao_aula: '',
        dia_aula_1: '',
        dia_aula_1_periodo: '',
        dia_aula_1_horario: '',
        dia_aula_2: '',
        dia_aula_2_periodo: '',
        dia_aula_2_horario: ''
      },
    })
  }

  updateFieldAditivoContrato(event){
    let { dadosAditivoCotrato } = this.state
    let {name, value} = event.target
    dadosAditivoCotrato[name] = value

    if(name === 'duracao'){
      dadosAditivoCotrato.data_fim_vigencia = moment(dadosAditivoCotrato.data_inicio_vigencia).add(value, 'months').format('YYYY-MM-DD')
    }

    if(name === 'data_inicio_vigencia' && dadosAditivoCotrato.duracao !== ''){
      dadosAditivoCotrato.data_fim_vigencia = moment(value).add(dadosAditivoCotrato.duracao, 'months').format('YYYY-MM-DD')
    }

    this.setState({dadosAditivoCotrato})
  }

  updateFieldCurso(event, value) {
    const { dadosAditivoCotrato } = this.state

    if(!value) {
      dadosAditivoCotrato.curso_id = ''
      dadosAditivoCotrato.curso_nome = ''

      this.setState({
        dadosAditivoCotrato,
        cursoSelecionado: {
          curso_id: '',
          curso_nome: '',
          duracoes: []
        }
      })

      return false
    }

    dadosAditivoCotrato.curso_id = value.curso_id
    dadosAditivoCotrato.curso_nome = value.curso_nome

    this.setState({
      dadosAditivoCotrato,
      cursoSelecionado: {
        curso_id: value.curso_id,
        curso_nome: value.curso_nome,
        duracoes: value.duracoes
      }
    })
  }

  validateFormContrato() {
    const { dadosAditivoCotrato } = this.state

    if (
      dadosAditivoCotrato.duracao === "" ||
      dadosAditivoCotrato.data_inicio_vigencia === "" ||
      dadosAditivoCotrato.curso_id === "" || 
      dadosAditivoCotrato.descricao_aditivo === ""
    ) {
      return false
    }

    if(dadosAditivoCotrato.curso_id === 2 || dadosAditivoCotrato.curso_id === 12){
      if(dadosAditivoCotrato.inicio_curso === '' ||
      dadosAditivoCotrato.mes_inicio_curso === '' ||
      dadosAditivoCotrato.duracao_aula === '' ||
      dadosAditivoCotrato.dia_aula_1 === '' ||
      dadosAditivoCotrato.dia_aula_1_periodo === '' ||
      dadosAditivoCotrato.dia_aula_1_horario === '' ||
      dadosAditivoCotrato.dia_aula_2 === '' ||
      dadosAditivoCotrato.dia_aula_2_periodo === '' ||
      dadosAditivoCotrato.dia_aula_2_horario === ''){
        return false
      }
    }

    return true
  }

  async confirmarAditivo(value){
    if(value){
      try {
        let {contrato, dadosAditivoCotrato} = this.state

        if(!this.validateFormContrato()){
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'error',
              message: 'Por favor informar todos os campos obrigatórios'
            }
          })
          return
        }

        this.setState({
          loadingSalvar: true
        })

        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: 'Aditivando Contrato...'
          }
        })

        await axios.post(`${process.env.REACT_APP_API_URL}/aluno/aditivarContrato/${contrato.contm_id}`,dadosAditivoCotrato, this.getToken())
        
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'success',
            message: 'Contrato aditivado com sucesso!'
          },
          loadingSalvar: false,
          contrato: {
            contm_id: "",
            contm_data_inicio_vigencia: "",
            contm_duracao: "",
            contm_observacao: "",
            curso: {
              curso_nome: ''
            },
            contm_ativo: "",
            unidade: {
              unin_numero: '',
              unin_descricao: '',
            },
            forma_pagto: {
              formpag_descricao: ''
            },
            contm_unin_id: "",
            contm_tabval_id: "",
            contm_data_vct_matricula: "",
            contm_data_vct_primeira_mensalidade: "",
            formapagto_id: "",
            stcontmat_situacao: "",
            contm_codigo: '',
            contm_metdiv_id: '',
            tabela: {
              _id: '',
              tabval_nome: '',
              tabval_num_parc: '',
              tabval_val_cheio_mensal: '',
              tabval_val_matri: '',
              tabval_val_mensal: '',
              tabval_val_mensal_total: ''
            },
            material: {
              matcont_valor: '',
              matcont_num_parcelas: '',
              matcont_dia_vencimento: '',
              matcont_formpag_id: '',
              matcont_tabprec_id: '',
              matcont_prod_id: '',
            }
          }
        })

        this.handleClosemodalAditivoContrato()
        await this.UNSAFE_componentWillMount()

        return

      }  catch ({response}) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'error',
            message: response.data.message
          },
          loadingSalvar: false
        })
        return
      }
    }else{
      this.handleClosemodalAditivoContrato()
    }
  }

  async imprimirAditivo(row){
    try {
      console.log(row)

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Baixando Arquivo...'
        }
      })

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contratoMatricula/aditivo/gerarPDFAssinado/${row._id}`,{codigo: row.aditcontm_codigo}, this.getToken())
      
      let path = JSON.parse(decrypt(data))

      const dados = {
        path: path
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      saveAs(pdfBlob, `Aditivo.pdf`);

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Arquivo Baixando...'
        }
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  onEditorStateChange(editorState){
    const {dadosAditivoCotrato } = this.state

    dadosAditivoCotrato.descricao_aditivo = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    this.setState({editorState, dadosAditivoCotrato})
  }

  async buscaPlanos(unin_id, curso_id){
    try {
      const tabelas = []

      const { data: tabelaValores } = await axios.get(`${process.env.REACT_APP_API_URL}/planoPagamentoCurso/listByUnidadeByCurso/${unin_id}/${curso_id}`, this.getToken())

      for (let i = 0; i < tabelaValores.length; i++) {
        for (let j = 0; j < tabelaValores[i].tabelas_valores.length; j++) {
          tabelas.push({
            _id: tabelaValores[i].tabelas_valores[j].tabval_id,
            planpag_descricao: tabelaValores[i].planpag_descricao,
            tabval_nome: tabelaValores[i].tabelas_valores[j].tabval_nome,
            tabval_num_parc: tabelaValores[i].tabelas_valores[j].tabval_num_parc,
            tabval_val_matri: moeda(tabelaValores[i].tabelas_valores[j].tabval_val_matri),
            tabval_val_mensal: moeda(tabelaValores[i].tabelas_valores[j].tabval_val_mensal),
            tabval_val_cheio_mensal: moeda(tabelaValores[i].tabelas_valores[j].tabval_val_cheio_mensal)
          })
        }
      }


      this.setState({
        planos: {
          list: tabelas
        },
        tabelaValoresFiltrado: {
          list: tabelaValores
        }
      })
    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  openObservacao(row){
    this.setState({
      openModalObservacaoProrrogacao: true,
      observacao_prorrogacao: row.hisp_observacao
    })
  }

  handleCloseModalObservacaoProrrogacao(){
    this.setState({
      openModalObservacaoProrrogacao: false,
      observacao_prorrogacao: ''
    })
  }

  async renviarAssinaturaAditivo(row){
    try {
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Reenviando email...'
        }
      })

      await axios.get(`${process.env.REACT_APP_API_URL}/contratoMatricula/aditivo/reenviarEmail/${row._id}`, this.getToken())

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          autoHideDuration: 3000,
          message: 'Email enviado!'
        }
      })

    }  catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  async reenviarAssinaturaProrrogacao(row){
    try {
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Reenviando email...'
        }
      })

      await axios.get(`${process.env.REACT_APP_API_URL}/prorrogacao/reenviarEmail/${row._id}`, this.getToken())

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          autoHideDuration: 3000,
          message: 'Email enviado!'
        }
      })

    }  catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  async reenviarAssinaturaContratoMatricula(row){
    try {
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Reenviando email...'
        }
      })

      await axios.get(`${process.env.REACT_APP_API_URL}/contratoMatricula/reenviarCotrato/${row._id}`, this.getToken())

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          autoHideDuration: 3000,
          message: 'Email enviado!'
        }
      })

    }  catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  async reenviarAssinatura(row, modulo){
    switch (modulo) {
      case 'aditivo_contrato':
        await this.renviarAssinaturaAditivo(row)

        break;
      
      case 'prorrogacao':
        await this.reenviarAssinaturaProrrogacao(row)

        break;

      case 'contrato_matricula':
        await this.reenviarAssinaturaContratoMatricula(row)

        break;
    
      default:
        break;
    }
    
  }

  render() {
    const {contrato, permissoesAtivarInativar, permissoesCancelar, permissoesProrrogar, habilitaProrrogar,
      permissoesAtribuirCodigo, prorrogacoes, permissoesVincularAditivo, aditivos} = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados do Contrato...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table 
                  headCell={this.state.cabecalhoTabela} 
                  rows={this.state.contratos.list} 
                  acoes={this.state.acoesTabela} 
                  viewContrato={e => this.viewContrato(e)} 
                  imprimirDoc={e => this.imprimirDoc(e)}
                  reenviarAssinatura={(e) => this.reenviarAssinatura(e, 'contrato_matricula')} 
                />
              </Grid>
            </Grid>
            {contrato.contm_id !== '' &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} sm={12} xs={12}>
                    <h4>Dados do Contrato:</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={1} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Codigo" value={contrato.contm_codigo} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Curso" value={contrato.curso.curso_nome} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Unidade" value={`${contrato.unidade.unin_numero} - ${contrato.unidade.unin_descricao}`} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Situação" value={contrato.stcontmat_situacao} disabled />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Duração do Contrato" value={`${contrato.contm_duracao} Meses`} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Data Cadastro" value={contrato.contm_data_hora_cadastro} disabled />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Data Inicio Vigência" value={contrato.contm_data_inicio_vigencia} disabled />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Data Fim Vigência" value={contrato.contm_data_fim_vigencia} disabled />
                  </Grid>
                  {contrato.stcontmat_situacao === 'Aguardando Assinatura' &&
                    <React.Fragment>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Link Assinatura" value={`${process.env.REACT_APP_URL}/assinatura_contrato/${contrato.contm_id}`} disabled />
                      </Grid>
                      <Grid item md={2} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Código Assinatura" value={contrato.contm_codigo_assinatura} disabled />
                      </Grid>
                    </React.Fragment>
                  }
                  {contrato.contm_data_cancelamento !== "" &&
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Data Cancelamento" value={contrato.contm_data_cancelamento} disabled />
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12} id='view'>
                    <TextField
                      id="outlined-multiline-static"
                      label="Observação do Contrato do Aluno"
                      className="input"
                      multiline
                      rows={8}
                      size="small"
                      variant="outlined"
                      value={contrato.contm_observacao}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Observações das Situações do Aluno"
                      className="input"
                      multiline
                      rows={6}
                      size="small"
                      variant="outlined"
                      value={contrato.stcontmat_observacao}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                </Grid>
                {(contrato.curso.curso_id === 2 || contrato.curso.curso_id === 12) &&
                   <React.Fragment>
                     <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} sm={12} xs={12}>
                        <h4>Dados dos Modulos:</h4>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={4} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Modulos" variant="outlined" size="small" name="modulos" value={contrato.modulos} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                      <Grid item md={3} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Inicio do Curso" variant="outlined" size="small" name="contm_inicio_curso" value={contrato.contm_inicio_curso} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                      <Grid item md={3} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Mês de Inicio do Curso" variant="outlined" size="small" name="contm_mes_inicio_curso" value={contrato.contm_mes_inicio_curso} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Duração da Aula (min)" variant="outlined" size="small" name="contm_duracao_aula" value={contrato.contm_duracao_aula} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={2} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Dia do Curso" variant="outlined" size="small" name="contm_dia_aula_1" value={contrato.contm_dia_aula_1} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Período" variant="outlined" size="small" name="contm_dia_aula_1_periodo" value={contrato.contm_dia_aula_1_periodo} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4} style={{borderRight: '1px solid #C0C0C0'}}>
                        <TextField fullWidth className="input" label="Horario" variant="outlined" size="small" name="contm_dia_aula_1_horario" value={contrato.contm_dia_aula_1_horario ? (contrato.contm_dia_aula_1_horario.split(':')[0]+':'+contrato.contm_dia_aula_1_horario.split(':')[1]) : ''} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Dia do Curso" variant="outlined" size="small" name="contm_dia_aula_2" value={contrato.contm_dia_aula_2} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Período" variant="outlined" size="small" name="contm_dia_aula_2_periodo" value={contrato.contm_dia_aula_2_periodo} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4}>
                        <TextField fullWidth className="input" label="Horario" variant="outlined" size="small" name="contm_dia_aula_2_horario" value={contrato.contm_dia_aula_2_horario ? (contrato.contm_dia_aula_2_horario.split(':')[0]+':'+contrato.contm_dia_aula_2_horario.split(':')[1]) : ''} InputLabelProps={{ shrink: true }} disabled />
                      </Grid>
                    </Grid>
                    <br />
                    <hr />
                    <br />
                 </React.Fragment>
                }
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} sm={12} xs={12}>
                    <h4>Dados da Tabela de Valores:</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Tabela" value={contrato.tabela.tabval_nome} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Matricula" value={contrato.tabela.tabval_val_matri} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Mensalidade Total" value={contrato.tabela.tabval_val_mensal_total} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Valor Mensalidade" value={contrato.tabela.tabval_val_mensal} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="N. de Parcelas" value={contrato.tabela.tabval_num_parc} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Forma de Pagto Mensalidade" value={contrato.forma_pagto ? contrato.forma_pagto.formpag_descricao : ''} disabled InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
                {contrato.material &&
                  <React.Fragment>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} sm={12} xs={12}>
                        <h4>Dados do Material Didático:</h4>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo do Material Didático" value={contrato.material.matcont_tipo} disabled InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Material Didático Adquirido" value={contrato.material.prod_descricao} disabled InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Valor Material Didático" value={contrato.material.matcont_valor} disabled InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="N. de Parcelas Material" value={contrato.material.matcont_num_parcelas} disabled InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Forma Pagto Material" value={contrato.material.forma_pagamento.formpag_descricao} disabled InputLabelProps={{ shrink: true }} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      {contrato.material.matcont_dia_vencimento &&
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Venc. Primeira Parcela Material" value={moment(contrato.material.matcont_dia_vencimento).format('DD/MM/YYYY')} disabled InputLabelProps={{ shrink: true }} />
                        </Grid>
                      }
                      {(!contrato.material.matcont_dia_vencimento && contrato.material.matcont_dia_vencimento_old) &&
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Dia Venc. Parcela Material" value={contrato.material.matcont_dia_vencimento_old} disabled InputLabelProps={{ shrink: true }} />
                        </Grid>
                      }
                    </Grid>
                  </React.Fragment>
                }
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} sm={12} xs={12}>
                    <h4>Prorrogações do Contrato:</h4>
                  </Grid>
                </Grid>
                {prorrogacoes.list.length === 0 &&
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} sm={12} xs={12}>
                      <h5>Nenhuma prorrogação lançada:</h5>
                    </Grid>
                  </Grid>
                }
                {prorrogacoes.list.length > 0 && 
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} sm={12} xs={12}>
                      <Table 
                        headCell={this.state.cabecalhoTabelaProrrogacoes} 
                        rows={prorrogacoes.list} 
                        acoes={this.state.acoesTabelaProrrogacoes}
                        openObservacao={(e) => this.openObservacao(e)}
                        estornarProrrogacao={e => this.estornarProrrogacao(e)} 
                        imprimir={(e) => this.imprimirProrrogacao(e)}
                        reenviarAssinatura= {(e) => this.reenviarAssinatura(e, 'prorrogacao')}
                      />
                    </Grid>
                  </Grid>
                }
                <hr className="mg_top_10"/>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} sm={12} xs={12}>
                    <h4>Aditivos do Contrato:</h4>
                  </Grid>
                </Grid>
                {aditivos.list.length === 0 &&
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} sm={12} xs={12}>
                      <h5>Nenhuma aditivo lançada:</h5>
                    </Grid>
                  </Grid>
                }
                 {aditivos.list.length > 0 && 
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} sm={12} xs={12}>
                      <Table 
                        headCell={this.state.cabecalhoTabelaAditivo} 
                        rows={aditivos.list} 
                        acoes={this.state.acoesTabelaAditivo}
                        imprimirDoc={(e) => this.imprimirAditivo(e)}
                        reenviarAssinatura= {(e) => this.reenviarAssinatura(e, 'aditivo_contrato')}
                      />
                    </Grid>
                  </Grid>
                }
                <hr className="mg_top_10"/>
                
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  {permissoesAtivarInativar.perm_inserir &&
                    <React.Fragment>
                      {contrato.stcontmat_situacao === 'Aprovado' &&
                        <Grid item md={2} xs={6} sm={4}>
                          <ColorButton className="btnCadastrar" variant="contained" onClick={() => this.inativarContrato(contrato)}>
                          Inativar Contrato
                          </ColorButton>
                        </Grid>
                      }
                      {contrato.stcontmat_situacao === 'Inativo' &&
                        <Grid item md={2} xs={6} sm={4}>
                          <Button className="btnCadastrar" variant="contained" color="primary" onClick={() => this.ativarContrato(contrato)}>
                            Ativar Contrato
                          </Button>
                        </Grid>
                      }
                    </React.Fragment>
                  }
                  {permissoesProrrogar.perm_inserir && 
                    <React.Fragment>
                      {contrato.stcontmat_situacao === 'Aprovado' &&
                        <React.Fragment>
                          {habilitaProrrogar &&
                            <Grid item md={3} xs={6} sm={4}>
                              <ColorGreenButton className="btnCadastrar" variant="contained" onClick={() => this.prorrogarContrato(contrato)}>
                                Prorrogar Contrato
                              </ColorGreenButton>
                            </Grid>
                          }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                  {permissoesCancelar.perm_inserir && 
                    <React.Fragment>
                      {contrato.stcontmat_situacao === 'Aprovado' &&
                        <Grid item md={2} xs={6} sm={4}>
                          <Button className="btnCadastrar" variant="contained" color="secondary" onClick={() => this.cancelarContrato(contrato)}>
                            Cancelar Contrato
                          </Button>
                        </Grid>
                      }
                    </React.Fragment>
                  }
                  {permissoesVincularAditivo.perm_inserir &&
                    <React.Fragment>
                      {contrato.stcontmat_situacao === 'Aprovado' &&
                        <Grid item md={3} xs={6} sm={4}>
                          <ColorYellowButton className="btnCadastrar" variant="contained" onClick={() => this.vincularAditivo(contrato)}>
                            Aditivo Start
                          </ColorYellowButton>
                        </Grid>
                      }
                    </React.Fragment>
                  }
                  <Grid item md={2} xs={6} sm={4}>
                    <ColorIndigoButton className="btnCadastrar" variant="contained" onClick={() => this.imprimirDoc(contrato)}>
                      Imprimir Contrato
                    </ColorIndigoButton>
                  </Grid>
                  {contrato.stcontmat_situacao === 'Aprovado' &&
                    <Grid item md={3} xs={6} sm={4}>
                      <Button color='primary' className="btnCadastrar" variant="contained" onClick={() => this.aditivarContrato(contrato)}>
                        Aditivo de Contrato
                      </Button>
                    </Grid>
                  }
                 </Grid>
              </React.Fragment>
            }
           
            <br />
            <hr />
            <br />

            <Grid container spacing={1} direction="row">
              <Grid item md={10} xs={12} sm={8}>
                <h5>Material didático do aluno: </h5>
              </Grid>
              {permissoesAtribuirCodigo.perm_inserir &&
                <Grid item md={2} xs={12} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => this.vincularCodigo(e)}>Vincular Código</Button>
                </Grid>
              }
            </Grid>
            <Grid container spacing={1} direction="row">
              <Grid item md={12} xs={12}>
                <Table 
                  headCell={this.state.cabecalhoTabelaCodigo} 
                  rows={this.state.codigos.list} 
                  acoes={this.state.acoesTabelaCodigo} 
                  transferirCodigo={e => this.transferirCodigo(e)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={9} xs={false}></Grid>
              <Grid item md={3} xs={6} sm={5}>
                <Link to={`/aluno`}>
                  <Button className="btnCadastrar" variant="contained" color="secondary">
                    Voltar
                </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        }
        <ModalErro 
          open={this.state.modalErro} 
          titulo={this.state.erro.titulo} 
          descricao={this.state.erro.descricao} 
          handleClose={e => this.handleCloseErro(e)} 
        />
        <ModalInativarContrato
          open={this.state.modalInativarContrato}
          handleClose={e => this.handleCloseModalInativarContrato(e)}
          confirmar={e => this.confirmarInativar(e)}
          dados={this.state.inativarContrato}
          updateField={e => this.updateFieldInativar(e)}
        />
        <ModalAtivarContrato
          open={this.state.modalAtivarContrato}
          handleClose={e => this.handleCloseModalAtivarContrato(e)}
          confirmar={e => this.confirmarAtivar(e)}
          dados={this.state.inativarContrato}
          updateField={e => this.updateFieldInativar(e)}
        />

         <ModalCancelarContrato
          open={this.state.modalCancelarContrato}
          handleClose={e => this.handleClosemodalCancelarContrato(e)}
          confirmar={e => this.confirmarCancelamento(e)}
          dados={this.state.cancelarContrato}
          updateField={e => this.updateFieldCancelar(e)}
          financeiros_pendentes={this.state.financeiros_pendentes.list}
          valores_quitacao={this.state.valores_quitacao.list}
          total_sugerido={this.state.total_sugerido}
          conta={this.state.conta}
          updateFieldConta={e => this.updateFieldConta(e)}
          mudarParcelas={e => this.mudarParcelas(e)}
          recalcularParcela={(e, value) => this.recalcularParcela(e, value)}
          updateFieldParcela={(e, index) => this.updateFieldParcela(e, index)}
          updateFieldLancFinanceiroCancelamento={e => this.updateFieldLancFinanceiroCancelamento(e)}
          formasPagamento={this.state.formasPagamento.list}
          disabledButtonCancelar={this.state.disabledButtonCancelar}
          contasFinanceira={this.state.contasFinanceira.list}
          loadingSalvar={this.state.loadingSalvar}
        />

        <ModalProrrogarContrato 
          open={this.state.modalProrrogarContrato}
          handleClose={e => this.handleCloseModalProrrogarContrato(e)}
          confirmar={e => this.confirmarProrrogacao(e)}
          dados={this.state.prorrogarContrato}
          updateField={e => this.updateFieldProrrogar(e)}
          updateFieldLancFinanceiro={e => this.updateFieldLancFinanceiro(e)}
          updateFieldLancFinanceiroTaxa={e => this.updateFieldLancFinanceiroTaxa(e)}
          conta={this.state.conta}
          updateFieldConta={e => this.updateFieldConta(e)}
          mudarParcelas={e => this.mudarParcelas(e)}
          recalcularParcela={(e, value) => this.recalcularParcela(e, value)}
          updateFieldParcela={(e, index) => this.updateFieldParcela(e, index)}
          formasPagamento={this.state.formasPagamento.list}
          disabledButtonProrrogar={this.state.disabledButtonProrrogar}
          travarParcelas={true}
          funcionarios={this.state.funcionarios}
          contasFinanceira={this.state.contasFinanceira.list}
          loadingSalvar={this.state.loadingSalvar}
          tabelaValores={this.state.tabelaValoresFiltrado.list}
          verifyFinishedDate={e => this.verifyFinishedDate(e)}
        />

        <ModalVincularCodigo 
          open={this.state.modalVincularCodigo}
          handleClose={e => this.handleClosemodalVincularCodigo(e)}
          confirmar={e => this.confirmarVincularCodigo(e)}
          materiais={this.state.materiais.list}
          material={this.state.material}
          updateFieldMaterial={(e, value) => this.updateFieldMaterialCodigo(e, value)}
          codigo={this.state.codigo}
          possuiFinanceiroCodigo={this.state.possuiFinanceiroCodigo}
          conta={this.state.conta}
          updateFieldConta={e => this.updateFieldConta(e)}
          mudarParcelas={e => this.mudarParcelas(e)}
          recalcularParcela={(e, value) => this.recalcularParcela(e, value)}
          updateFieldParcela={(e, index) => this.updateFieldParcela(e, index)}
          formasPagamento={this.state.formasPagamento.list}
        />

        <ModalEstornarProrrogacao 
          open={this.state.modalEstornarProrrogacao}
          handleClose={e => this.handleCloseModalEstornarProrrogacao(e)}
          estornoProrrogacao={this.state.estornoProrrogacao}
          prorrogacaoEstornar={this.state.prorrogacaoEstornar}
          confirmar={e => this.confirmarEstornoProrrogacao(e)}
          updateData={e => this.updateEstornoProrrogacao(e)}
        />

        <ModalTransferirCodigo 
          open={this.state.modalTransferirCodigo}
          handleClose={e => this.handleCloseModalTransferirCodigo(e)}
          updateField={e => this.updateFieldSearch(e)}
          buscaAluno={e => this.buscaAluno(e)}
          confirmar={e => this.confirmarTransferenciaCodigo(e)}
          search={this.state.search}
          buscaPor={this.state.buscaPor}
          alunoTransferenciaCodigo={this.state.alunoTransferenciaCodigo}
          loadingSalvarTransf = {this.state.loadingSalvarTransf}
        />

        <ModalVincularAditivo 
          open={this.state.modalVincularAditivo}
          handleClose={e => this.handleClosemodalVincularAditivo(e)}
          confirmar={e => this.confirmarVincularAditivo(e)}
          config_aditivos={this.state.config_aditivos.list}
          dados={this.state.aditivoSelecionado}
          updateFieldAditivo={e => this.updateFieldAditivo(e)}
          curso_aditivo={this.state.curso_aditivo}
          updateFieldCursoAditivo={e => this.updateFieldCursoAditivo(e)}
          conta={this.state.conta}
          updateFieldConta={e => this.updateFieldConta(e)}
          mudarParcelas={e => this.mudarParcelas(e)}
          recalcularParcela={(e, value) => this.recalcularParcela(e, value)}
          updateFieldParcela={(e, index) => this.updateFieldParcela(e, index)}
          verificaDataParcela={(e, index) => this.verificaDataParcela(e, index)}
          formasPagamento={this.state.formasPagamento.list}
          travarParcelas={true}
          updateFieldMaterial={e => this.updateFieldMaterial(e)}
          materiais={this.state.materiais.list}
          contasFinanceira={this.state.contasFinanceira.list}
          loadingSalvar={this.state.loadingSalvar}
        />

        <ModalAditivoContrato 
          open={this.state.openModalAditivoContrato}
          handleClose={e => this.handleClosemodalAditivoContrato(e)}
          dados={this.state.dadosAditivoCotrato}
          updateField={(e) => this.updateFieldAditivoContrato(e)}
          cursos={this.state.cursos.list}
          curso={this.state.cursoSelecionado}
          updateFieldCurso={(event, value) => this.updateFieldCurso(event, value)}
          modulos={this.state.modulos}
          modulosSelecionadas={this.state.modulosSelecionadas}
          aluno={this.props.aluno}
          confirmar={(e) => this.confirmarAditivo(e)}
          loadingSalvar={this.state.loadingSalvar}
          onEditorStateChange={e => this.onEditorStateChange(e)}
          editorState={this.state.editorState}
        />

        <ModalObservacaoProrrogacao 
          open={this.state.openModalObservacaoProrrogacao}
          handleClose={e => this.handleCloseModalObservacaoProrrogacao(e)}
          dados={this.state.observacao_prorrogacao}
        />

        <Snackbar open={this.state.snackbar.open} autoHideDuration={6000} onClose={e => this.handleCloseSnackbar()}>
          <Alert onClose={e => this.handleCloseSnackbar()} severity={this.state.snackbar.severity}>
            {this.state.snackbar.mensagem}
          </Alert>
        </Snackbar>

        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment>
    )
  }
}