import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField, FormControlLabel } from '@material-ui/core'
import CurrencyInput from './../mask/CurrencyInput'
import real from '../../services/real'
import moment from 'moment'

const dataHoje = moment(new Date()).format('YYYY-MM-DD')

const FormContratoAluno = ({ curso, cursos, formasPagamento, unidade, unidades, dados,
  updateField, updateFieldCurso, updateFieldUnidade, tabelaValores, configuracao, modulosSelecionadas,
  maiorIdade, aprovarCadastro, updateFieldSenha, senha, changeAprovar, metodosDivugacoes, modulos,
  updateFieldMaterial, materiais, tabelaPrecoMaterial, unin_tipo, updateFieldMaiorMenor,updateFieldModulo,
  calculaIdade, pessoaFisica, permissoesAprovar, verifyFinishedDate, travaUnidade, contasFinanceira, 
  permissoesReprovar }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldCurso(event, value)}
          freeSolo
          options={cursos}
          value={curso}
          getOptionLabel={option => option.curso_nome}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Curso*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={curso.curso_nome} />
          )}
        />
      </Grid>
      {unin_tipo !== "UNIDADE" &&
        <React.Fragment>
          {!travaUnidade &&
            <Grid item md={3} xs={12} sm={6}>
              <Autocomplete
                onChange={(event, value) => updateFieldUnidade(event, value)}
                freeSolo
                options={unidades}
                value={unidade}
                getOptionLabel={option => `${option.unin_numero} - ${option.unin_descricao}`}
                renderInput={(params) => (
                  <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Unidade*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={unidade.unin_numero + ' - ' + unidade.unin_descricao}/>
                )}
              />
            </Grid>
          }

          {travaUnidade && 
            <Grid item md={3} xs={12} sm={6}>
              <TextField className="input" label="Unidade*" variant="outlined" name="unidade" value={`${unidade.unin_numero} - ${unidade.unin_descricao}`} InputLabelProps={{ shrink: true }} disabled/>
            </Grid>
          }
        </React.Fragment>
      }
      {/* {unin_tipo !== "UNIDADE" && 
        <Grid item md={3} xs={12} sm={6}>
          <TextField className="input" label="Unidade*" variant="outlined" name="unidade" value={`${unidade.unin_numero} - ${unidade.unin_descricao}`} InputLabelProps={{ shrink: true }} disabled/>
        </Grid>
      } */}
      <Grid item md={3} xs={12} sm={6}>
        <TextField type="date"
          className="input"
          label="Data Inicio Vigencia*"
          variant="outlined"
          name="contm_data_inicio_vigencia"
          value={dados.contm_data_inicio_vigencia}
          onChange={(e) => updateField(e)}
          onBlur={(e) => verifyFinishedDate(e)}
          InputProps={{ inputProps: { min: dataHoje } }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Duração do Contrato*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="contm_duracao"
          value={dados.contm_duracao}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.contm_curso_id === '' ? true: false}
        >
          <option value=""> Selecionar Duração do Contrato </option>
          {curso.duracoes.map(duracao => {
            return (
              <option value={duracao.durcur_tempo}> {duracao.durcur_tempo} Meses </option>
            )
          })}
        </TextField>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input"
          fullWidth
          label="Método de Divulgação"
          size="small"
          SelectProps={{
            native: true,
          }}
          name="contm_metdiv_id"
          value={dados.contm_metdiv_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {metodosDivugacoes.map(divugacao => {
            return (
              <option key={divugacao.metdiv_id} value={divugacao.metdiv_id}>{divugacao.metdiv_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input"
          fullWidth
          label="Plano Pagamento*"
          size="small"
          SelectProps={{
            native: true,
          }}
          name="contm_tabval_id"
          value={dados.contm_tabval_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.contm_unin_id === '' || dados.contm_curso_id === ''}
        >
          <option value={dados.contm_tabval_id !== '' ? dados.contm_tabval_id : ''}>{dados.contm_tabval_id !== '' ? dados.tabval_nome : '--Escolha--'}</option>
          {tabelaValores.filter(param => param.planpag_ativo === true).map(plano => {
            if (plano.tabelas_valores.filter(param => param.tabval_ativo === true).length > 0) {
              return (
                <optgroup key={plano.planpag_id} label={plano.planpag_descricao}>
                  {plano.tabelas_valores.filter(param => param.tabval_ativo === true).map(tabela => {
                    return (
                      <option key={tabela.tabval_id} value={tabela.tabval_id}>{tabela.tabval_nome}</option>
                    )
                  })}
                </optgroup>
              )
            } else {
              return false
            }

          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField fullWidth className="input" label="Matrícula" variant="outlined" size="small" name="tabval_val_matri" value={dados.tabela.tabval_val_matri} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField fullWidth className="input" label="Mensalidade Total" variant="outlined" size="small" name="tabval_val_mensal_total" value={dados.tabela.tabval_val_mensal_total} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField fullWidth className="input" label="N. de Parcelas" variant="outlined" size="small" name="tabval_num_parc" value={dados.tabela.tabval_num_parc} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={4}>
        <TextField fullWidth className="input" label="Parcela com desc." variant="outlined" size="small" name="tabval_val_mensal" value={dados.tabela.tabval_val_mensal} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField fullWidth className="input" label="Parcela sem desc." variant="outlined" size="small" name="tabval_val_cheio_mensal" value={dados.tabela.tabval_val_cheio_mensal} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          size="small"
          type="date"
          className="input"
          label="Data Vcto 1ª Mensalidade*"
          variant="outlined"
          name="contm_data_vct_primeira_mensalidade"
          value={dados.contm_data_vct_primeira_mensalidade}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          onBlur={(e) => verifyFinishedDate(e)}
          InputProps={{ inputProps: { min: dataHoje } }}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          size="small"
          type="date"
          className="input"
          label="Data Vcto Matricula*"
          variant="outlined"
          name="contm_data_vct_matricula"
          value={dados.contm_data_vct_matricula}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          onBlur={(e) => verifyFinishedDate(e)}
          InputProps={{ inputProps: { min: dataHoje } }}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input"
          fullWidth
          label="Forma de Pagamento Mensalidades*"
          size="small"
          SelectProps={{
            native: true,
          }}
          name="formapagto_id"
          value={dados.formapagto_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {formasPagamento.map(forma => {
            return (
              <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input"
          fullWidth
          label="Conta Financeira"
          size="small"
          SelectProps={{
            native: true,
          }}
          name="contfin_id"
          value={dados.contfin_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {contasFinanceira.filter(param => param.contfin_tipo_conta === (dados.formapagto_id === '1' ? 'COFRE': 'BANCO')).map(conta => {
            return (
              <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
    </Grid>
    <br />
    <hr />
    <br />
    {(curso.curso_id === 2 || curso.curso_id === 12) &&
      <React.Fragment>
        <Grid container direction="row" spacing={1}>
          <Grid item md={4} xs={12} sm={4}>
            <Autocomplete
              multiple
              limitTags={8}
              id="multiple-limit-tags"
              options={modulos}
              size="small"
              value={modulosSelecionadas}
              onChange={(event, value) => updateFieldModulo(event, value)}
              getOptionLabel={(option) => `${option.num} (${option.value})`}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Modulos" InputLabelProps={{ shrink: true }} />
              )}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="input"
              fullWidth
              label="Inicio do Curso"
              size="small"
              SelectProps={{
                native: true,
              }}
              name="contm_inicio_curso"
              value={dados.contm_inicio_curso}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">--Escolha--</option>
              <option value="1º Semestre">1º Semestre</option>
              <option value="2º Semestre">2º Semestre"</option>
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="input"
              fullWidth
              label="Mês de Inicio do Curso"
              size="small"
              SelectProps={{
                native: true,
              }}
              name="contm_mes_inicio_curso"
              value={dados.contm_mes_inicio_curso}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">--Escolha--</option>
              <option value="Janeiro">Janeiro</option>
              <option value="Fevereiro">Fevereiro</option>
              <option value="Março">Março</option>
              <option value="Abril">Abril</option>
              <option value="Maio">Maio</option>
              <option value="Junho">Junho</option>
              <option value="Julho">Julho</option>
              <option value="Agosto">Agosto</option>
              <option value="Setembro">Setembro</option>
              <option value="Outubro">Outubro</option>
              <option value="Novembro">Novembro</option>
              <option value="Dezembro">Dezembro</option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={4}>
            <TextField type='number' fullWidth className="input" label="Duração da Aula (min)" variant="outlined" size="small" name="contm_duracao_aula" value={dados.contm_duracao_aula} InputLabelProps={{ shrink: true }} onChange={(e) => updateField(e)} />  
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={2} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="input"
              fullWidth
              label="Dia do Curso"
              size="small"
              SelectProps={{
                native: true,
              }}
              name="contm_dia_aula_1"
              value={dados.contm_dia_aula_1}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">--Escolha--</option>
              <option value="Segunda">Segunda</option>
              <option value="Terça">Terça</option>
              <option value="Quarta">Quarta</option>
              <option value="Quinta">Quinta</option>
              <option value="Sexta">Sexta</option>
              <option value="Sabado">Sabado</option>
              <option value="Domingo">Domingo</option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="input"
              fullWidth
              label="Período"
              size="small"
              SelectProps={{
                native: true,
              }}
              name="contm_dia_aula_1_periodo"
              value={dados.contm_dia_aula_1_periodo}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">--Escolha--</option>
              <option value="Manhã">Manhã</option>
              <option value="Tarde">Tarde</option>
              <option value="Noite">Noite</option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={4} style={{borderRight: '1px solid #C0C0C0'}}>
            <TextField type='time' fullWidth className="input" label="Horario" variant="outlined" size="small" name="contm_dia_aula_1_horario" value={dados.contm_dia_aula_1_horario} InputLabelProps={{ shrink: true }} onChange={(e) => updateField(e)} />  
          </Grid>
          <Grid item md={2} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="input"
              fullWidth
              label="Dia do Curso"
              size="small"
              SelectProps={{
                native: true,
              }}
              name="contm_dia_aula_2"
              value={dados.contm_dia_aula_2}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">--Escolha--</option>
              <option value="Segunda">Segunda</option>
              <option value="Terça">Terça</option>
              <option value="Quarta">Quarta</option>
              <option value="Quinta">Quinta</option>
              <option value="Sexta">Sexta</option>
              <option value="Sabado">Sabado</option>
              <option value="Domingo">Domingo</option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              variant="outlined"
              className="input"
              fullWidth
              label="Período"
              size="small"
              SelectProps={{
                native: true,
              }}
              name="contm_dia_aula_2_periodo"
              value={dados.contm_dia_aula_2_periodo}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">--Escolha--</option>
              <option value="Manhã">Manhã</option>
              <option value="Tarde">Tarde</option>
              <option value="Noite">Noite</option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={4}>
            <TextField type='time' fullWidth className="input" label="Horario" variant="outlined" size="small" name="contm_dia_aula_2_horario" value={dados.contm_dia_aula_2_horario} InputLabelProps={{ shrink: true }} onChange={(e) => updateField(e)} />  
          </Grid>
        </Grid>
        <br />
        <hr />
        <br />
      </React.Fragment>
    }
    <Grid container direction="row" spacing={1}>
    <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input"
          fullWidth
          label="Tipo de Material*"
          size="small"
          SelectProps={{
            native: true,
          }}
          name="matcont_tipo"
          value={dados.material.matcont_tipo}
          onChangeCapture={(e) => updateFieldMaterial(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.contm_curso_id === '' ? true: false}
        >
          <option value="">--Escolha--</option>
          <option value="Físico">Físico</option>
          <option value="Digital">Digital</option>
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input"
          fullWidth
          label="Material Didático Adquirido*"
          size="small"
          SelectProps={{
            native: true,
          }}
          name="matcont_prod_id"
          value={dados.material.matcont_prod_id}
          onChangeCapture={(e) => updateFieldMaterial(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.contm_curso_id === '' ? true: false}
        >
          <option value={dados.material.matcont_prod_id !== '' ? dados.material.matcont_prod_id : ''}>{dados.material.matcont_prod_id !== '' ? dados.material.matcont_prod_descricao : '--Escolha--'}</option>
          {materiais.map((material, key) => {
            return (
              <option key={key} value={material._id}>{material.descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Valor do Material Didático*" variant="outlined"
          size="small" name="matcont_valor" value={dados.material.matcont_valor}
          onChange={(e) => updateFieldMaterial(e)} InputLabelProps={{ shrink: true }}
          InputProps={{ inputComponent: CurrencyInput }} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField fullWidth className="input" label="N. de Parcelas Material" variant="outlined"
          size="small" type="number" name="matcont_num_parcelas" value={dados.material.matcont_num_parcelas}
          InputLabelProps={{ shrink: true }} onChange={(e) => updateFieldMaterial(e)} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField 
          fullWidth
          type="date" 
          className="input" 
          label="Prim. Venc. do Material" 
          variant="outlined"
          size="small" 
          name="matcont_dia_vencimento" 
          value={dados.material.matcont_dia_vencimento}
          InputLabelProps={{ shrink: true }} 
          onChange={(e) => updateFieldMaterial(e)}
          InputProps={{ inputProps: { min: dataHoje } }}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input"
          fullWidth
          label="Forma de pagamento de Material*"
          size="small"
          SelectProps={{
            native: true,
          }}
          name="matcont_formpag_id"
          value={dados.material.matcont_formpag_id}
          onChangeCapture={(e) => updateFieldMaterial(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {formasPagamento.map(forma => {
            return (
              <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          className="input"
          fullWidth
          label="Conta Financeira"
          size="small"
          SelectProps={{
            native: true,
          }}
          name="contfin_id"
          value={dados.material.contfin_id}
          onChangeCapture={(e) => updateFieldMaterial(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.material.matcont_formpag_id === ''}
        >
          <option value="">--Escolha--</option>
          {contasFinanceira.filter(param => param.contfin_tipo_conta === (dados.material.matcont_formpag_id === '1' ? 'COFRE': 'BANCO')).map(conta => {
            return (
              <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      

      {tabelaPrecoMaterial && dados.material.matcont_prod_id !== '' &&
        <Grid item md={12} xs={12} sm={12}>
          <h6><b>Tabela de preço do Material</b></h6>
          <p style={{ margin: 0 }}>Nome: {tabelaPrecoMaterial.tabprec_descricao}</p>
          <p style={{ margin: 0 }}>Valor Mínimo: R$ {real(tabelaPrecoMaterial.preco_produto.precprod_valor_minimo)} </p>
          <p style={{ margin: 0 }}>Valor Máximo: R$ {real(tabelaPrecoMaterial.preco_produto.precprod_valor_maximo)} </p>
        </Grid>
      }

      {!tabelaPrecoMaterial && dados.material.matcont_prod_id &&
        <Grid item md={12} xs={12} sm={12}>
          <h6 style={{ color: '#ff5a5a' }}>Produto sem Tabela de Preço</h6>
        </Grid>
      }

    </Grid>
    <br />
    <hr />
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <TextField
          id="outlined-multiline-static"
          label="Observação"
          className="input"
          multiline
          rows={5}
          size="small"
          variant="outlined"
          name="contm_observacao"
          value={dados.contm_observacao}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
    {calculaIdade(pessoaFisica.dataNascimento) >= 18 &&
      <div className='cardEndereco'>
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={12}>
            <h4>Responsável financeiro</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" checked={!maiorIdade} />}
              onClick={e => updateFieldMaiorMenor(e)}
              label="Deseja informar o responsável financeiro? "
              labelPlacement="end"
            />
            {/* <Grid item md={3}>
              <Button fullWidth onClick={() => this.updateFieldMaiorMenor()} color="primary" variant="contained" size="small">Informar responsável financeiro</Button>
            </Grid> */}
          </Grid>
        </Grid>
      </div>
    }
    {permissoesAprovar.perm_inserir &&
      <React.Fragment>
        {maiorIdade &&
          <div className='cardEndereco'>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <h4>Aprovar e efetivar o cadastro</h4>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <div className='alertaNaoDesfeita'>
                  <p>Esta operação não poderá ser desfeita. Por segurança é necessário que confirme sua senha abaixo para validar o processo.</p>
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={6} xs={12} sm={6}>
                <FormControlLabel
                  value="end"
                  disabled={dados.stcontmat_situacao === 'AGUARDANDO_ASSINATURA'}
                  control={<Checkbox color="primary" checked={aprovarCadastro} />}
                  onClick={e => changeAprovar(e)}
                  label={permissoesReprovar.perm_inserir ? `Quero aprovar/reprovar este pré cadastro e efetivá-lo no sistema ${configuracao.confunin_hab_assinatura_doc  ? ', e enviar o email de assinatura (Em caso de aprovação).' : '.'}${dados.stcontmat_situacao === 'AGUARDANDO_ASSINATURA' ? 'O contrato antigo será cancelado.': ''}` : `Quero aprovar este pré cadastro e efetivá-lo no sistema ${configuracao.confunin_hab_assinatura_doc  ? ', e enviar o email de assinatura.' : '.'}${dados.stcontmat_situacao === 'AGUARDANDO_ASSINATURA' ? 'O contrato antigo será cancelado.': ''}`}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item md={6} xs={12} sm={6}>
                <TextField type='password' className="input" label="Senha" variant="outlined" size="small" name="senha" value={senha} onChange={(e) => updateFieldSenha(e)} InputLabelProps={{ shrink: true }} placeholder='Digite sua Senha' />
              </Grid>
            </Grid>
          </div>
        }
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormContratoAluno