import getEndereco from './../../services/api/getEndereco'
import calculaIdade from './../../services/calculaIdade'
import React, { Component } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core'

import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'
import FormContratoAluno from '../../components/forms/FormContratoAluno'
import FormComissao from '../../components/forms/FormComissao'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import { saveAs } from 'file-saver';
import moment from 'moment';
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalConfirmacaoTrocaResp from './../../components/modals/Confirmacao'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from './../../components/templates/Alert'
import real from '../../services/real'
import moeda from '../../services/moeda'
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';

const ColorDarkGreenButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#006400').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#006400',
    },
  },
}))(Button);

const cpf = require('node-cpf-cnpj');

let baseState = {
  aluno: {
    _id: "",
    pesf_id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      sobrenome: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "55",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contrato: {
      contm_id: "",
      contm_data_inicio_vigencia: "",
      contm_duracao: "",
      contm_observacao: "",
      contm_curso_id: "",
      contm_ativo: true,
      contm_unin_id: "",
      contm_tabval_id: "",
      contm_data_vct_matricula: "",
      contm_data_vct_primeira_mensalidade: "",
      formapagto_id: "",
      stcontmat_situacao: "AGUARDANDO_APROVACAO",
      contm_metdiv_id: '',
      contfin_id: '',
      habilita: '',
      contm_num_modulos: '',
      contm_inicio_curso: '',
      contm_mes_inicio_curso: '',
      contm_dia_aula_1: '',
      contm_dia_aula_2: '',
      contm_dia_aula_1_periodo: '',
      contm_dia_aula_2_periodo: '',
      contm_dia_aula_1_horario: '',
      contm_dia_aula_2_horario: '',
      contm_duracao_aula: '',
      tabela: {
        _id: '',
        tabval_nome: '',
        tabval_num_parc: '',
        tabval_val_cheio_mensal: '',
        tabval_val_mensal: '',
        tabval_val_matri: '',
        tabval_val_mensal_total: '',
      },
      material: {
        matcont_valor: '',
        matcont_tipo: '',
        matcont_num_parcelas: '',
        matcont_dia_vencimento: '',
        matcont_formpag_id: '',
        matcont_tabprec_id: '',
        matcont_prod_id: '',
        contfin_id: '',
      }
    },
    unidade: {
      unin_id: '',
      unin_descricao: '',
      unin_numero: ''
    },
    aprovarCadastro: false
  },
  responsavel: {
    pesf_id: "",
    pessoaFisica: {
      nome: "",
      sobrenome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: "",
      maiorIdade: true
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "55",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
  },
  senha: "",
  tabelaPrecoMaterial: null,
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  configuracao: {
    confunin_certificado_id: '',
    confunin_hab_assinatura_doc: false
  },
}

let initalState = {
  ...baseState,
  materiais: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  cursos: {
    list: []
  },
  curso: {
    curso_id: "",
    curso_nome: "",
    duracoes: []
  },
  unidade: {
    unin_id: "",
    unin_descricao: "",
    unin_numero: ""
  },
  tabelaValores: {
    list: []
  },
  tabelaValoresFiltrado: {
    list: []
  },
  planos: {
    list: []
  },
  loadingSalvar: false,
  loading: true,
  unidades: {
    list: []
  },
  modulosSelecionadas: [],
  modulos: [
    {num: 1, value: 'Um'},
    {num: 2, value: 'Dois'},
    {num: 3, value: 'Três'},
    {num: 4, value: 'Quatro'},
    {num: 5, value: 'Cinco'},
    {num: 6, value: 'Seis'},
    {num: 7, value: 'Sete'},
    {num: 8, value: 'Oito'}
  ],
  enableDadosPessoa: true,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosContrato: false,
  enableDadosPessoaResponsavel: false,
  enableDadosEnderecoResponsavel: false,
  enableDadosEmailResponsavel: false,
  enableDadosContatoResponsavel: false,
  enableBuscaResp: true,
  enableDadosComercial: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  unin_tipo: 'FRANQUEADOR',
  searchResp: {
    cpf: ''
  },
  funcoes: {
    list: []
  },
  comissoes: [],
  modal: false,
  dialog: {
    ativo: false,
    titulo: "",
    descricao: ""
  },
  logado: {
    unidadetrabalho: {
      unin_tipo: ''
    }
  },
  loadList: true,
  funcionarios: {
    list: []
  },
  permissoesAprovar: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesEditarDadosPessoais: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesEditarDadosAcademicos: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesEditarDadosResponsavel:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesReprovar:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  edit: false,
  duracoesCursoSelecionado: [],
  habilitaDocumento: false,
  msg_loading: '',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  modalConfirmacaoTrocaRespOpen: false,
}

function formatarCEP(str) {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  return str.replace(re, "$1.$2-$3");
}

function formatarTelefone(str) {
  if (str.length === 11) {
    return str.replace(/(\d{2})?(\d{4})?(\d{5})/, "($1) $2-$3")
  } else if (str.length === 10) {
    return str.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3")
  }
}

function converteFloatMoeda(valor) {
  var inteiro = null, decimal = null, c = null, j = null;
  var aux = [];
  valor = "" + valor;
  c = valor.indexOf(".", 0);
  //encontrou o ponto na string
  if (c > 0) {
    //separa as partes em inteiro e decimal
    inteiro = valor.substring(0, c);
    decimal = valor.substring(c + 1, valor.length);
  } else {
    inteiro = valor;
  }

  //pega a parte inteiro de 3 em 3 partes
  for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
    aux[c] = inteiro.substring(j - 3, j);
  }

  //percorre a string acrescentando os pontos
  inteiro = "";
  for (c = aux.length - 1; c >= 0; c--) {
    inteiro += aux[c] + '.';
  }
  //retirando o ultimo ponto e finalizando a parte inteiro

  inteiro = inteiro.substring(0, inteiro.length - 1);

  decimal = parseInt(decimal);
  if (isNaN(decimal)) {
    decimal = "00";
  } else {
    decimal = "" + decimal;
    if (decimal.length === 1) {
      decimal = decimal + "0";
    }
  }


  valor = inteiro + "," + decimal;


  return valor;

}

export default class Cadastroaluno extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoesAprovar = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cadastro-aluno-aprovacao')[0]
    let permissoesEditarDadosPessoais = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'alunos-alterar-dados-pessoais')[0]
    let permissoesEditarDadosAcademicos = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'alunos-alterar-dados-academicos')[0]
    let permissoesEditarDadosResponsavel = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'alunos-alterar-dados-responsavel')[0]
    let permissoesReprovar = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'reprovar-pre-cadastro')[0]

    if (permissoesAprovar) {
      this.setState({ permissoesAprovar })
    }

    if(permissoesReprovar){
      this.setState({permissoesReprovar})
    }

    let aluno = { ...this.state.aluno }
    let responsavel = { ...this.state.responsavel }

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo : 'UNIDADE'

    this.setState({ aluno, unin_tipo })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/comissaoFuncao/funcoes`, dados, this.getToken())

      let funcionarios = []

      for (const i in data) {
        for (const j in data[i].contratos) {
          funcionarios.push({
            car_id: data[i].cargo.car_id,
            func_id: data[i].func_id,
            func_nivel: data[i].func_nivel,
            funcionario: data[i].contratos[j].funcionario
          })
        }
      }

      this.setState({
        funcoes: {
          list: data
        },
        funcionarios: {
          list: funcionarios
        }
      })

      const comissoes = data.map(funcao => {
        return {
          contcomis_fun_id: this.verificaComissionado(funcao.func_id),
          func_nivel: funcao.func_nivel,
          funcao_id: funcao.func_id,
          cargo_id: funcao.cargo.car_id
        }
      })

      this.setState({
        comissoes
      })
    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {

      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())
      const { data: metodosDivugacoes } = await axios.get(`${process.env.REACT_APP_API_URL}/metodoDivugacao`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        },
        metodosDivugacoes: {
          list: metodosDivugacoes.filter(param => param.metdiv_ativo === true)
        },
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {

      const { data: cursos } = await axios.get(`${process.env.REACT_APP_API_URL}/curso`, this.getToken())
      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())

      this.setState({
        unidades: {
          list: unidades.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : ((b.unin_numero > a.unin_numero) ? -1 : 0)).filter(param => param.unin_status === true)
        },
        cursos: {
          list: cursos.filter(param => param.curso_ativo === true)
        }
      })
    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    const { match: { params } } = this.props;

    if(params.alunoId){
      this.setState({
        habilitaDocumento: true
      })
    }

    // Editando ou nova matricula Aluno
    if (params.alunoId || params.pefIdEncode) {
      try {

        const data = await this.getDadosAluno()

        aluno = data.aluno

        responsavel = data.responsavel

        if(aluno.curso){
          this.setState({
            curso: {
              curso_id: aluno.curso.curso_id,
              curso_nome: aluno.curso.curso_nome,
              duracoes: aluno.curso.duracoes
            }
          })
        }

        if(aluno.unidade.unin_id){
          await this.consultaContaFinanceira(aluno.unidade.unin_id)
          await this.consultarConfiguracao(aluno.unidade.unin_id)
        }
        
        this.setState({
          unidade: {
            unin_id: aluno.unidade.unin_id,
            unin_descricao: aluno.unidade.unin_descricao,
            unin_numero: aluno.unidade.unin_numero,
          }
        })
        
        try {
          await this.buscarComissoes(aluno.contrato.contm_id)

          const comissoes = this.state.funcoes.list.map(funcao => {
            return {
              contcomis_fun_id: this.verificaComissionado(funcao.func_id),
              funcao_id: funcao.func_id,
              func_slug: funcao.func_slug,
              func_nivel: funcao.func_nivel,
              cargo_id: funcao.cargo.car_id
            }
          })

          const logado = JSON.parse(localStorage.getItem('usuario'))

          this.setState({
            logado,
            comissoes,
            loading: false
          })
        } catch ({ response }) {
          // console.log(error)
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

      } catch ({response}) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

    }

    if (unin_tipo === 'UNIDADE') {
      aluno.contrato.contm_unin_id = dados.unidadesnegocio[0]

      await this.consultaContaFinanceira(dados.unidadesnegocio[0])
      await this.consultarConfiguracao(dados.unidadesnegocio[0])
    }

    if(aluno.contrato.contm_unin_id === '' && aluno.unidade.unin_id !== ''){
      aluno.contrato.contm_unin_id = aluno.unidade.unin_id
    }

    if(aluno.contrato.stcontmat_situacao === "AGUARDANDO_APROVACAO" || aluno.contrato.stcontmat_situacao === "AGUARDANDO_ASSINATURA"){
      this.setState({
        permissoesEditarDadosPessoais: {
          perm_alterar: true,
          perm_inserir: true,
          perm_visualizar: true,
          perm_deletar: true
        },
        permissoesEditarDadosAcademicos: {
          perm_alterar: true,
          perm_inserir: true,
          perm_visualizar: true,
          perm_deletar: true
        },
        permissoesEditarDadosResponsavel:{
          perm_alterar: true,
          perm_inserir: true,
          perm_visualizar: true,
          perm_deletar: true
        }
      })
    }else{
      if(permissoesEditarDadosPessoais){
        this.setState({permissoesEditarDadosPessoais})
      }
  
      if(permissoesEditarDadosAcademicos){
        this.setState({permissoesEditarDadosAcademicos})
      }
  
      if(permissoesEditarDadosResponsavel){
        this.setState({permissoesEditarDadosResponsavel})
      }
    }

    this.setState({
      aluno,
      responsavel,
      loading: false
    })
  }

  async consultarConfiguracao(unidade_id){
    try {
      const { data: configuracao } = await axios.get(`${process.env.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade_id}`, this.getToken())
      
      if (configuracao) {
        this.setState({
          configuracao: {
            confunin_certificado_id: configuracao.confunin_certificado_id,
            confunin_hab_assinatura_doc: configuracao.confunin_hab_assinatura_doc
          },
        })
      }

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  async consultaContaFinanceira(unidade_id){
    try {
      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${unidade_id}`, this.getToken())

      this.setState({
        contasFinanceira: {
          list: contasFinanceira
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  verificaComissionado(func_id) {
    if (this.state.comissionados) {
      for (const comissionado of this.state.comissionados) {
        if (comissionado.comissaofunc.funcao.func_id === func_id) {
          return comissionado.contcomis_fun_id
        }
      }
    }

    return ''
  }

  async getDadosAluno() {

    let alunoAux = {
      _id: "",
      pesf_id: "",
      pessoaFisica: {
        nome: "",
        sobrenome: "",
        cpf: "",
        rg: "",
        rg_orgao_emissor: "",
        rg_uf: "",
        rg_data_emissao: "",
        dataNascimento: "",
        idade: 0,
        maiorIdade: false
      },
      enderecos: [{
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: true,
        tipoEndereco: "RESIDENCIAL",
        index: 1
      }],
      emails: [{
        email: "",
        principal: true,
        index: 1
      }],
      contatos: [{
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: true,
        index: 1
      }],
      contrato: {
        contm_id: "",
        contm_data_inicio_vigencia: "",
        contm_duracao: "",
        contm_observacao: "",
        contm_curso_id: "",
        contm_ativo: true,
        contm_unin_id: "",
        contm_tabval_id: "",
        contm_data_vct_matricula: "",
        contm_data_vct_primeira_mensalidade: "",
        formapagto_id: "",
        stcontmat_situacao: "AGUARDANDO_APROVACAO",
        contm_metdiv_id: '',
        contm_num_modulos: '',
        contm_inicio_curso: '',
        contm_mes_inicio_curso: '',
        contm_dia_aula_1: '',
        contm_dia_aula_2: '',
        contm_dia_aula_1_periodo: '',
        contm_dia_aula_2_periodo: '',
        contm_dia_aula_1_horario: '',
        contm_dia_aula_2_horario: '',
        contm_duracao_aula: '',
        tabela: {
          _id: '',
          tabval_nome: '',
          tabval_num_parc: '',
          tabval_val_cheio_mensal: '',
          tabval_val_matri: '',
          tabval_val_mensal: '',
          tabval_val_mensal_total: ''
        },
        material: {
          matcont_valor: '',
          matcont_tipo: '',
          matcont_num_parcelas: '',
          matcont_dia_vencimento: '',
          matcont_formpag_id: '',
          matcont_tabprec_id: '',
          matcont_prod_id: '',
        }
      },
      aprovarCadastro: false
    }
    let responsavelAux = {
      pesf_id: "",
      pessoaFisica: {
        nome: "",
        sobrenome: "",
        cpf: "",
        rg: "",
        rg_orgao_emissor: "",
        rg_uf: "",
        rg_data_emissao: "",
        dataNascimento: ""
      },
      enderecos: [{
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: true,
        tipoEndereco: "",
        index: 1
      }],
      emails: [{
        email: "",
        principal: true,
        index: 1
      }],
      contatos: [{
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: true,
        index: 1
      }],
    }

    const { match: { params } } = this.props;

    // Editando ou nova matricula Aluno
    if (params.alunoId || params.pefIdEncode) {
      let auxPessoa = {}
      let auxFisica = {}
      let auxAluno = {}

      if (params.pefIdEncode) {
        // const pesf_id = Buffer.from(params.pefIdEncode, 'base64').toString('ascii')
        const pesf_id = params.pefIdEncode
        const { data: novaMatricula } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/pessoa/${pesf_id}`, this.getToken())

        auxPessoa = novaMatricula.pessoa
        auxFisica = novaMatricula
        auxAluno = novaMatricula.aluno || {}

        this.setState({ 
          novaMatricula: novaMatricula.aluno ? true : false 
        })
      }

      if (params.alunoId) {
        const { data: aluno } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/${params.alunoId}`, this.getToken())
        auxPessoa = aluno.fisica.pessoa
        auxFisica = aluno.fisica
        auxAluno = aluno

        this.setState({ edit: true })
      }

      try {

        const aluno = {
          _id: auxAluno ? auxAluno.aluno_id : '',
          aluno_matricula: auxAluno && auxAluno.aluno_matricula ? auxAluno.aluno_matricula : '',
          pesf_id: auxFisica.pesf_id,
          aprovarCadastro: false,
        }

        aluno.enderecos = auxPessoa.enderecos.map((end, key) => {
          return {
            end_id: end.end_id,
            cep_id: end.end_cep_id,
            complemento: end.end_complemento,
            rua: end.end_logradouro,
            numero: end.end_numero,
            bairro: end.end_bairro,
            cidade: end.end_cidade,
            estado: end.end_estado,
            cep: end.end_cep,
            principal: end.end_principal,
            tipoEndereco: end.end_tipo,
            index: key + 1
          }
        })

        aluno.contatos = auxPessoa.telefones.map((cont, key) => {
          return {
            tel_id: cont.tel_id,
            numero: cont.tel_numero,
            tipoContato: cont.tel_tipo,
            principal: cont.tel_principal,
            index: key + 1
          }
        })

        aluno.emails = auxPessoa.emails.map((ema, key) => {
          return {
            ema_id: ema.ema_id,
            email: ema.ema_email,
            principal: ema.ema_principal,
            index: key + 1
          }
        })

        const idade = calculaIdade(moment(auxFisica.pesf_datanascimento).format('Y-MM-DD'))

        aluno.pessoaFisica = {
          nome: auxFisica.pesf_nome,
          sobrenome: auxFisica.pesf_sobrenome,
          cpf: cpf.format(auxFisica.pesf_cpf),
          rg: auxFisica.pesf_rg,
          rg_orgao_emissor: auxFisica.pesf_rg_orgao_emissor,
          rg_uf: auxFisica.pesf_rg_uf,
          rg_data_emissao: auxFisica.pesf_rg_data_emissao ? moment(auxFisica.pesf_rg_data_emissao).format('YYYY-MM-DD') : '',
          dataNascimento: moment(auxFisica.pesf_datanascimento).format('YYYY-MM-DD'),
          idade: idade,
          maiorIdade: idade < 18 ? false : true
        }

        if (this.state.edit) {
          aluno.contrato = {
            contm_id: auxAluno.contrato.contm_id,
            contm_data_inicio_vigencia: moment(auxAluno.contrato.contm_data_inicio_vigencia).format('YYYY-MM-DD'),
            contm_duracao: auxAluno.contrato.contm_duracao,
            contm_observacao: auxAluno.contrato.contm_observacao ? auxAluno.contrato.contm_observacao : '',
            contm_curso_id: auxAluno.contrato.contm_curso_id,
            contm_ativo: auxAluno.contrato.contm_ativo,
            contm_unin_id: auxAluno.contrato.contm_unin_id,
            contm_tabval_id: auxAluno.contrato.tab_val_cont.tabvalcont_tabval_id,
            contm_data_vct_matricula: moment(auxAluno.contrato.contm_data_vct_matricula).format('YYYY-MM-DD'),
            contm_data_vct_primeira_mensalidade: moment(auxAluno.contrato.contm_data_vct_primeira_mensalidade).format('YYYY-MM-DD'),
            contm_metdiv_id: auxAluno.contrato.contm_metdiv_id,
            contfin_id: auxAluno.contrato.contm_contfin_id || '',
            contm_num_modulos: '',
            contm_inicio_curso: auxAluno.contrato.contm_inicio_curso || '',
            contm_mes_inicio_curso: auxAluno.contrato.contm_mes_inicio_curso || '',
            contm_dia_aula_1: auxAluno.contrato.contm_dia_aula_1 || '',
            contm_dia_aula_2: auxAluno.contrato.contm_dia_aula_2 || '',
            contm_dia_aula_1_periodo: auxAluno.contrato.contm_dia_aula_1_periodo || '',
            contm_dia_aula_2_periodo: auxAluno.contrato.contm_dia_aula_2_periodo || '',
            contm_dia_aula_1_horario: auxAluno.contrato.contm_dia_aula_1_horario || '',
            contm_dia_aula_2_horario: auxAluno.contrato.contm_dia_aula_2_horario || '',
            contm_duracao_aula: auxAluno.contrato.contm_duracao_aula || '',
            tabval_nome: auxAluno.contrato.tab_val_cont.tabvalcont_nome,
            formapagto_id: auxAluno.contrato.contm_forma_pagto_id,
            stcontmat_situacao: auxAluno.contrato.status.stcontmat_situacao,
            tabela: {
              _id: auxAluno.contrato.tab_val_cont.tabvalcont_tabval_id,
              tabval_nome: auxAluno.contrato.tab_val_cont.tabvalcont_nome,
              tabval_num_parc: auxAluno.contrato.tab_val_cont.tabvalcont_num_parc,
              tabval_val_cheio_mensal: converteFloatMoeda(auxAluno.contrato.tab_val_cont.tabvalcont_val_cheio_mensal),
              tabval_val_matri: converteFloatMoeda(auxAluno.contrato.tab_val_cont.tabvalcont_val_matri),
              tabval_val_mensal: converteFloatMoeda(auxAluno.contrato.tab_val_cont.tabvalcont_val_mensal),
              tabval_val_mensal_total: converteFloatMoeda(auxAluno.contrato.tab_val_cont.tabvalcont_val_mensal * auxAluno.contrato.tab_val_cont.tabvalcont_num_parc)
            },
            resp_fin: auxAluno.contrato.resp_fin
          }

          if (auxAluno.contrato.material_contrato) {
            aluno.contrato.material = {
              matcont_valor: converteFloatMoeda(auxAluno.contrato.material_contrato.matcont_valor),
              matcont_tipo: auxAluno.contrato.material_contrato.matcont_tipo,
              matcont_num_parcelas: auxAluno.contrato.material_contrato.matcont_num_parcelas,
              matcont_dia_vencimento: auxAluno.contrato.material_contrato.matcont_dia_vencimento ? moment(auxAluno.contrato.material_contrato.matcont_dia_vencimento).format('YYYY-MM-DD') : '',
              matcont_formpag_id: auxAluno.contrato.material_contrato.matcont_formpag_id,
              matcont_tabprec_id: auxAluno.contrato.material_contrato.matcont_tabprec_id,
              matcont_prod_id: auxAluno.contrato.material_contrato.matcont_prod_id,
              matcont_prod_descricao: auxAluno.contrato.material_contrato.material.prod_descricao,
              contfin_id: auxAluno.contrato.material_contrato.matcont_contfin_id
            }
          }

          try {
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/listBooks/materiais/byCurso/${auxAluno.contrato.curso.curso_id}`, this.getToken())
            const produtos = []
      
            resp.data.forEach(produto => {
              produtos.push({
                _id: produto.prod_id,
                descricao: produto.prod_descricao,
              })
            })
      
            this.setState({
              materiais: {
                list: produtos
              }
            })
      
          } catch ({ response }) {
            this.handleOpenDialog({
              titulo: 'Ops...',
              descricao: response.data.message
            })
            return
          }
          
          aluno.curso = {
            curso_id: auxAluno.contrato.curso.curso_id,
            curso_nome: auxAluno.contrato.curso.curso_nome,
            duracoes: auxAluno.contrato.curso.duracoes
          }

          aluno.unidade = {
            unin_id: auxAluno.contrato.unidade.unin_id,
            unin_descricao: auxAluno.contrato.unidade.unin_descricao,
            unin_numero: auxAluno.contrato.unidade.unin_numero,
          }

          if(auxAluno.contrato.modulos){
            this.setState({
              modulosSelecionadas: auxAluno.contrato.modulos.map(value => {
                return {
                  num: value.modcontm_numero,
                  value: value.modcontm_numero_extenso
                }
              })
            })
          }
        } else {
          aluno.contrato = initalState.aluno.contrato
          aluno.curso = initalState.aluno.curso
          
          const unidades = this.state.unidades.list
          const unidade = unidades.filter(param => param.unin_id === parseInt(localStorage.getItem('tra_unin_destino_id')))[0]

          if(unidade){
            aluno.unidade = {
              unin_id: unidade.unin_id,
              unin_descricao: unidade.unin_descricao,
              unin_numero: unidade.unin_numero
            }
          }else{
            aluno.unidade = {
              unin_id: '',
              unin_descricao: '',
              unin_numero: ''
            }
          }

        }

        alunoAux = aluno

        if(auxAluno.contrato){
          if (auxAluno.contrato.resp_fin) {
            const auxResp = auxAluno.contrato.resp_fin
  
            if (auxAluno.contrato.resp_fin) {
              this.setState({
                enableBuscaResp: false
              })
  
              const enderecosResp = auxResp.pessoa.enderecos.map((end, key) => {
                return {
                  end_id: end.end_id,
                  cep_id: end.end_cep_id,
                  complemento: end.end_complemento,
                  rua: end.end_logradouro,
                  numero: end.end_numero,
                  bairro: end.end_bairro,
                  cidade: end.end_cidade,
                  estado: end.end_estado,
                  cep: end.end_cep,
                  principal: end.end_principal,
                  tipoEndereco: end.end_tipo,
                  index: key + 1
                }
              })
  
              const contatosResp = auxResp.pessoa.telefones.map((cont, key) => {
                return {
                  tel_id: cont.tel_id,
                  numero: cont.tel_numero,
                  tipoContato: cont.tel_tipo,
                  principal: cont.tel_principal,
                  index: key + 1
                }
              })
  
              const emailsResp = auxResp.pessoa.emails.map((ema, key) => {
                return {
                  ema_id: ema.ema_id,
                  email: ema.ema_email,
                  principal: ema.ema_principal,
                  index: key + 1
                }
              })
  
              const responsavel = {
                _id: auxResp.responsavel_id,
                responsavel_matricula: auxResp.responsavel_matricula,
                pesf_id: auxResp.pesf_id,
                pessoaFisica: {
                  nome: auxResp.pesf_nome,
                  sobrenome: auxResp.pesf_sobrenome,
                  cpf: cpf.format(auxResp.pesf_cpf),
                  rg: auxResp.pesf_rg,
                  rg_orgao_emissor: auxResp.pesf_rg_orgao_emissor,
                  rg_uf: auxResp.pesf_rg_uf,
                  rg_data_emissao: auxResp.pesf_rg_data_emissao ?
                    moment(auxResp.pesf_rg_data_emissao).format('YYYY-MM-DD') :
                    '',
                  dataNascimento: moment(auxResp.pesf_datanascimento).format('YYYY-MM-DD')
                },
                enderecos: enderecosResp,
                emails: emailsResp,
                contatos: contatosResp
              }
  
              responsavelAux = responsavel
  
              this.setState({
                searchResp: {
                  cpf: auxResp.pesf_cpf
                },
              })
            }
  
            if (auxAluno.contrato.resp_fin) {
              aluno.pessoaFisica.maiorIdade = false
            }
          }
        }       

      } catch (error) {
        console.log(error)
        // this.handleOpenDialog({
        //   titulo: "Opps!",
        //   descricao: response.data.message
        // })

        // this.backPage()
      }
    }

    if(alunoAux.contrato.stcontmat_situacao === "AGUARDANDO_ASSINATURA"){
      alunoAux.aprovarCadastro = true
    }
    // aprovarCadastro

    return {
      aluno: alunoAux,
      responsavel: responsavelAux,
    }
  }

  async buscarComissoes(contm_id) {
    this.setState({ loadList: true })

    if (contm_id) {

      const { data: comissionados } = await axios.get(`${process.env.REACT_APP_API_URL}/comissao/${contm_id}`, this.getToken())

      this.setState({
        comissionados
      })

    }

    this.setState({
      loadList: false
    })
  }

  updateFieldPessoaFisica(event) {
    const aluno = { ...this.state.aluno }
    aluno.pessoaFisica[event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldPessoaFisicaResponsavel(event) {
    const responsavel = { ...this.state.responsavel }
    responsavel.pessoaFisica[event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  updateFieldEndereco(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldContato(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  formataTelefone(event, index) {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const aluno = { ...this.state.aluno }
    aluno.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    this.setState({ aluno })
  }

  formataTelefoneResponsavel(event, index) {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const responsavel = { ...this.state.responsavel }
    responsavel.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    this.setState({ responsavel })
  }

  updateFieldEmail(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.emails[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldEnderecoResponsavel(event, index) {
    const responsavel = { ...this.state.responsavel }
    responsavel.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  updateFieldContatoResponsavel(event, index) {
    const responsavel = { ...this.state.responsavel }
    responsavel.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  updateFieldEmailResponsavel(event, index) {
    const responsavel = { ...this.state.responsavel }
    responsavel.emails[index - 1][event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  async updateFieldCurso(event, value) {
    const { aluno } = this.state

    if(!value) {
      aluno.contrato.contm_curso_id = ''
      aluno.contrato.contm_duracao = ''

      this.setState({
        aluno,
        curso: {
          curso_id: '',
          curso_nome: '',
          duracoes: []
        }
      })

      return false
    }

    aluno.contrato.material.matcont_prod_id = ''

    aluno.contrato.contm_curso_id = value.curso_id

    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/listBooks/materiais/byCurso/${value.curso_id}`, this.getToken())
      const produtos = []

      resp.data.forEach(produto => {
        produtos.push({
          _id: produto.prod_id,
          descricao: produto.prod_descricao,
        })
      })

      this.setState({
        materiais: {
          list: produtos
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    if(aluno.contrato.contm_unin_id !== ''){
      await this.buscaPlanos(aluno.contrato.contm_unin_id, value.curso_id)
    }

    this.setState({
      aluno,
      curso: {
        curso_id: value.curso_id,
        curso_nome: value.curso_nome,
        duracoes: value.duracoes
      }
    })
  }

  updateFieldModulo(event, value) {
    this.setState({
      modulosSelecionadas: value
    })
  }

  updateFieldTabelaValor(value) {
    const { aluno } = this.state
    aluno.contrato.contm_tabval_id = value._id
    this.setState({
      aluno
    })

  }

  async updateFieldUnidade(event, value) {
    if (!value) return false
    const aluno = { ...this.state.aluno }
    aluno.contrato.contm_unin_id = value.unin_id


    await this.consultaContaFinanceira(value.unin_id)
    await this.consultarConfiguracao(value.unin_id)
    await this.buscaPlanos(value.unin_id, aluno.contrato.contm_curso_id)

    this.setState({
      aluno,
      unidade: {
        unin_id: value.unin_id,
        unin_descricao: value.unin_descricao,
        unin_numero: value.unin_numero
      }
    })
  }

  async buscaPlanos(unin_id, curso_id){
    try {
      let {aluno} = this.state

      const tabelas = []

      const { data: tabelaValores } = await axios.get(`${process.env.REACT_APP_API_URL}/planoPagamentoCurso/listByUnidadeByCurso/${unin_id}/${curso_id}`, this.getToken())

      for (let i = 0; i < tabelaValores.length; i++) {
        for (let j = 0; j < tabelaValores[i].tabelas_valores.length; j++) {
          tabelas.push({
            _id: tabelaValores[i].tabelas_valores[j].tabval_id,
            planpag_descricao: tabelaValores[i].planpag_descricao,
            tabval_nome: tabelaValores[i].tabelas_valores[j].tabval_nome,
            tabval_num_parc: tabelaValores[i].tabelas_valores[j].tabval_num_parc,
            tabval_val_matri: moeda(tabelaValores[i].tabelas_valores[j].tabval_val_matri),
            tabval_val_mensal: moeda(tabelaValores[i].tabelas_valores[j].tabval_val_mensal),
            tabval_val_cheio_mensal: moeda(tabelaValores[i].tabelas_valores[j].tabval_val_cheio_mensal)
          })
        }
      }

      aluno.contrato.tabela = {
        _id: '',
        tabval_nome: '',
        tabval_num_parc: '',
        tabval_val_cheio_mensal: '',
        tabval_val_mensal: '',
        tabval_val_matri: '',
        tabval_val_mensal_total: '',
      }

      aluno.contrato.contm_tabval_id = ''
      aluno.contrato.tabval_nome = ''

      this.setState({
        planos: {
          list: tabelas
        },
        tabelaValoresFiltrado: {
          list: tabelaValores
        },
        aluno
      })
    } catch ({response}) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: response.data.message
      })
    }
  }

  async updateFieldContrato(event) {
    const { aluno } = this.state
    const { contrato } = aluno
    const planos = this.state.planos.list

    if (event.target.name === 'contm_tabval_id') {
      const index = planos.findIndex(tabela => tabela._id === parseInt(event.target.value))

      contrato.tabela = {
        _id: planos[index]._id,
        tabval_nome: planos[index].tabval_nome,
        tabval_num_parc: planos[index].tabval_num_parc,
        tabval_val_cheio_mensal: planos[index].tabval_val_cheio_mensal,
        tabval_val_matri: planos[index].tabval_val_matri,
        tabval_val_mensal: planos[index].tabval_val_mensal,
        tabval_val_mensal_total: real(parseFloat(planos[index].tabval_val_mensal.replace("R$ ", "").replace(".", "").replace(",", ".")) * planos[index].tabval_num_parc)
      }

      contrato.tabval_nome = planos[index].tabval_nome
    }

    if(event.target.name === 'formapagto_id'){
      contrato.contfin_id = ''
    }

    contrato[event.target.name] = event.target.value

    aluno.contrato = contrato

    this.setState({ aluno })
  }

  async updateFieldMaterialContrato(event) {
    const { aluno } = this.state
    const { material } = aluno.contrato
    const materiais = this.state.materiais.list

    material[event.target.name] = event.target.value

    if (event.target.name === 'matcont_prod_id') {
      if (aluno.contrato.contm_unin_id === '') {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor selecionar a unidade!'
        })
        return
      }

      aluno.contrato.material.matcont_prod_descricao = materiais.filter(param => param._id === parseInt(event.target.value))[0].descricao
      await this.buscaTabelaPrecoMaterial()
    }

    if(event.target){
      if (event.target.name === 'matcont_formpag_id'){
        material.contfin_id = ''
      }
    }

    aluno.contrato.material = material

    this.setState({ aluno })
  }

  async buscaTabelaPrecoMaterial() {

    const { aluno } = this.state
    const { material } = aluno.contrato

    if (material.matcont_prod_id === '') return true

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tabelaPreco/produto/${material.matcont_prod_id}/${aluno.contrato.contm_unin_id}`, this.getToken())

    material.matcont_tabprec_id = data.tabprec_id || ''
    aluno.contrato.material = material

    this.setState({
      aluno,
      tabelaPrecoMaterial: Object.keys(data).length ? data : null
    })
  }

  updateFieldEnderecoPrincipal(event, index) {
    const aluno = { ...this.state.aluno }
    const indexAux = aluno.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      aluno.enderecos[indexAux].principal = false
    }

    aluno.enderecos[index - 1].principal = !aluno.enderecos[index - 1].principal
    this.setState({ aluno })
  }

  updateFieldContatoPrincipal(event, index) {
    const aluno = { ...this.state.aluno }
    const indexAux = aluno.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      aluno.contatos[indexAux].principal = false
    }

    aluno.contatos[index - 1].principal = !aluno.contatos[index - 1].principal
    this.setState({ aluno })
  }

  updateFieldEmailPrincipal(event, index) {
    const aluno = { ...this.state.aluno }
    const indexAux = aluno.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      aluno.emails[indexAux].principal = false
    }

    aluno.emails[index - 1].principal = !aluno.emails[index - 1].principal

    this.setState({ aluno })
  }

  updateFieldEnderecoPrincipalResponsavel(event, index) {
    const responsavel = { ...this.state.responsavel }
    const indexAux = responsavel.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.enderecos[indexAux].principal = false
    }

    responsavel.enderecos[index - 1].principal = !responsavel.enderecos[index - 1].principal
    this.setState({ responsavel })
  }

  updateFieldContatoPrincipalResponsavel(event, index) {
    const responsavel = { ...this.state.responsavel }
    const indexAux = responsavel.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.contatos[indexAux].principal = false
    }

    responsavel.contatos[index - 1].principal = !responsavel.contatos[index - 1].principal
    this.setState({ responsavel })
  }

  updateFieldEmailPrincipalResponsavel(event, index) {
    const responsavel = { ...this.state.responsavel }
    const indexAux = responsavel.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.emails[indexAux].principal = false
    }

    responsavel.emails[index - 1].principal = !responsavel.emails[index - 1].principal

    this.setState({ responsavel })
  }

  async validarCpfCnpj(event) {

    let cpf_cnpj = event.target.value
    const aluno = this.state.aluno

    const dados = {
      pesf_id: aluno.pesf_id
    }

    const idade = calculaIdade(moment(aluno.pessoaFisica.dataNascimento).format('Y-MM-DD'))

    const maiorIdade = idade < 18 ? false : true

    if (!maiorIdade) {
      if (cpf_cnpj === '0') {
        cpf_cnpj = '000.000.000-00'
      }

      cpf_cnpj = cpf.format(cpf_cnpj)

      if (cpf_cnpj === '000.000.000-00') {
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

          if (data) {
            aluno.pessoaFisica.cpf = ""
            this.setState({ aluno })

            const erro = {
              titulo: "CPF Invalido!",
              descricao: "Já existe um cadastro com este CPF"
            }
            this.setState({
              modalErro: true,
              erro
            })
          } else {
            aluno.pessoaFisica.cpf = cpf_cnpj
            this.setState({ aluno })
          }

        } catch (error) {
          console.log(error)
        }
      } else if (!cpf.isValid(cpf_cnpj)) {
        aluno.pessoaFisica.cpf = ""
        this.setState({ aluno })

        const erro = {
          titulo: "CPF Invalido!",
          descricao: "Por favor corrija o campo CPF"
        }
        this.setState({
          modalErro: true,
          erro
        })
      } else {
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

          if (data) {
            aluno.pessoaFisica.cpf = ""
            this.setState({ aluno })

            const erro = {
              titulo: "CPF Invalido!",
              descricao: "Já existe um cadastro com este CPF"
            }
            this.setState({
              modalErro: true,
              erro
            })
          } else {
            aluno.pessoaFisica.cpf = cpf_cnpj
            this.setState({ aluno })
          }

        } catch (error) {
          console.log(error)
        }


      }
    } else {
      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {
        aluno.pessoaFisica.cpf = ""
        this.setState({ aluno })

        const erro = {
          titulo: "CPF Invalido!",
          descricao: "Por favor corrija o campo CPF"
        }
        this.setState({
          modalErro: true,
          erro
        })
      } else {
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

          if (data) {
            aluno.pessoaFisica.cpf = ""
            this.setState({ aluno })

            const erro = {
              titulo: "CPF Invalido!",
              descricao: "Já existe um cadastro com este CPF"
            }
            this.setState({
              modalErro: true,
              erro
            })
          } else {
            aluno.pessoaFisica.cpf = cpf_cnpj
            this.setState({ aluno })
          }

        } catch (error) {
          console.log(error)
        }
      }
    }

    if (cpf_cnpj === '000.000.000-00') {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

        if (data) {
          aluno.pessoaFisica.cpf = ""
          this.setState({ aluno })

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          this.setState({
            modalErro: true,
            erro
          })
        } else {
          aluno.pessoaFisica.cpf = cpf_cnpj
          this.setState({ aluno })
        }

      } catch (error) {
        console.log(error)
      }
    } else if (!cpf.isValid(cpf_cnpj)) {
      aluno.pessoaFisica.cpf = ""
      this.setState({ aluno })

      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      this.setState({
        modalErro: true,
        erro
      })
    } else {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

        if (data) {
          aluno.pessoaFisica.cpf = ""
          this.setState({ aluno })

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          this.setState({
            modalErro: true,
            erro
          })
        } else {
          aluno.pessoaFisica.cpf = cpf_cnpj
          this.setState({ aluno })
        }

      } catch (error) {
        console.log(error)
      }


    }
  }

  async validarCpfCnpjResponsavel(event) {

    let cpf_cnpj = event.target.value
    const responsavel = this.state.responsavel
    cpf_cnpj = cpf.format(cpf_cnpj)


    const dados = {
      pesf_id: responsavel.pesf_id
    }

    if (cpf_cnpj === '000.000.000-00') {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

        if (data) {
          responsavel.pessoaFisica.cpf = ""
          this.setState({ responsavel })

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          this.setState({
            modalErro: true,
            erro
          })
        } else {
          responsavel.pessoaFisica.cpf = cpf_cnpj
          this.setState({ responsavel })
        }

      } catch (error) {
        console.log(error)
      }
    } else if (!cpf.isValid(cpf_cnpj)) {
      responsavel.pessoaFisica.cpf = ""
      this.setState({ responsavel })

      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      this.setState({
        modalErro: true,
        erro
      })
    } else {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

        if (data) {
          responsavel.pessoaFisica.cpf = ""
          this.setState({ responsavel })

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          this.setState({
            modalErro: true,
            erro
          })
        } else {
          responsavel.pessoaFisica.cpf = cpf_cnpj
          this.setState({ responsavel })
        }

      } catch (error) {
        console.log(error)
      }
    }
  }

  async pegaEndereco(event, index) {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const aluno = { ...this.state.aluno }
      const enderecos = aluno.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      aluno.enderecos[index - 1] = endereco
      this.setState({ aluno })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        aluno.enderecos[index - 1] = endereco
        this.setState({ aluno })

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        aluno.enderecos[index - 1] = endereco
        this.setState({ aluno })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  async pegaEnderecoResponsavel(event, index) {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const responsavel = { ...this.state.responsavel }
      const enderecos = responsavel.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      responsavel.enderecos[index - 1] = endereco
      this.setState({ responsavel })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        responsavel.enderecos[index - 1] = endereco
        this.setState({ responsavel })

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        responsavel.enderecos[index - 1] = endereco
        this.setState({ responsavel })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  addNovoEndereco() {
    const aluno = { ...this.state.aluno }
    const enderecos = aluno.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      comlpemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    aluno.enderecos = enderecos
    this.setState({ aluno })
  }

  addNovoContato() {
    const aluno = { ...this.state.aluno }
    const contatos = aluno.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "55",
      complemento: "",
      tipoContato: "COMERCIAL",
      principal: false,
      index
    })

    aluno.contatos = contatos
    this.setState({ aluno })
  }

  addNovoEmail() {
    const aluno = { ...this.state.aluno }
    const emails = aluno.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    aluno.emails = emails
    this.setState({ aluno })
  }

  addNovoEnderecoResponsavel() {
    const responsavel = { ...this.state.responsavel }
    const enderecos = responsavel.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      comlpemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    responsavel.enderecos = enderecos
    this.setState({ responsavel })
  }

  addNovoContatoResponsavel() {
    const responsavel = { ...this.state.responsavel }
    const contatos = responsavel.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "55",
      complemento: "",
      tipoContato: "COMERCIAL",
      principal: false,
      index
    })

    responsavel.contatos = contatos
    this.setState({ responsavel })
  }

  addNovoEmailResponsavel() {
    const responsavel = { ...this.state.responsavel }
    const emails = responsavel.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    responsavel.emails = emails
    this.setState({ responsavel })
  }

  diasNoMes(mes, ano) {
    var data = new Date(ano, mes, 0);
    return data.getDate();
  }

  async proximo(atual, prox) {
    const { aluno, responsavel, modulosSelecionadas} = this.state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
     
      const aluno = { ...this.state.aluno }
      const idade = calculaIdade(moment(aluno.pessoaFisica.dataNascimento).format('Y-MM-DD'))

      if (!aluno.contrato.resp_fin) {
        aluno.pessoaFisica.maiorIdade = idade < 18 ? false : true
      }

      aluno.pessoaFisica.idade = idade
      this.setState({ aluno })
      const anoNascimentoAluno = moment(aluno.pessoaFisica.dataNascimento).format('YYYY')

      if (parseInt(anoNascimentoAluno) > 9999) {
        this.handleOpenDialog({
          titulo: "Erro no Cadastro!",
          descricao: "Data de Nascimento Invalido!"
        })
        return
      }

      if (aluno.pessoaFisica.maiorIdade) {
        if (aluno.pessoaFisica.cpf === '000.000.000-00') {
          this.setState({
            modalErro: true,
            erro: {
              titulo: "CPF Invalido!",
              descricao: "Por favor informar o campo CPF."
            }
          })
          return
        }
      }

      if (
        aluno.pessoaFisica.nome === "" ||
        aluno.pessoaFisica.cpf === "" ||
        aluno.pessoaFisica.dataNascimento === ""
      ) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      try {

        const dados = {
          pesf_id: aluno.pesf_id
        }

        let cpf_cnpj = cpf.format(aluno.pessoaFisica.cpf)

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

        if (data) {
          aluno.pessoaFisica.cpf = ""
          this.setState({ aluno })

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          this.setState({
            modalErro: true,
            erro
          })

          return
        } else {
          aluno.pessoaFisica.cpf = cpf_cnpj
          this.setState({ responsavel })
        }

      } catch (error) {
        console.log(error)
      }

      this.setState({
        enableDadosPessoa: false,
        enableDadosEndereco: true
      })

    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      aluno.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
        if (endereco.numero === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosEndereco: false,
        enableDadosContato: true
      })

    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      aluno.contatos.forEach(contato => {
        if (contato.numero === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosContato: false,
        enableDadosEmail: true
      })
    } else if (atual === "enableDadosEmail" && prox === "enableDadosComercial") {
      let valido = true
      aluno.emails.forEach(email => {
        if (email.email === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosEmail: false,
        enableDadosComercial: true
      })

    } else if (atual === "enableDadosComercial" && prox === "enableDadosContrato") {

      const comissoes = [...this.state.comissoes]

      const index = comissoes.findIndex(param => param.funcao_id === 5)

      if(comissoes[index].contcomis_fun_id === ''){
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Por favor informar o colaborador da função Trainee Júnior ."
          }
        })
        return
      }

      this.setState({
        enableDadosComercial: false,
        enableDadosContrato: true
      })

    } else if (atual === "enableDadosEmail" && prox === "enableDadosPessoaResponsavel") {
      let valido = true
      aluno.emails.forEach(email => {
        if (email.email === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosEmail: false,
        enableDadosPessoaResponsavel: true
      })

    } else if (atual === "enableDadosContrato" && prox === "enableDadosPessoaResponsavel") {

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

      if (unin_tipo === "FRANQUEADOR" || unin_tipo === "FRANQUEADO") {
        if (!aluno.contrato.contm_unin_id) {
          this.setState({
            modalErro: true,
            erro: {
              titulo: "Campo Vazio!",
              descricao: "Existem campo obrigatorios vazios."
            }
          })
          return
        }
      }

      const anoContrato = moment(aluno.contrato.contm_data_inicio_vigencia).format('YYYY')

      if (parseInt(anoContrato) > 9999) {
        this.handleOpenDialog({
          titulo: "Erro no Cadastro!",
          descricao: "Data de Inicio da Vigência Invalido!"
        })
        return
      }

      if (
        aluno.contrato.contm_duracao === "" ||
        aluno.contrato.contm_data_inicio_vigencia === "" ||
        aluno.contrato.contm_curso_id === "" ||
        aluno.contrato.contm_tabval_id === "" ||
        aluno.contrato.contm_data_vct_primeira_mensalidade === "" ||
        aluno.contrato.contm_data_vct_matricula === "" ||
        aluno.contrato.formapagto_id === "" ||
        aluno.contrato.material.matcont_valor === "" ||
        aluno.contrato.matcont_tipo === "" ||
        aluno.contrato.material.matcont_num_parcelas === "" ||
        aluno.contrato.material.matcont_dia_vencimento === "" ||
        aluno.contrato.material.matcont_formpag_id === "" ||
        aluno.contrato.material.matcont_prod_id === ""
      ) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      if(aluno.contrato.contm_curso_id === 2 || aluno.contrato.contm_curso_id === 12){
        if(modulosSelecionadas.length === 0){
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: `Por favor informar os modulos do curso`
          })
          return false
        }

        if(aluno.contrato.contm_inicio_curso === ''){
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: `Por favor informar o inicio do curso`
          })
          return false
        }

        if(aluno.contrato.contm_mes_inicio_curso === ''){
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: `Por favor informar o mês de inicio do curso`
          })
          return false
        }

        if(aluno.contrato.contm_duracao_aula === ''){
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: `Por favor informar a duração das aulas`
          })
          return false
        }

        if(aluno.contrato.contm_dia_aula_1 === ''){
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: `Por favor informar os dias da aula`
          })
          return false
        }

        // if(aluno.contrato.contm_dia_aula_2 === ''){
        //   this.setState({ loadingSalvar: false })
        //   this.handleOpenDialog({
        //     titulo: "Erro no Cadastro!",
        //     descricao: `Por favor informar os dias da aula`
        //   })
        //   return false
        // }

        if(aluno.contrato.contm_dia_aula_1_periodo === ''){
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: `Por favor informar o período das aulas`
          })
          return false
        }

        // if(aluno.contrato.contm_dia_aula_2_periodo === ''){
        //   this.setState({ loadingSalvar: false })
        //   this.handleOpenDialog({
        //     titulo: "Erro no Cadastro!",
        //     descricao: `Por favor informar o período das aulas`
        //   })
        //   return false
        // }

        if(aluno.contrato.contm_dia_aula_1_horario === ''){
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: `Por favor informar o horario das aulas`
          })
          return false
        }

        // if(aluno.contrato.contm_dia_aula_2_horario === ''){
        //   this.setState({ loadingSalvar: false })
        //   this.handleOpenDialog({
        //     titulo: "Erro no Cadastro!",
        //     descricao: `Por favor informar o horario das aulas`
        //   })
        //   return false
        // }
      }

      if(!this.state.enableBuscaResp){
        this.setState({
          modalConfirmacaoTrocaRespOpen: true,
          modalConfirmacao: {
            mensagem: 'Deseja trocar o responsável financeiro?',
          }
        })
        console.log(this.state.enableBuscaResp)
      }

      this.setState({
        enableDadosContrato: false,
        enableDadosPessoaResponsavel: true
      })

    } else if (atual === "enableDadosPessoaResponsavel" && prox === "enableDadosEnderecoResponsavel") {
      const idade = calculaIdade(moment(responsavel.pessoaFisica.dataNascimento).format('Y-MM-DD'))

      const maiorIdade = idade < 18 ? false : true

      const anoNascimentoResp = moment(responsavel.pessoaFisica.dataNascimento).format('YYYY')

      if (parseInt(anoNascimentoResp) > 9999) {
        this.handleOpenDialog({
          titulo: "Erro no Cadastro!",
          descricao: "Data de Nascimento do Responsável Invalido!"
        })
        return
      }

      if (maiorIdade) {
        if (responsavel.pessoaFisica.cpf === '000.000.000-00') {
          this.setState({
            modalErro: true,
            erro: {
              titulo: "CPF Invalido!",
              descricao: "Por favor informar o campo CPF."
            }
          })
          return
        }
      } else {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Erro!",
            descricao: "O Responsável do aluno precisa ser maior de idade."
          }
        })
        return
      }

      if (
        responsavel.pessoaFisica.nome === "" ||
        responsavel.pessoaFisica.cpf === "" ||
        responsavel.pessoaFisica.dataNascimento === ""
      ) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      responsavel.enderecos = []

      for (const endereco of aluno.enderecos) {
        responsavel.enderecos.push({
          ...endereco
        })
      }

      try {

        const dados = {
          pesf_id: responsavel.pesf_id
        }

        let cpf_cnpj = cpf.format(responsavel.pessoaFisica.cpf)

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, this.getToken())

        if (data) {
          responsavel.pessoaFisica.cpf = ""
          this.setState({ responsavel })

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          this.setState({
            modalErro: true,
            erro
          })

          return
        } else {
          responsavel.pessoaFisica.cpf = cpf_cnpj
          this.setState({ responsavel })
        }

      } catch (error) {
        console.log(error)
      }

      this.setState({
        responsavel,
        enableDadosPessoaResponsavel: false,
        enableDadosEnderecoResponsavel: true
      })
    } else if (atual === "enableDadosEnderecoResponsavel" && prox === "enableDadosContatoResponsavel") {
      let valido = true
      responsavel.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosEnderecoResponsavel: false,
        enableDadosContatoResponsavel: true
      })

    } else if (atual === "enableDadosContatoResponsavel" && prox === "enableDadosEmailResponsavel") {
      let valido = true
    
      responsavel.contatos.forEach(contato => {
        if (contato.numero === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosContatoResponsavel: false,
        enableDadosEmailResponsavel: true
      })
    }
  }

  voltar(atual, anterior) {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      this.setState({
        enableDadosPessoa: true,
        enableDadosEndereco: false
      })
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      this.setState({
        enableDadosEndereco: true,
        enableDadosContato: false
      })
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      this.setState({
        enableDadosContato: true,
        enableDadosEmail: false
      })
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosComercial") {
      this.setState({
        enableDadosEmail: true,
        enableDadosComercial: false
      })
    } else if (anterior === "enableDadosComercial" && atual === "enableDadosContrato") {
      this.setState({
        enableDadosComercial: true,
        enableDadosContrato: false
      })
    } else if (anterior === "enableDadosContrato" && atual === "enableDadosPessoaResponsavel") {
      this.setState({
        enableDadosContrato: true,
        enableDadosPessoaResponsavel: false
      })
    } else if (anterior === "enableBuscaResp" && atual === "enableDadosPessoaResponsavel") {
      this.setState({
        enableBuscaResp: true
      })
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosPessoaResponsavel") {
      this.setState({
        enableDadosEmail: true,
        enableDadosPessoaResponsavel: false
      })
    } else if (anterior === "enableDadosPessoaResponsavel" && atual === "enableDadosEnderecoResponsavel") {
      this.setState({
        enableDadosPessoaResponsavel: true,
        enableDadosEnderecoResponsavel: false
      })
    } else if (anterior === "enableDadosEnderecoResponsavel" && atual === "enableDadosContatoResponsavel") {
      this.setState({
        enableDadosEnderecoResponsavel: true,
        enableDadosContatoResponsavel: false
      })
    } else if (anterior === "enableDadosContatoResponsavel" && atual === "enableDadosEmailResponsavel") {
      this.setState({
        enableDadosContatoResponsavel: true,
        enableDadosEmailResponsavel: false
      })
    }
  }

  confirmarTrocaResp(value){
    if(value){
      this.setState({
        modalConfirmacaoTrocaRespOpen: false,
        modalConfirmacao: {
          mensagem: '',
        },
        enableBuscaResp: true
      })
    }else{
      this.setState({
        modalConfirmacaoTrocaRespOpen: false,
        modalConfirmacao: {
          mensagem: '',
        }
      })
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  backPage(timeout = 1000) {

    baseState = {
      ...baseState,
      aluno: {
        _id: "",
        pesf_id: "",
        pessoaFisica: {
          nome: "",
          cpf: "",
          sobrenome: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: "",
          idade: 0,
          maiorIdade: false
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "55",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        contrato: {
          contm_id: "",
          contm_data_inicio_vigencia: "",
          contm_duracao: "",
          contm_observacao: "",
          contm_curso_id: "",
          contm_ativo: true,
          contm_unin_id: "",
          contm_tabval_id: "",
          contm_data_vct_matricula: "",
          contm_data_vct_primeira_mensalidade: "",
          formapagto_id: "",
          stcontmat_situacao: "AGUARDANDO_APROVACAO",
          contm_metdiv_id: '',
          tabela: {
            _id: '',
            tabval_nome: '',
            tabval_num_parc: '',
            tabval_val_cheio_mensal: '',
            tabval_val_matri: '',
            tabval_val_mensal: '',
            tabval_val_mensal_total: ''
          },
          material: {
            matcont_valor: '',
            matcont_tipo: '',
            matcont_num_parcelas: '',
            matcont_dia_vencimento: '',
            matcont_formpag_id: '',
            matcont_tabprec_id: '',
            matcont_prod_id: '',
          }
        },
        aprovarCadastro: false
      },
      responsavel: {
        pesf_id: "",
        pessoaFisica: {
          nome: "",
          sobrenome: "",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: ""
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "55",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
      },
      senha: "",
      tabelaPrecoMaterial: null
    }

    initalState = {
      ...initalState,
      ...baseState
    }

    this.setState(Object.assign(this.state, baseState))

    const self = this
    setTimeout(() => {
      self.props.history.push("/aluno");
    }, timeout)
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateFieldSenha(event) {
    this.setState({
      senha: event.target.value
    })
  }

  changeAprovar(event) {
    const aluno = { ...this.state.aluno }

    aluno.aprovarCadastro = !aluno.aprovarCadastro

    this.setState({ aluno })

  }

  validateFormEmailResponsavel() {
    const { responsavel } = this.state

    for (const email of responsavel.emails) {
      if (!email.email) return false
    }

    return true
  }

  removeEnderecoAluno(index) {
    const aluno = { ...this.state.aluno }
    const enderecos = aluno.enderecos
    let aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    aluno.enderecos = aux
    this.setState({ aluno })
  }

  removeContatoAluno(index) {
    const aluno = { ...this.state.aluno }
    const contatos = aluno.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    aluno.contatos = aux
    this.setState({ aluno })
  }

  removeEmailAluno(index) {
    const aluno = { ...this.state.aluno }
    const emails = aluno.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    aluno.emails = aux
    this.setState({ aluno })
  }

  removeEnderecoResponsavel(index) {
    const responsavel = { ...this.state.responsavel }
    const enderecos = responsavel.enderecos
    const aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    responsavel.enderecos = aux
    this.setState({ responsavel })
  }

  removeContatoResponsavel(index) {
    const responsavel = { ...this.state.responsavel }
    const contatos = responsavel.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    responsavel.contatos = aux
    this.setState({ responsavel })
  }

  removeEmailResponsavel(index) {
    const responsavel = { ...this.state.responsavel }
    const emails = responsavel.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    responsavel.emails = aux
    this.setState({ responsavel })
  }

  validateFormContrato() {
    const { aluno } = this.state

    if (
      aluno.contrato.contm_duracao === "" ||
      aluno.contrato.contm_data_inicio_vigencia === "" ||
      aluno.contrato.contm_curso_id === "" ||
      aluno.contrato.contm_tabval_id === "" ||
      aluno.contrato.contm_data_vct_primeira_mensalidade === "" ||
      aluno.contrato.contm_data_vct_matricula === "" ||
      aluno.contrato.formapagto_id === "" ||
      aluno.contrato.material.matcont_valor === "" ||
      aluno.contrato.material.matcont_tipo === "" ||
      aluno.contrato.material.matcont_num_parcelas === "" ||
      aluno.contrato.material.matcont_dia_vencimento === "" ||
      aluno.contrato.material.matcont_formpag_id === "" ||
      aluno.contrato.material.matcont_prod_id === "" ||
      aluno.contrato.contm_metdiv_id === ""
    ) {
      return false
    }

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    if (unin_tipo === "FRANQUEADOR" || unin_tipo === "FRANQUEADO") {
      if (!aluno.contrato.contm_unin_id) return false
    }

    return true
  }

  preparaDados(comissoes) {
    const retorno = []
    for (const item of comissoes) {
      if (item.contcomis_fun_id) {
        retorno.push({
          ...item,
          contcomis_fun_id: parseInt(item.contcomis_fun_id)
        })
      }
    }
    return retorno
  }

  async salvar(reload) {

    const { aluno, responsavel, modulosSelecionadas } = this.state
    
    this.setState({ loadingSalvar: true, msg_loading: 'Salvando Aluno...' })

    let valido = true

    if (aluno.aprovarCadastro || (aluno.contrato.stcontmat_situacao === 'REPROVADO' && aluno.aprovarCadastro)) {
      const login = {
        login: JSON.parse(localStorage.getItem('usu_login')),
        senha: this.state.senha
      }

      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/login`, login)

        valido = true

      } catch (error) {
        this.setState({ loadingSalvar: false })
        valido = false
        this.handleOpenDialog({
          titulo: "Senha Invalida!",
          descricao: "Por favor informar a senha corretamente!"
        })
      }
    }

    if (valido) {
      if (aluno.pessoaFisica.maiorIdade) {
        if (aluno.contrato.stcontmat_situacao === '' ||
          aluno.contrato.stcontmat_situacao === 'AGUARDANDO_APROVACAO' ||
          aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA') {
          if (!this.validateFormContrato()) {
            this.setState({ loadingSalvar: false })
            this.handleOpenDialog({
              titulo: "Erro no Cadastro!",
              descricao: "Preencha os campos obrigatorios (*)."
            })
            return false
          }

          const anoContrato = moment(aluno.contrato.contm_data_inicio_vigencia).format('YYYY')

          if (parseInt(anoContrato) > 9999) {
            this.setState({ loadingSalvar: false })
            this.handleOpenDialog({
              titulo: "Erro no Cadastro!",
              descricao: "Data de Inicio da Vigência Invalido!"
            })
            return false
          }
        }
      } else {
        if (!this.validateFormEmailResponsavel()) {
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: "Preencha os campos obrigatorios (*)."
          })
          return false
        }
      }

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))

      let dados = {}

      if (
        aluno.contrato.stcontmat_situacao === 'AGUARDANDO_APROVACAO' ||
        aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA' ||
        aluno.contrato.stcontmat_situacao === 'AGUARDANDO_TRANSFERENCIA' ||
        aluno.contrato.stcontmat_situacao === 'REPROVADO'
      ) {
        let matcont_valor = aluno.contrato.material.matcont_valor.replace("R$ ", "");
        matcont_valor = matcont_valor.replace(".", "");
        matcont_valor = matcont_valor.replace(",", ".");
        matcont_valor = parseFloat(matcont_valor)

        dados = {
          aluno: {
            pesf_id: aluno.pesf_id,
            nome: aluno.pessoaFisica.nome,
            sobrenome: aluno.pessoaFisica.sobrenome,
            cpf: aluno.pessoaFisica.cpf,
            rg: aluno.pessoaFisica.rg,
            rg_orgao_emissor: aluno.pessoaFisica.rg_orgao_emissor,
            rg_uf: aluno.pessoaFisica.rg_uf,
            rg_data_emissao: aluno.pessoaFisica.rg_data_emissao,
            datanascimento: aluno.pessoaFisica.dataNascimento,
            enderecos: aluno.enderecos.map(end => {
              return {
                end_id: end.end_id,
                cep_id: end.cep_id,
                numero: end.numero,
                rua: end.rua,
                bairro: end.bairro,
                cidade: end.cidade,
                estado: end.estado,
                cep: end.cep,
                complemento: end.complemento,
                principal: end.principal,
                tipo: end.tipoEndereco
              }
            }),
            emails: aluno.emails,
            telefones: aluno.contatos.map(cont => {
              return {
                ...cont,
                tipo: cont.tipoContato
              }
            }),
            aprovarCadastro: aluno.aprovarCadastro,
            reprovarCadastro: aluno.contrato.stcontmat_situacao === 'REPROVADO'
          },
          contrato: {
            contm_id: aluno.contrato.contm_id,
            contm_data_inicio_vigencia: aluno.contrato.contm_data_inicio_vigencia,
            contm_observacao: aluno.contrato.contm_observacao,
            contm_ativo: aluno.contrato.contm_ativo,
            contm_duracao: parseInt(aluno.contrato.contm_duracao),
            contm_curso_id: aluno.contrato.contm_curso_id,
            contm_unin_id: aluno.contrato.contm_unin_id === "" ? pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_id : aluno.contrato.contm_unin_id,
            contm_tabval_id: parseInt(aluno.contrato.contm_tabval_id),
            contm_data_vct_matricula: moment(aluno.contrato.contm_data_vct_matricula).format('YYYY-MM-DD'),
            contm_data_vct_primeira_mensalidade: moment(aluno.contrato.contm_data_vct_primeira_mensalidade).format('YYYY-MM-DD'),
            formapagto_id: parseInt(aluno.contrato.formapagto_id),
            contfin_id: aluno.contrato.contfin_id ? parseInt(aluno.contrato.contfin_id) : null,
            contm_metdiv_id: parseInt(aluno.contrato.contm_metdiv_id),
            material: {
              matcont_valor,
              matcont_tipo: aluno.contrato.material.matcont_tipo,
              matcont_num_parcelas: parseInt(aluno.contrato.material.matcont_num_parcelas),
              matcont_dia_vencimento: aluno.contrato.material.matcont_dia_vencimento,
              matcont_formpag_id: parseInt(aluno.contrato.material.matcont_formpag_id),
              matcont_tabprec_id: parseInt(aluno.contrato.material.matcont_tabprec_id),
              matcont_prod_id: parseInt(aluno.contrato.material.matcont_prod_id),
              contfin_id: aluno.contrato.material.contfin_id ? parseInt(aluno.contrato.material.contfin_id) : null,
            }
          }
        }

        if(aluno.contrato.contfin_id === '' || !aluno.contrato.contfin_id){
          delete aluno.contrato.contfin_id
        }
        if(aluno.contrato.material.contfin_id === '' || !aluno.contrato.material.contfin_id){
          delete aluno.contrato.material.contfin_id
        }

        if (aluno.contrato.contm_id === "") {
          delete dados.contrato.contm_id
        }
        if (aluno.contrato.contm_observacao === "") {
          delete dados.contrato.contm_observacao
        }
      } else {
        dados = {
          aluno: {
            pesf_id: aluno.pesf_id,
            nome: aluno.pessoaFisica.nome,
            sobrenome: aluno.pessoaFisica.sobrenome,
            cpf: aluno.pessoaFisica.cpf,
            rg: aluno.pessoaFisica.rg,
            rg_orgao_emissor: aluno.pessoaFisica.rg_orgao_emissor,
            rg_uf: aluno.pessoaFisica.rg_uf,
            rg_data_emissao: aluno.pessoaFisica.rg_data_emissao,
            datanascimento: aluno.pessoaFisica.dataNascimento,
            enderecos: aluno.enderecos.map(end => {
              return {
                end_id: end.end_id,
                cep_id: end.cep_id,
                numero: end.numero,
                complemento: end.complemento,
                rua: end.rua,
                bairro: end.bairro,
                cidade: end.cidade,
                estado: end.estado,
                cep: end.cep,
                principal: end.principal,
                tipo: end.tipoEndereco
              }
            }),
            emails: aluno.emails,
            telefones: aluno.contatos.map(cont => {
              return {
                ...cont,
                tipo: cont.tipoContato
              }
            }),
            aprovarCadastro: aluno.aprovarCadastro,
            reprovarCadastro: aluno.contrato.stcontmat_situacao === 'REPROVADO'
          }
        }
      }

      for (let i = 0; i < dados.aluno.enderecos.length; i++) {
        if (dados.aluno.enderecos[i].complemento === "" || !dados.aluno.enderecos[i].complemento) {
          delete dados.aluno.enderecos[i].complemento
        }
      }

      if (!aluno.pessoaFisica.maiorIdade) {
        const resp_fin = {
          pesf_id: responsavel.pesf_id,
          nome: responsavel.pessoaFisica.nome,
          sobrenome: responsavel.pessoaFisica.sobrenome,
          cpf: responsavel.pessoaFisica.cpf,
          rg: responsavel.pessoaFisica.rg,
          rg_orgao_emissor: responsavel.pessoaFisica.rg_orgao_emissor,
          rg_uf: responsavel.pessoaFisica.rg_uf,
          rg_data_emissao: responsavel.pessoaFisica.rg_data_emissao,
          datanascimento: responsavel.pessoaFisica.dataNascimento,
          enderecos: responsavel.enderecos.map(end => {
            return {
              end_id: end.end_id,
              cep_id: end.cep_id,
              numero: end.numero,
              complemento: end.complemento,
              rua: end.rua,
              bairro: end.bairro,
              cidade: end.cidade,
              estado: end.estado,
              cep: end.cep,
              principal: end.principal,
              tipo: end.tipoEndereco
            }
          }),
          emails: responsavel.emails,
          telefones: responsavel.contatos.map(cont => {
            return {
              ...cont,
              tipo: cont.tipoContato
            }
          })
        }

        for (let i = 0; i < resp_fin.enderecos.length; i++) {
          if (resp_fin.enderecos[i].complemento === "" || !resp_fin.enderecos[i].complemento) {
            delete resp_fin.enderecos[i].complemento
          }
        }

        if (resp_fin.pesf_id === '') {
          delete resp_fin.pesf_id
        }

        dados.resp_fin = resp_fin

        if (dados.resp_fin.rg === '' || !dados.resp_fin.rg) {
          delete dados.resp_fin.rg
        }
        if (dados.resp_fin.sobrenome === '' || !dados.resp_fin.sobrenome) {
          delete dados.resp_fin.sobrenome
        }
        if (dados.resp_fin.rg_orgao_emissor === '' || !dados.resp_fin.rg_orgao_emissor) {
          delete dados.resp_fin.rg_orgao_emissor
        }
        if (dados.resp_fin.rg_uf === '' || !dados.resp_fin.rg_uf) {
          delete dados.resp_fin.rg_uf
        }
        if (dados.resp_fin.rg_data_emissao === '' || !dados.resp_fin.rg_data_emissao) {
          delete dados.resp_fin.rg_data_emissao
        }
      }

      try {

        if (dados.aluno.rg === '' || !dados.aluno.rg) {
          delete dados.aluno.rg
        }
        if (dados.aluno.sobrenome === '' || !dados.aluno.sobrenome) {
          delete dados.aluno.sobrenome
        }
        if (dados.aluno.rg_orgao_emissor === '' || !dados.aluno.rg_orgao_emissor) {
          delete dados.aluno.rg_orgao_emissor
        }
        if (dados.aluno.rg_uf === '' || !dados.aluno.rg_uf) {
          delete dados.aluno.rg_uf
        }
        if (dados.aluno.rg_data_emissao === '' || !dados.aluno.rg_data_emissao) {
          delete dados.aluno.rg_data_emissao
        }

        if (dados.contrato) {
          if (dados.contrato.contm_tabval_id === '' || !dados.contrato.contm_tabval_id) {
            delete dados.contrato.contm_tabval_id
          }
          if (dados.contrato.contfin_id === '' || !dados.contrato.contfin_id) {
            delete dados.contrato.contfin_id
          }

          if (dados.contrato.material.contfin_id === '' || !dados.contrato.material.contfin_id) {
            delete dados.contrato.material.contfin_id
          }
        }

        let parcelas_criadas = []
        let mensalidades_criadas = []

        let comissoes = this.preparaDados(this.state.comissoes)

        if(dados.contrato){
          const forma_selecionada = this.state.formasPagamento.list.filter(param => param.formpag_id === parseInt(dados.contrato.formapagto_id))[0]
          const conta_selecionada = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(dados.contrato.contfin_id))[0]
          
          const conta_selecionada_material = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(dados.contrato.material.contfin_id))[0]
          const forma_selecionada_material = this.state.formasPagamento.list.filter(param => param.formpag_id === parseInt(dados.contrato.material.matcont_formpag_id))[0]
          
          if(conta_selecionada){
            if(conta_selecionada.conta_corrente){
              if(conta_selecionada.conta_corrente.parceiro){
                if(
                  moment(dados.contrato.contm_data_vct_primeira_mensalidade).isBefore(new Date()) 
                  && forma_selecionada.formpag_slug === 'boleto'){
                    this.setState({ loadingSalvar: false })
                    this.handleOpenDialog({
                      titulo: "Data Invalida!",
                      descricao: "A data de vencimento da mensalidade não pode ser inferior ao dia de amanhã!"
                    })
        
                    return
                }
        
                if(
                  moment(dados.contrato.contm_data_vct_matricula).isBefore(new Date()) 
                  && forma_selecionada.formpag_slug === 'boleto'){
                    this.setState({ loadingSalvar: false })
                    this.handleOpenDialog({
                      titulo: "Data Invalida!",
                      descricao: "A data de vencimento da matricula não pode ser inferior ao dia de amanhã!"
                    })
        
                    return
                }
    
                if(forma_selecionada.formpag_slug === 'boleto' || forma_selecionada.formpag_slug === 'pix'){
                  
                  let tabela_valor = aluno.contrato.tabela.tabval_val_mensal.replace("R$ ", "");
    
                  tabela_valor = tabela_valor.replace(".", "");
                  tabela_valor = tabela_valor.replace(",", ".");
                  tabela_valor = parseFloat(tabela_valor)
    
                  let tabval_val_matri = aluno.contrato.tabela.tabval_val_matri
                  tabval_val_matri = tabval_val_matri.replace(".", "");
                  tabval_val_matri = tabval_val_matri.replace(",", ".");
                  tabval_val_matri = parseFloat(tabval_val_matri)
    
                  if(tabela_valor < 3){
                    this.setState({ loadingSalvar: false })
                    this.handleOpenDialog({
                      titulo: "Valor Inferior!",
                      descricao: "O Valor do boleto/pix da Mensalidade não pode ser inferior a 3 Reias!"
                    })
        
                    return
                  }
    
                  if(tabval_val_matri < 3){
                    this.setState({ loadingSalvar: false })
                    this.handleOpenDialog({
                      titulo: "Valor Inferior!",
                      descricao: "O Valor do boleto/pix da Matricula não pode ser inferior a 3 Reias!"
                    })
        
                    return
                  }
                }
              }
            }
          }

          if(conta_selecionada_material){
            if(conta_selecionada_material.conta_corrente){
              if(conta_selecionada_material.conta_corrente.parceiro){
                if(forma_selecionada_material.formpag_slug === 'boleto' || forma_selecionada_material.formpag_slug === 'pix'){
                  if(dados.contrato.material.matcont_valor < 3){
                    this.setState({ loadingSalvar: false })
                    this.handleOpenDialog({
                      titulo: "Valor Inferior!",
                      descricao: "O Valor do boleto/pix do Material não pode ser inferior a 3 Reias!"
                    })
        
                    return
                  }
    
                }
              }
            }
          }

          if(dados.contrato.contm_curso_id === 2 || dados.contrato.contm_curso_id === 12){
            if(modulosSelecionadas.length === 0){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Erro no Cadastro!",
                descricao: `Por favor informar os modulos do curso`
              })
              return false
            }
    
            if(aluno.contrato.contm_inicio_curso === ''){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Erro no Cadastro!",
                descricao: `Por favor informar o inicio do curso`
              })
              return false
            }
    
            if(aluno.contrato.contm_mes_inicio_curso === ''){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Erro no Cadastro!",
                descricao: `Por favor informar o mês de inicio do curso`
              })
              return false
            }
    
            if(aluno.contrato.contm_dia_aula_1 === ''){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Erro no Cadastro!",
                descricao: `Por favor informar os dias da aula`
              })
              return false
            }
    
            // if(aluno.contrato.contm_dia_aula_2 === ''){
            //   this.setState({ loadingSalvar: false })
            //   this.handleOpenDialog({
            //     titulo: "Erro no Cadastro!",
            //     descricao: `Por favor informar os dias da aula`
            //   })
            //   return false
            // }
    
            if(aluno.contrato.contm_dia_aula_1_periodo === ''){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Erro no Cadastro!",
                descricao: `Por favor informar o período das aulas`
              })
              return false
            }
    
            // if(aluno.contrato.contm_dia_aula_2_periodo === ''){
            //   this.setState({ loadingSalvar: false })
            //   this.handleOpenDialog({
            //     titulo: "Erro no Cadastro!",
            //     descricao: `Por favor informar o período das aulas`
            //   })
            //   return false
            // }
    
            if(aluno.contrato.contm_dia_aula_1_horario === ''){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Erro no Cadastro!",
                descricao: `Por favor informar o horario das aulas`
              })
              return false
            }
    
            // if(aluno.contrato.contm_dia_aula_2_horario === ''){
            //   this.setState({ loadingSalvar: false })
            //   this.handleOpenDialog({
            //     titulo: "Erro no Cadastro!",
            //     descricao: `Por favor informar o horario das aulas`
            //   })
            //   return false
            // }
    
            if(aluno.contrato.contm_duracao_aula === ''){
              this.setState({ loadingSalvar: false })
              this.handleOpenDialog({
                titulo: "Erro no Cadastro!",
                descricao: `Por favor informar a duração das aulas`
              })
              return false
            }
  
            dados.contrato.teens = {
              contm_num_modulos: modulosSelecionadas.length,
              contm_inicio_curso: aluno.contrato.contm_inicio_curso,
              contm_mes_inicio_curso: aluno.contrato.contm_mes_inicio_curso,
              contm_dia_aula_1: aluno.contrato.contm_dia_aula_1,
              contm_dia_aula_2: aluno.contrato.contm_dia_aula_2,
              contm_dia_aula_1_periodo: aluno.contrato.contm_dia_aula_1_periodo,
              contm_dia_aula_2_periodo: aluno.contrato.contm_dia_aula_2_periodo,
              contm_dia_aula_1_horario: aluno.contrato.contm_dia_aula_1_horario,
              contm_dia_aula_2_horario: aluno.contrato.contm_dia_aula_2_horario,
              contm_duracao_aula: aluno.contrato.contm_duracao_aula,
              modulos: modulosSelecionadas
            }
          }

          dados.contrato.comissoes = comissoes
        }
                
        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cadastrando Aluno...'
          }
        })

        if (aluno._id !== "" && this.state.edit) {

          
          const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/aluno/${aluno._id}`, dados, this.getToken())

          parcelas_criadas = data.parcelas_criadas
          mensalidades_criadas = data.mensalidades_criadas

          // const dadosComissao = {
          //   comissoes
          // }

          // await axios.post(`${process.env.REACT_APP_API_URL}/comissao/${aluno.contrato.contm_id}`, dadosComissao, this.getToken())
       
        } else {

          if (this.state.novaMatricula) {
            dados.novoContrato = true
          } else {
            if(dados.aluno === ''){
              delete dados.aluno.pesf_id
            }
            delete dados.contrato.contm_id
            // if (!aluno.pessoaFisica.maiorIdade) {
            //   delete dados.resp_fin.pesf_id
            // }
          }

          if (dados.resp_fin && !dados.resp_fin.pesf_id) {
            delete dados.resp_fin.pesf_id
          }

          if(dados.aluno.pesf_id === ''){
            delete dados.aluno.pesf_id
          }

          let comissoes = this.preparaDados(this.state.comissoes)

          const { data: contrato } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno`, dados, this.getToken())

          const dadosComissao = {
            comissoes
          }

          parcelas_criadas = contrato.parcelas_criadas
          mensalidades_criadas = contrato.mensalidades_criadas

          await axios.post(`${process.env.REACT_APP_API_URL}/comissao/${contrato.contrato_matricula.contm_id}`, dadosComissao, this.getToken())

        }

        let tabela = aluno.contrato.tabela
        
        for (const mensalidade of mensalidades_criadas) {
          this.setState({
            alerta: {
              open: true,
              severity: 'info',
              message: 'Lançando Financeiro...'
            }
          }) 
          
          const data_hoje = moment(new Date()).format('YYYY-MM-DD')
          const data_vencimento = moment(mensalidade.parcont_datavencimento).format('YYYY-MM-DD')
        
          const data_menos_3 = moment(data_vencimento).subtract(3, "days").format('YYYY-MM-DD')
          
          let valor = ''
          let data_venc = ''

          if(moment(data_menos_3).isAfter(data_hoje)){
            data_venc = data_menos_3
            valor = parseFloat(tabela.tabval_val_mensal.replace('.', '').replace(',', '.'))
          }else{
            data_venc = data_vencimento
            valor = parseFloat(tabela.tabval_val_cheio_mensal.replace('.', '').replace(',', '.'))
          }

          // const dados = {
          //   parcela_id: mensalidade.parcont_id,
          //   valor,
          //   data_venc,
          // }

          // if(mensalidade.forma_pgto === 'boleto'){
          //   this.setState({msg_loading: 'Gerando Boletos...'})
          //   await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, dados, this.getToken())
          // }else if (mensalidade.forma_pgto === 'pix'){
          //   this.setState({msg_loading: 'Gerando Pix...'})
          //   await axios.post(`${process.env.REACT_APP_API_URL}/pix`, dados, this.getToken())
          // }
        }
        
        // for (const parcela of parcelas_criadas) {
        //   this.setState({
        //     alerta: {
        //       open: true,
        //       severity: 'info',
        //       message: 'Lançando Financeiro...'
        //     }
        //   })

        //   if(parcela.forma_pgto === 'boleto'){
        //     this.setState({msg_loading: 'Gerando Boletos...'})
        //     await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: parcela.parcont_id}, this.getToken())
        //   }else if (parcela.forma_pgto === 'pix'){
        //     this.setState({msg_loading: 'Gerando Pix...'})
        //     await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: parcela.parcont_id}, this.getToken())
        //   }
        // }

        this.setState({ loadingSalvar: false })

        if (reload) {
          this.handleOpenDialog({
            titulo: 'Parabéns',
            descricao: 'Cadastro realizado com sucesso.'
          })

          this.backPage()
        }

        return true
      } catch (error) {
        console.log(error)
        this.setState({ loadingSalvar: false })
        if (
          error.response &&
          (error.response.status === 400 || error.response.status === 500)
        ) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: error.response.data.message
          })
          return false
        }

        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: error.response.data.message
        })

        this.handleOpenDialog({})

        return false
      }
    }
  }

  handleCloseConfirmacao() {
    this.setState({
      modalConfirmacaoOpen: false
    })
  }

  handleCloseConfirmacaoTrocaResp(){
    this.setState({
      modalConfirmacaoTrocaRespOpen: false
    })
  }

  async imprimirContrato() {
    const { aluno, responsavel } = this.state

    const { match: { params } } = this.props;

    // Editando ou nova matricula Aluno
    if (params.alunoId || params.pefIdEncode) {
      try {

        const data = await this.getDadosAluno()

        const alunoNaoAlterado = {...data.aluno}
        const responsavelNaoAlterado = {...data.responsavel}

        let diferenca = false
        if (JSON.stringify(alunoNaoAlterado) !== JSON.stringify(aluno)) {
          diferenca = true
        }

        if (JSON.stringify(responsavel) !== JSON.stringify(responsavelNaoAlterado)) {
          diferenca = true
        }

        if (diferenca) {
          this.setState({
            modalConfirmacao: {
              mensagem: "Existem alterações no contrato, deseja salvar antes de imprimir?",
            },
            modalConfirmacaoOpen: true
          })
          return
        }

        const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/contrato/matricula/${aluno.contrato.contm_id}/imprimir`, {}, this.getToken())
        const dados = { path: respImprimir.data }
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Contrato-Aluno.pdf`);

      } catch ({ response }) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

    }

  }

  async confirmarSalvamento(resposta) {
    this.setState({
      modalConfirmacaoOpen: false
    })

    if (resposta) {
      try {
        const resp = await this.salvar(false)
        if(resp){
          const { aluno} = this.state
  
          const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/contrato/matricula/${aluno.contrato.contm_id}/imprimir`, {}, this.getToken())
                    
          const dados = { path: respImprimir.data }
          const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })
          const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
          saveAs(pdfBlob, `Contrato-Aluno.pdf`);
        }


      } catch (error) {
        console.log(error)
        this.setState({ loadingSalvar: false })
        if (
          error.response &&
          (error.response.status === 400 || error.response.status === 500)
        ) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: error.response.data.message
          })
          return
        }

        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: error.response.data.message
        })

        this.handleOpenDialog({})
      }


    } else {
      const { aluno} = this.state

      const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/contrato/matricula/${aluno.contrato.contm_id}/imprimir`, {}, this.getToken())
      const dados = { path: respImprimir.data }
      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `Contrato-Aluno.pdf`);

    }
  }

  updateFieldSearchResp(event) {
    const { searchResp } = this.state

    searchResp[event.target.name] = event.target.value

    this.setState({ searchResp })
  }

  async buscarResp() {
    const { searchResp, aluno } = this.state

    const buscaPor = 'cpf'
    const search = searchResp.cpf

    if (!cpf.isValid(cpf.format(searchResp.cpf))) {
      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      this.setState({
        modalErro: true,
        erro
      })
      return
    }

    if (cpf.format(searchResp.cpf) === cpf.format(aluno.pessoaFisica.cpf)) {
      const erro = {
        titulo: "CPF Invalido!",
        descricao: "O CPF do Responsável não pode ser igual ao do aluno."
      }
      this.setState({
        modalErro: true,
        erro
      })
      return
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/search/${search}`, { buscaPor, busca: 'responsavel' }, this.getToken())

      let responsavel = {
        pesf_id: data.pesf_id,
        pessoaFisica: {
          nome: data.pesf_nome,
          sobrenome: data.pesf_sobrenome,
          cpf: cpf.format(data.pesf_cpf),
          rg: data.pesf_rg ? data.pesf_rg : '',
          rg_orgao_emissor: data.pesf_rg_orgao_emissor ? data.pesf_rg_orgao_emissor : '',
          rg_uf: data.pesf_rg_uf ? data.pesf_rg_uf : '',
          rg_data_emissao: data.pesf_rg_data_emissao ? data.pesf_rg_data_emissao : '',
          dataNascimento: data.pesf_datanascimento
        },
        enderecos: data.pessoa.enderecos.map((end, key) => {
          return {
            end_id: end.end_id,
            cep_id: end.end_cep_id,
            rua: end.cep.logradouro,
            complemento: end.end_complemento,
            numero: end.end_numero,
            bairro: end.cep.bairro.nome,
            cidade: end.cep.bairro.cidade.nome,
            estado: end.cep.bairro.cidade.uf.descricao,
            cep: end.cep.cep,
            principal: end.end_principal,
            tipoEndereco: end.end_tipo,
            index: key + 1
          }
        }),
        emails: data.pessoa.emails.map((ema, key) => {
          return {
            ema_id: ema.ema_id,
            email: ema.ema_email,
            principal: ema.ema_principal,
            index: key + 1
          }
        }),
        contatos: data.pessoa.telefones.map((tel, key) => {
          return {
            tel_id: tel.tel_id,
            numero: tel.tel_numero,
            tipoContato: tel.tel_tipo,
            principal: tel.tel_principal,
            index: key + 1
          }
        }),
      }

      this.setState({ responsavel, enableBuscaResp: false })
    } catch ({ response }) {
      let responsavel = {
        pesf_id: "",
        pessoaFisica: {
          nome: "",
          sobrenome: "",
          cpf: cpf.format(searchResp.cpf),
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: ""
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "55",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
      }
      this.setState({ responsavel, enableBuscaResp: false })
    }
  }

  updateFieldComissoes(event, func_id) {
    const comissoes = [...this.state.comissoes]

    const index = comissoes.findIndex(param => param.funcao_id === func_id)

    for (let i = 0; i < comissoes.length; i++) {
      if(comissoes[i].func_nivel < comissoes[index].func_nivel){
        if(comissoes[i].contcomis_fun_id === ''){
          comissoes[i].contcomis_fun_id = event.target.value
        }
      }
    }

    comissoes[index].contcomis_fun_id = event.target.value
    this.setState({ comissoes })
  }

  updateFieldMaiorMenor() {
    const { aluno } = this.state

    aluno.pessoaFisica.maiorIdade = !aluno.pessoaFisica.maiorIdade

    this.setState({
      aluno
    })
  }

  disabledDados(value){
  
    const {permissoesEditarDadosPessoais, permissoesEditarDadosAcademicos, permissoesEditarDadosResponsavel} = this.state
    
    if(this.state.edit){
      if(value === 'pessoais'){
        if(permissoesEditarDadosPessoais.perm_alterar){
          return false
        }
      }
  
      if(value === 'academico'){
        if(permissoesEditarDadosAcademicos.perm_alterar){
          return false
        }
      }

      if(value === 'responsavel'){
        if(permissoesEditarDadosResponsavel.perm_alterar){
          return false
        }
      }
      

      return true
    }else{
      return false
    }
  }
  
  verifyFinishedDate(event) {
    const dataHoje = new Date(moment().format('YYYY-MM-DD'))
    const dataInformada = new Date(event.target.value)

    if (dataInformada < dataHoje) {
      const { aluno } = this.state
      aluno.contrato[event.target.name] = ''
      this.setState({
        aluno
      })

      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'A data informada é menor que a data atual. Favor informar uma data válida'
      })
      return
    }

  }

  updateFieldContaContrato(event){
    const {aluno} = this.state
    aluno.contrato.contfin_id = event.target.value
    this.setState({
      aluno
    })
  }

  reprovarCadastro(){
    let { aluno } = this.state

    aluno.contrato.stcontmat_situacao = 'REPROVADO'

    this.setState({
      aluno
    })

    this.salvar(true)
  }

  render() {
    const { aluno, responsavel, permissoesAprovar, configuracao, permissoesReprovar } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          } {!this.state.loading &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={10}>
                  <h2 className="titulo">Cadastro de Alunos</h2>
                </Grid>
                {this.state.loadingSalvar &&
                  <Grid item md={2}>
                    <div>
                      <span>{this.state.msg_loading}</span>
                      <CircularProgress />
                    </div>
                  </Grid>
                }
              </Grid>

              {this.state.enableDadosPessoa &&
                <div className="formFranqueado">
                  <FormPessoaFisica 
                    validarCpfCnpj={e => this.validarCpfCnpj(e)} 
                    updateField={e => this.updateFieldPessoaFisica(e)} 
                    dados={aluno.pessoaFisica} 
                    disabledDados={e => this.disabledDados(e)}
                    dadosDisabled='pessoais' />

                  <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                    <Grid item md={9}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEndereco &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados de Endereço</h5>
                    </Grid>
                  </Grid>
                  {aluno.enderecos.map(endereco => {
                    return (
                      <div key={endereco.index} className="cardEndereco">
                        <FormEndereco 
                          removerItem={(index) => this.removeEnderecoAluno(index)} 
                          disableEndereco={this.state.disableEndereco} 
                          pegaEndereco={e => this.pegaEndereco(e, endereco.index)} 
                          updateField={e => this.updateFieldEndereco(e, endereco.index)} 
                          updateFieldPrincipal={e => this.updateFieldEnderecoPrincipal(e, endereco.index)} 
                          dados={endereco}
                          disabledDados={e => this.disabledDados(e)} 
                          dadosDisabled='academico'
                        />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      {!this.disabledDados('academico') && 
                        <Button fullWidth onClick={() => this.addNovoEndereco()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                      }
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContato &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados de Contato</h5>
                    </Grid>
                  </Grid>
                  {aluno.contatos.map(contato => {
                    return (
                      <div key={contato.index} className="cardEndereco">
                        <FormContato 
                          removerItem={(index) => this.removeContatoAluno(index)} 
                          updateField={e => this.updateFieldContato(e, contato.index)} 
                          updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, contato.index)} 
                          dados={contato}
                          disabledDados={e => this.disabledDados(e)}
                          dadosDisabled='academico'
                        />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      {!this.disabledDados('academico') && 
                        <Button fullWidth onClick={() => this.addNovoContato()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                      }
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEmail &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados de E-mail</h5>
                    </Grid>
                  </Grid>
                  {aluno.emails.map(email => {
                    return (
                      <div key={email.index} className="cardEndereco">
                        <FormEmail 
                          removerItem={(index) => this.removeEmailAluno(index)} 
                          updateField={e => this.updateFieldEmail(e, email.index)} 
                          updateFieldPrincipal={e => this.updateFieldEmailPrincipal(e, email.index)} 
                          dados={email} 
                          disabledDados={e => this.disabledDados(e)}
                          dadosDisabled='academico'
                        />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      {!this.disabledDados('academico') && 
                        <Button fullWidth onClick={() => this.addNovoEmail()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                      }
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    {(aluno.contrato.stcontmat_situacao === 'AGUARDANDO_APROVACAO' || aluno.contrato.stcontmat_situacao === 'AGUARDANDO_TRANSFERENCIA' || aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA') &&
                      <Grid item md={3}>
                        <Button fullWidth onClick={() => this.proximo("enableDadosEmail", "enableDadosComercial")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                      </Grid>
                    }
                    {(aluno.contrato.stcontmat_situacao !== 'AGUARDANDO_APROVACAO' && aluno.contrato.stcontmat_situacao !== 'AGUARDANDO_TRANSFERENCIA' && aluno.contrato.stcontmat_situacao !== 'AGUARDANDO_ASSINATURA') && 
                      <React.Fragment>
                        {!aluno.pessoaFisica.maiorIdade &&
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => this.proximo("enableDadosEmail", "enableDadosPessoaResponsavel")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                          </Grid>
                        }
                        {aluno.pessoaFisica.maiorIdade &&
                          <Grid item md={3}>
                            <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(true)} disabled={this.state.loadingSalvar}>Salvar</Button>
                          </Grid>
                        }
                      </React.Fragment>
                    }

                  </Grid>
                </div>
              }

              {this.state.enableDadosComercial &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados Honorários</h5>
                    </Grid>
                  </Grid>
                  <FormComissao
                    funcoes={this.state.funcoes.list}
                    comissoes={this.state.comissoes}
                    funcionarios={this.state.funcionarios.list}
                    updateField={(e, func_id) => this.updateFieldComissoes(e, func_id)}
                  />

                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={6}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosComercial", "enableDadosEmail")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosComercial", "enableDadosContrato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContrato &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados do Contrato</h5>
                    </Grid>
                  </Grid>
                  <FormContratoAluno
                    cursos={this.state.cursos.list}
                    curso={this.state.curso}
                    formasPagamento={this.state.formasPagamento.list}
                    unidades={this.state.unidades.list}
                    unidade={this.state.unidade}
                    updateFieldCurso={(event, value) => this.updateFieldCurso(event, value)}
                    updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
                    updateField={e => this.updateFieldContrato(e)}
                    dados={aluno.contrato}
                    tabelaValores={this.state.tabelaValoresFiltrado.list}
                    updateFieldTabelaValor={(value) => this.updateFieldTabelaValor(value)}
                    maiorIdade={aluno.pessoaFisica.maiorIdade}
                    aprovarCadastro={aluno.aprovarCadastro}
                    senha={this.state.senha}
                    updateFieldSenha={e => this.updateFieldSenha(e)}
                    changeAprovar={e => this.changeAprovar(e)}
                    metodosDivugacoes={this.state.metodosDivugacoes.list}
                    materiais={this.state.materiais.list}
                    updateFieldMaterial={(e) => this.updateFieldMaterialContrato(e)}
                    tabelaPrecoMaterial={this.state.tabelaPrecoMaterial}
                    unin_tipo={this.state.unin_tipo}
                    updateFieldMaiorMenor={e => this.updateFieldMaiorMenor(e)}
                    calculaIdade={e => calculaIdade(e)}
                    pessoaFisica={aluno.pessoaFisica}
                    permissoesAprovar={permissoesAprovar}
                    verifyFinishedDate={e => this.verifyFinishedDate(e)}
                    travaUnidade={this.state.habilitaDocumento}
                    updateFieldContaContrato={(e) => this.updateFieldContaContrato(e)}
                    contasFinanceira={this.state.contasFinanceira.list}
                    configuracao={configuracao}
                    modulosSelecionadas={this.state.modulosSelecionadas}
                    modulos={this.state.modulos}
                    updateFieldModulo={(event, value) => this.updateFieldModulo(event, value)}
                    permissoesReprovar={permissoesReprovar}
                  />

                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={2}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContrato", "enableDadosComercial")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}></Grid>
                    {(!aluno.pessoaFisica.maiorIdade ||!permissoesReprovar.perm_inserir) &&
                      <Grid item md={2}></Grid>
                    }
                    {this.state.habilitaDocumento &&
                      <Grid item md={3}>
                        <ColorDarkGreenButton fullWidth onClick={() => this.imprimirContrato()} variant="contained" size="small" startIcon={<PrintIcon />}>Imprimir Contrato</ColorDarkGreenButton>
                      </Grid>
                    }                    
                    {!this.state.habilitaDocumento &&
                      <Grid item md={2}></Grid>
                    }
                    {!aluno.pessoaFisica.maiorIdade &&
                      <Grid item md={2}>
                        <Button fullWidth onClick={() => this.proximo("enableDadosContrato", "enableDadosPessoaResponsavel")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                      </Grid>
                    }
                    {(aluno.pessoaFisica.maiorIdade && permissoesReprovar.perm_inserir) &&
                      <Grid item md={2}>
                        <Button fullWidth color="secondary" variant="contained" size="small" endIcon={<CloseIcon />} onClick={e => this.reprovarCadastro(true)} disabled={this.state.loadingSalvar || !aluno.aprovarCadastro}>Reprovar</Button>
                      </Grid>
                    }
                    {aluno.pessoaFisica.maiorIdade &&
                      <Grid item md={2}>
                        <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(true)} disabled={this.state.loadingSalvar}>Salvar</Button>
                      </Grid>
                    }
                  </Grid>
                </div>
              }

              {this.state.enableDadosPessoaResponsavel &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados do Responsável Financeiro</h5>
                    </Grid>
                  </Grid>

                  {this.state.enableBuscaResp &&
                    <React.Fragment>
                      <Grid container direction="row" className="mg_top_10">
                        <Grid item md={12}>
                          <p>Por favor informar o CPF do responsável</p>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        <Grid item md={3} xs={12} sm={6}>
                          <TextField className="input" label="CPF" variant="outlined" size="small" name="cpf" value={this.state.searchResp.cpf} onChange={(e) => this.updateFieldSearchResp(e)} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item md={1} xs={12} sm={3}>
                          <Button fullWidth onClick={() => this.buscarResp()} color="primary" variant="contained" size="small">Buscar</Button>
                        </Grid>
                      </Grid>
                      <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                        <Grid item md={9}></Grid>
                        {(aluno.contrato.stcontmat_situacao === 'AGUARDANDO_APROVACAO' || aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA' ) &&
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => this.voltar("enableDadosPessoaResponsavel", "enableDadosContrato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                        }
                      </Grid>
                    </React.Fragment>
                  }

                  {!this.state.enableBuscaResp &&
                    <React.Fragment>
                      <FormPessoaFisica 
                        validarCpfCnpj={e => this.validarCpfCnpjResponsavel(e)} 
                        updateField={e => this.updateFieldPessoaFisicaResponsavel(e)} 
                        dados={responsavel.pessoaFisica} 
                        dadosDisabled='responsavel'
                        disabledDados={e => this.disabledDados(e)}
                      />

                      <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                        <Grid item md={6}></Grid>
                        {/* {aluno.contrato.stcontmat_situacao === 'AGUARDANDO_APROVACAO' &&
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => this.voltar("enableDadosPessoaResponsavel", "enableDadosContrato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                        } */}

                        {(aluno.contrato.stcontmat_situacao === 'AGUARDANDO_APROVACAO' || aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA') &&
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => this.voltar("enableDadosPessoaResponsavel", "enableBuscaResp")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                        }
                        {aluno.contrato.stcontmat_situacao === 'AGUARDANDO_TRANSFERENCIA' &&
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => this.voltar("enableDadosPessoaResponsavel", "enableBuscaResp")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                        }
                        {/* {aluno.contrato.stcontmat_situacao === 'AGUARDANDO_TRANSFERENCIA' &&
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => this.voltar("enableDadosPessoaResponsavel", "enableDadosContrato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                        } */}
                        {(aluno.contrato.stcontmat_situacao !== 'AGUARDANDO_APROVACAO' && aluno.contrato.stcontmat_situacao !== 'AGUARDANDO_TRANSFERENCIA' && aluno.contrato.stcontmat_situacao !== 'AGUARDANDO_ASSINATURA') &&
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => this.voltar("enableDadosPessoaResponsavel", "enableDadosEmail")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                        }
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosPessoaResponsavel", "enableDadosEnderecoResponsavel")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }

                </div>
              }

              {this.state.enableDadosEnderecoResponsavel &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados do Responsável Financeiro</h5>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados de Endereço</h5>
                    </Grid>
                  </Grid>
                  {responsavel.enderecos.map(endereco => {
                    return (
                      <div key={endereco.index} className="cardEndereco">
                        <FormEndereco 
                          removerItem={(index) => this.removeEnderecoResponsavel(index)} 
                          disableEndereco={this.state.disableEndereco} 
                          pegaEndereco={e => this.pegaEnderecoResponsavel(e, endereco.index)} 
                          updateField={e => this.updateFieldEnderecoResponsavel(e, endereco.index)} 
                          updateFieldPrincipal={e => this.updateFieldEnderecoPrincipalResponsavel(e, endereco.index)} 
                          dados={endereco} 
                          dadosDisabled='responsavel'
                          disabledDados={e => this.disabledDados(e)}
                        />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoEnderecoResponsavel()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEnderecoResponsavel", "enableDadosPessoaResponsavel")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosEnderecoResponsavel", "enableDadosContatoResponsavel")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContatoResponsavel &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados do Responsável Financeiro</h5>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados de Contato</h5>
                    </Grid>
                  </Grid>
                  {responsavel.contatos.map(contato => {
                    return (
                      <div key={contato.index} className="cardEndereco">
                        <FormContato 
                          removerItem={(index) => this.removeContatoResponsavel(index)} 
                          updateField={e => this.updateFieldContatoResponsavel(e, contato.index)} 
                          updateFieldPrincipal={e => this.updateFieldContatoPrincipalResponsavel(e, contato.index)} 
                          dados={contato} 
                          dadosDisabled='responsavel'
                          disabledDados={e => this.disabledDados(e)}
                        />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoContatoResponsavel()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContatoResponsavel", "enableDadosEnderecoResponsavel")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosContatoResponsavel", "enableDadosEmailResponsavel")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEmailResponsavel &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados do Responsável Financeiro</h5>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h5 className="mg_top_20">Dados de E-mail</h5>
                    </Grid>
                  </Grid>
                  {responsavel.emails.map(email => {
                    return (
                      <div key={email.index} className="cardEndereco">
                        <FormEmail
                          removerItem={(index) => this.removeEmailResponsavel(index)}
                          updateField={e => this.updateFieldEmailResponsavel(e, email.index)}
                          updateFieldPrincipal={e => this.updateFieldEmailPrincipalResponsavel(e, email.index)}
                          dados={email}
                          dadosDisabled='responsavel'
                          disabledDados={e => this.disabledDados(e)}
                        />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.addNovoEmailResponsavel()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                    </Grid>
                  </Grid>

                  {permissoesAprovar.perm_inserir &&
                    <React.Fragment>
                      {(aluno.contrato.stcontmat_situacao === 'AGUARDANDO_APROVACAO' || aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA') &&
                        <React.Fragment>
                          {!aluno.pessoaFisica.maiorIdade &&
                            <div className='cardEndereco'>
                              <Grid container direction="row" spacing={1}>
                                <Grid item md={12} xs={12} sm={12}>
                                  <h4>Aprovar e efetivar o cadastro</h4>
                                </Grid>
                              </Grid>
                              <Grid container direction="row" spacing={1}>
                                <Grid item md={12} xs={12} sm={12}>
                                  <div className='alertaNaoDesfeita'>
                                    <p>Esta operação não poderá ser desfeita. Por segurança é necessário que confirme sua senha abaixo para validar o processo.</p>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container direction="row" spacing={1} className="mg_top_10">
                                <Grid item md={6} xs={12} sm={6}>
                                  <FormControlLabel
                                    value="end"
                                    disabled={aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA'}
                                    control={<Checkbox color="primary" checked={aluno.aprovarCadastro} />}
                                    onClick={e => this.changeAprovar(e)}
                                    label={permissoesReprovar.perm_inserir ? `Quero aprovar/reprovar este pré cadastro e efetivá-lo no sistema ${configuracao.confunin_hab_assinatura_doc  ? ', e enviar o email de assinatura (Em caso de aprovação).' : '.'}${aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA' ? 'O contrato antigo será cancelado.': ''}` : `Quero aprovar este pré cadastro e efetivá-lo no sistema ${configuracao.confunin_hab_assinatura_doc  ? ', e enviar o email de assinatura.' : '.'}${aluno.contrato.stcontmat_situacao === 'AGUARDANDO_ASSINATURA' ? 'O contrato antigo será cancelado.': ''}`}
                                    labelPlacement="end"
                                  />
                                </Grid>
                                <Grid item md={6} xs={12} sm={6}>
                                  <TextField type='password' className="input" label="Senha" variant="outlined" size="small" name="senha" value={this.state.senha} onChange={(e) => this.updateFieldSenha(e)} InputLabelProps={{ shrink: true }} placeholder='Digite sua Senha' />
                                </Grid>
                              </Grid>
                            </div>
                          }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={2}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEmailResponsavel", "enableDadosContatoResponsavel")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}></Grid>
                    {(aluno.pessoaFisica.maiorIdade ||!permissoesReprovar.perm_inserir) &&
                      <Grid item md={2}></Grid>
                    }
                    {this.state.habilitaDocumento &&
                      <Grid item md={3}>
                        <ColorDarkGreenButton fullWidth onClick={() => this.imprimirContrato()} variant="contained" size="small" startIcon={<PrintIcon />}>Imprimir Contrato</ColorDarkGreenButton>
                      </Grid>
                    }

                    {!this.state.habilitaDocumento &&
                      <Grid item md={3}></Grid>
                    }
                    {(!aluno.pessoaFisica.maiorIdade && permissoesReprovar.perm_inserir) &&
                      <Grid item md={2}>
                        <Button fullWidth color="secondary" variant="contained" size="small" endIcon={<CloseIcon />} onClick={e => this.reprovarCadastro(true)} disabled={this.state.loadingSalvar || !aluno.aprovarCadastro}>Reprovar</Button>
                      </Grid>
                    }
                    <Grid item md={2}>
                      <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(true)} disabled={this.state.loadingSalvar}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              <br />
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalConfirmacao 
            open={this.state.modalConfirmacaoOpen} 
            dados={this.state.modalConfirmacao} 
            handleClose={e => this.handleCloseConfirmacao(e)} 
            confirmar={e => this.confirmarSalvamento(e)} 
          />
          <ModalConfirmacaoTrocaResp 
            open={this.state.modalConfirmacaoTrocaRespOpen} 
            dados={this.state.modalConfirmacao} 
            handleClose={e => this.handleCloseConfirmacaoTrocaResp(e)} 
            confirmar={e => this.confirmarTrocaResp(e)} 
          />
          <Alert 
            open={this.state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={this.state.alerta.severity}
            message={this.state.alerta.message} 
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}